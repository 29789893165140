// SPDX-FileCopyrightText: 2024 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { COLORS } from '@tls/treact-ui';
import styled, { createGlobalStyle } from 'styled-components';

export const ToastStyle = createGlobalStyle`@media only screen and (max-width : 480px) {
  .Toastify__toast-container {
    width: 100vw;
    padding: 0;
    left: 0;
    margin: 0;
    height: 100vh;
  }
  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: 0;
    transform: translateX(0);
  }
  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: 0;
    transform: translateX(0);
  }
  .Toastify__toast-container--rtl {
    right: 0;
    left: initial;
  }
}
.Toastify--animate {
  animation-fill-mode: both;
  animation-duration: 0.7s;
}

@keyframes Toastify__trackProgress {
    0% {
      transform: scaleX(1);
    }
    100% {
      transform: scaleX(0);
    }
  }
  .Toastify__progress-bar {
    width: 100%;
    height: 0.5rem;
    z-index: 9999;
    transform-origin: left;
  }
  .Toastify__progress-bar--default {
    margin-left: 1.25rem;
    background: rgb(${COLORS.trblue4.rgb});
  }
  .Toastify__progress-bar--wrp {
    transform: translateY(-1.3rem);
  }
  .Toastify__progress-bar--animated {
    animation: Toastify__trackProgress linear 1 forwards;
  }
  .Toastify__progress-bar--controlled {
    transition: transform 0.2s;
  }
  .Toastify__progress-bar--rtl {
    right: 0;
    left: initial;
    transform-origin: right;
  }
  .Toastify__progress-bar--dark {
    background: #bb86fc;
  }
  @keyframes Toastify__bounceInRight {
    0% {
      opacity: 0;
      transform: translate3d(0px, 0, 0);
    }
    to {
      opacity: 1;
      transform: translate3d(0px, 0, 0);
    }
  }
  @keyframes Toastify__bounceOutRight {
    0% {
      opacity: 1;
      transform: translate3d(0px, 0, 0);
    }
    to {
      opacity: 0;
      transform: translate3d(0px, 0, 0);
    }
  }
  .Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
    animation-name: Toastify__bounceInRight;
  }
  .Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
    animation-name: Toastify__bounceOutRight;
  }`;

export const StatusBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow: hidden;
`;
