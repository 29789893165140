// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { useCallback, useEffect, useState } from 'react';
import { get } from '@tls/react-saf-ui';
import { ProjectVersion } from 'model/ProjectMetaMessageExtensions';

export default function useProjectVersionApi(projectId: string) {
  const [versions, setVersion] = useState<ProjectVersion[]>([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(200);

  const refresh = useCallback(async () => {
    setLoading(true);
    const result = await get(`projects/${projectId}/models`);
    setStatus(result.status);
    setLoading(false);
    if (result.status < 200 || result.status >= 400 || !result.data) {
      return;
    }

    const latestVersions: string[] = !Array.isArray(result.data.projectVersions)
      ? []
      : (result.data.projectVersions as string[]);

    setVersion(latestVersions);
  }, [projectId]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return { versions, loading, status, refresh };
}
