// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface LabelsFetchState {
  lastStatus?: number;
}
export interface ImageState extends LabelsFetchState {
  selectedMd5?: string;
}

const initialState: ImageState = {};

export const projectImageSlice = createSlice({
  name: 'images',
  initialState,
  reducers: {
    changeSelectedImage: (state, action: PayloadAction<string | undefined>) => {
      state.selectedMd5 = action.payload;
      document.dispatchEvent(new CustomEvent('clearstack', { bubbles: true }));
    },
  },
});

export const { changeSelectedImage } = projectImageSlice.actions;
export default projectImageSlice.reducer;
