// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import React from 'react';
import { LicenseComponent } from '@tls/treact-ui-templates';
import useBomApi from 'hooks/useBomApi';
import NoContentBackdrop from 'components/treactui-template/organisms/backdrop/NoContentBackdrop';
import { useTranslation } from 'react-i18next';
import BackdropLoading from 'components/treactui-template/organisms/backdrop/loading/BackdropLoading';
export default function ThirdPartyLicenses() {
  const { bom, loading } = useBomApi();

  const { t } = useTranslation();
  return (
    <>
      <LicenseComponent boms={bom ? [bom] : []} isSmallWidth={true} />
      <BackdropLoading loading={loading} label='' />
      <NoContentBackdrop show={!loading && !bom} title={t('thirdPartyLicenses.none')} />
    </>
  );
}
