// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { Atoms, COLORS, ICONS } from '@tls/treact-ui';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import NoContentBackdrop from 'components/treactui-template/organisms/backdrop/NoContentBackdrop';
import useProjectVersionApi from 'hooks/useProjectModeVersionApi';
import { useParams } from 'react-router';
import VersionSelectionTable from './evaluate/VersionSelectionTable';
import ModelComparison from './evaluate/ModelComparison';
import { ProjectVersion, workingCopy } from 'model/ProjectMetaMessageExtensions';
import { useAppDispatch } from 'store/hooks';
import { setActiveMd5 } from 'store/reducers/projectEvaluationSlice';
import sortBy from 'lodash/sortBy';
import useProjectsMetadata from 'hooks/useProjectsMetadata';
import useTrainingStatusApi from 'hooks/useTrainingStatusApi';
import BackdropLoading from 'components/treactui-template/organisms/backdrop/loading/BackdropLoading';
import useModelEvaluationTriggerApi from 'hooks/useModelEvaluationTriggerApi';
import { ProjectStatus } from 'model/ProjectMetadata';
import EvaluateContext from './evaluate/EvaluateContext';
import ParamsWithId from 'model/ParamsWithId';

const { IconPlay } = ICONS;

export default function Evaluate() {
  const [currentSelected, setCurrentSelected] = useState<ProjectVersion[]>([]);
  const [sortedCurrentSelected, setSortedCurrentSelected] = useState<ProjectVersion[]>([]);
  const [selected, setSelected] = useState<ProjectVersion[]>([]);

  const { id } = useParams<ParamsWithId>();
  const { selectedProject } = useProjectsMetadata(id);
  const { changeTrainingStatus, loading: changingTraining } = useTrainingStatusApi(id ?? '');

  const { trigger, loading: evaluationLoading, evaluationId } = useModelEvaluationTriggerApi(id ?? '');

  const onClick = useCallback(
    (version: ProjectVersion) => async () => {
      const item = currentSelected.find(c => c === version);
      let newCurrentSelected: ProjectVersion[] = [];
      if (item) {
        newCurrentSelected = currentSelected.filter(t => t !== item);
      } else {
        newCurrentSelected = [...currentSelected, version];
      }
      setCurrentSelected(newCurrentSelected);
      setSortedCurrentSelected(sortBy(newCurrentSelected, ['major', 'minor', 'patch']));
    },
    [currentSelected]
  );

  const resetAll = useCallback(() => {
    setCurrentSelected([]);
    setSortedCurrentSelected([]);
  }, []);
  const dispatch = useAppDispatch();

  const evaluate = useCallback(async () => {
    dispatch(setActiveMd5(undefined));
    setSelected([]);

    if (
      sortedCurrentSelected.includes(workingCopy) &&
      selectedProject?.projectStatus === ProjectStatus.TRAINING_ACTIVE
    ) {
      await changeTrainingStatus(ProjectStatus.TRAINING_STOPPED);
    }

    //allow browser to refresh size for selected options
    setTimeout(async () => {
      setSelected(sortedCurrentSelected);
      trigger(sortedCurrentSelected);
    }, 10);
  }, [changeTrainingStatus, dispatch, selectedProject?.projectStatus, sortedCurrentSelected, trigger]);

  const { t } = useTranslation();
  const { versions, loading, refresh } = useProjectVersionApi(id ?? '');

  const evaluateProviderValue = useMemo(() => ({ refresh }), [refresh]);
  return (
    <Container>
      <EvaluateContext.Provider value={evaluateProviderValue}>
        <VersionListContainer>
          <TableContainer>
            <VersionSelectionTable
              loading={loading}
              versions={versions}
              selected={sortedCurrentSelected}
              resetAll={resetAll}
              onClick={onClick}
              disableUnselected={sortedCurrentSelected.length > 2}
            />
          </TableContainer>
          <ButtonContainer>
            <Atoms.Button
              icon={<IconPlay />}
              label={t('project.evaluation.evaluate', { selected: currentSelected.length, total: 3 })}
              variant={selected.length > 0 ? 'secondary' : 'primary'}
              onClick={evaluate}
              disabled={
                currentSelected.length < 1 ||
                currentSelected.length > 3 ||
                sortedCurrentSelected.map(s => s).join(',') === selected.map(s => s).join(',')
              }
            />
          </ButtonContainer>
        </VersionListContainer>
        <Evaluation>
          <ModelComparison selected={selected} evaluationId={evaluationId} />
          <NoContentBackdrop
            show={versions?.length < 1 && !loading}
            title={t('project.evaluation.versions.noData.title')}
            details={t('project.evaluation.versions.noData.details')}
          />
          <NoContentBackdrop
            show={versions?.length > 0 && (selected.length < 1 || (selected.length < 1 && currentSelected.length < 1))}
            title={t('project.evaluation.prompt.title')}
            details={t('project.evaluation.prompt.details', { selected: currentSelected.length, total: 3 })}
          />
          <BackdropLoading loading={changingTraining} label={t('project.label.training.stopping')} />
          <BackdropLoading loading={evaluationLoading} label={t('project.label.training.evaluating')} />
        </Evaluation>
      </EvaluateContext.Provider>
    </Container>
  );
}

const TableContainer = styled.div`
  height: calc(100% - 3.5rem);
  overflow-y: hidden;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0.5rem 0rem;
  button {
    width: calc(100% - 1rem);
  }
`;

const Evaluation = styled.div`
  position: relative;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  position: relative;
`;

const VersionListContainer = styled.div`
  height: inherit;
  min-width: 12rem;
  border-right: 1px solid ${COLORS.trgrey2.hex};
`;
