// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { ApplicationInsights, ITelemetryPlugin } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

const reactPlugin = new ReactPlugin();

const extensions: ITelemetryPlugin[] = [reactPlugin];

function setUpAppInsights(connectionString: string, instrumentationKey: string) {
  const appInsights = new ApplicationInsights({
    config: {
      connectionString,
      instrumentationKey,
      extensions,
      disableTelemetry: !connectionString || !instrumentationKey,
      enableAutoRouteTracking: true,
    },
  });

  appInsights.loadAppInsights();
}

export { reactPlugin, setUpAppInsights };
