// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { Atoms } from '@tls/treact-ui';
import React from 'react';
import styled from 'styled-components';
const { Loader } = Atoms;

export const logo = {
  width: 356,
  height: 356,
  src: '/images/logo.png',
};

export function SplashLoading() {
  return (
    <LoaderWrapper>
      <Loader label='' />
    </LoaderWrapper>
  );
}

const LoaderWrapper = styled.section`
  margin-top: 20;
  width: 356px;
`;
