// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import styled, { css } from 'styled-components';

const Centered = styled.div<{ width?: number }>`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  ${({ width }) => {
    if (!width)
      return css`
        width: 100%;
      `;

    return css`
      min-width: ${width}%;
    `;
  }}
`;

export default Centered;
