// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { useTranslation } from 'react-i18next';
import React, { useCallback } from 'react';
import { Atoms } from '@tls/treact-ui';
import { useNavigate } from 'react-router';
import { AppRoutes } from 'utilities/router/AppRoutes';
import { useSafContext } from '@tls/react-saf-ui';
import Icon404 from 'components/treactui-template/icons/Icon404';
import { Background, Details, Title } from 'components/common/Background';
import IconHome from 'components/treactui-template/icons/IconHome';

export default function NotFound() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { appBaseUrl } = useSafContext();
  const goHome = useCallback(() => navigate(AppRoutes.Projects), [navigate]);

  return (
    <Background appBaseUrl={appBaseUrl ?? ''}>
      <div className='icon'>
        <Icon404 />
      </div>
      <Details className='text'>
        <Title>{t('notFound.title')}</Title>
        <div className='font-size_m details'>{t('notFound.details')}</div>

        <Atoms.Button
          icon={<IconHome />}
          className='margin-top'
          label={t('error.home')}
          onClick={goHome}
          variant='secondary'
        />
      </Details>
    </Background>
  );
}
