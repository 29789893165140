// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { get } from '@tls/react-saf-ui';
import { HistoryInformationMessage } from '@tls/sw91-communication/types/com.base';
import { ToastByStatus } from 'components/treactui-template/organisms/toast/ToastMessage';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HubContext } from '../components/common/emitter/HubContext';
import sortBy from 'lodash/sortBy';
import { setPredictEnabled } from 'store/reducers/projectLabelSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useQuery } from '@tanstack/react-query';
import { GenericResponse } from 'model/reponse/GenericResponse';
import { GetHistoryInformationAckMessages } from 'model/reponse/GetHistoryInformationAckMessages';

export default function useProjectTrainingApi(projectId: string) {
  const [history, setHistory] = useState<HistoryInformationMessage[]>([]);

  const dispatch = useAppDispatch();
  const predictEnabled = useAppSelector(s => s.label.predictEnabled);

  const listener = useCallback(
    (message: HistoryInformationMessage) => {
      if (history.some(h => h.epochNumber === message.epochNumber)) {
        console.warn('Duplicate epoch message', message);
        return;
      }

      if (message.modelUpdate && !predictEnabled) {
        dispatch(setPredictEnabled(true));
      }

      const newHistory = [...history, message];
      setHistory(sortBy(newHistory, ['epochNumber']));
    },
    [dispatch, history, predictEnabled]
  );

  const { events } = useContext(HubContext);
  useEffect(() => {
    events.on('onHistoryInformationUpdate', listener);

    return () => {
      events.off('onHistoryInformationUpdate', listener);
    };
  }, [events, listener]);

  const { t } = useTranslation();

  const { data, isLoading, status, isError } = useQuery<GenericResponse<GetHistoryInformationAckMessages>>({
    queryKey: [`projects/${projectId}/training/history`],
    queryFn: () => get(`projects/${projectId}/training/history`),
  });

  useEffect(() => {
    if (!isError) return;
    ToastByStatus(500, t('project.label.training.title'), t('project.label.training.changeFailed'));
  }, [isError, t]);

  useEffect(() => {
    if (!data?.data?.historyInformation || data?.data?.historyInformation.length < 1) return;

    const convertedData: HistoryInformationMessage[] = data.data.historyInformation.map(
      HistoryInformationMessage.fromJSON
    );

    if (convertedData.length < 1) return;
    if (convertedData.some(h => h.modelUpdate)) {
      dispatch(setPredictEnabled(true));
    }
    setHistory(convertedData);
  }, [dispatch, data?.data?.historyInformation]);

  return { history, status, loading: isLoading };
}
