// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LockResponse } from 'model/reponse/LockResponse';

export type LockProject = {
  projectIdToLock?: string;
  projectLockResponse?: LockResponse;
};

export type ProjectedLockedState = {
  showDialog: boolean;
} & LockProject;

const initialState: ProjectedLockedState = {
  showDialog: false,
};

export const projectLockedSlice = createSlice({
  name: 'project-locked',
  initialState,
  reducers: {
    showDialog: (state, action: PayloadAction<LockProject>) => {
      state.showDialog = true;
      state.projectIdToLock = action.payload.projectIdToLock;
      state.projectLockResponse = action.payload.projectLockResponse;
    },
    closeDialog: state => {
      state.showDialog = false;
    },
  },
});

export const { showDialog, closeDialog } = projectLockedSlice.actions;
export default projectLockedSlice.reducer;
