// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { Molecules } from '@tls/treact-ui';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateFilterText } from 'store/reducers/uiSettingsSlice';
import React, { useCallback } from 'react';

export default function Search() {
  const filterText = useAppSelector(s => s.uiSettings.filterText);
  const show = useAppSelector(s => s.uiSettings.showFilterText);
  const dispatch = useAppDispatch();
  const setFilterText = useCallback((e: string) => dispatch(updateFilterText(e)), [dispatch]);

  if (!show) return <React.Fragment />;

  return <Molecules.SearchField asAtom placeholder='Filter' value={filterText} onValueChange={setFilterText} />;
}
