// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { IconProps } from '@tls/treact-ui/lib/components/Icons/types';
import React from 'react';

const IconScannerViewMaximize = ({ id, width, height, viewBox, strokeWidth, stroke, fill, ...other }: IconProps) => {
  return (
    <svg
      id={id ?? 'svg-scanner-view-maximize'}
      data-name='svg-scanner-view-maximize'
      data-testid='svg-scanner-view-maximize'
      xmlns='http://www.w3.org/2000/svg'
      width={width ?? '2.25rem'}
      height={height ?? '2.25rem'}
      viewBox={viewBox ?? '0 0 24 24'}
      strokeWidth={strokeWidth ?? '0'}
      stroke={stroke ?? 'currentColor'}
      fill={fill ?? 'currentColor'}
      {...other}
    >
      <path d='M10 4H4V10H5.5V5.5H10V4Z' />
      <path d='M14 4H20V10H18.5V5.5H14V4Z' />
      <path d='M9.5 11V9.25L4.5 12L9.5 14.75V13H14.5V14.75L19.5 12L14.5 9.25V11H9.5Z' />
      <path d='M18.5 14H20V20H14V18.5H18.5V14Z' />
      <path d='M4 14H5.5V18.5H10V20H4V14Z' />
    </svg>
  );
};

export default IconScannerViewMaximize;
