// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { Atoms, FLAGS, Organisms } from '@tls/treact-ui';
import { Trans, useTranslation } from 'react-i18next';
import React, { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { closeDialog } from 'store/reducers/projectLockedSlice';
import useProjectsMetadata from 'hooks/useProjectsMetadata';
import { AcknowledgeStatus } from '@tls/sw91-communication/types/com.base';
import { ToastByStatus } from 'components/treactui-template/organisms/toast/ToastMessage';
import { useProjectLockApi } from 'hooks/useProjectLockApi';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'utilities/router/AppRoutes';
import styled from 'styled-components';

const { Dialog } = Organisms;

export default function ProjectLockedDialog() {
  const { t } = useTranslation();
  const { showDialog, projectLockResponse, projectIdToLock } = useAppSelector(a => a.projectLocked);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { projects } = useProjectsMetadata();
  const projectName = projects.find(p => p.projectId === projectIdToLock)?.projectName ?? '';
  const lockedProjectName =
    projects.find(p => p.projectId === projectLockResponse?.currentProjectLockedByUser)?.projectName ?? t('deleted');

  const { lock, unlock, loading } = useProjectLockApi();

  const onClose = useCallback(() => dispatch(closeDialog()), [dispatch]);
  const switchProjects = useCallback(async () => {
    if (!projectLockResponse?.currentProjectLockedByUser || !projectIdToLock) return;
    await unlock(projectLockResponse?.currentProjectLockedByUser);
    const lockResult = await lock(projectIdToLock);
    if (lockResult) {
      navigate(AppRoutes.ProjectLabelFullPath(projectIdToLock));
      onClose();
    }
  }, [lock, navigate, onClose, projectIdToLock, projectLockResponse?.currentProjectLockedByUser, unlock]);

  const copyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(projectLockResponse?.lockedByUser?.userName ?? '');
    ToastByStatus(
      200,
      t('project.lock.copy.title'),
      undefined,
      t('project.lock.copy.success', { userName: projectLockResponse?.lockedByUser?.userName })
    );
  }, [projectLockResponse?.lockedByUser?.userName, t]);

  return (
    <Dialog enableClosing open={showDialog} size='small' onClose={onClose}>
      <Dialog.DialogHeader label={t('project.lock.title')} showCloseButton />
      <Dialog.DialogContent>
        <div>
          {getDetailsText(
            {
              projectName,
              lockedProjectName,
              username: projectLockResponse?.lockedByUser?.userName,
            },
            projectLockResponse?.ackMessage?.status?.status
          )}
        </div>
      </Dialog.DialogContent>
      <Dialog.Footer
        left={[
          projectLockResponse?.ackMessage?.status?.status === AcknowledgeStatus.PROJECT_LOCKED && (
            <Atoms.FooterButton
              key='secondary'
              variant='secondary'
              label={t('project.lock.copy.toClipboard')}
              onClick={copyToClipboard}
            />
          ),
        ]}
        right={[
          projectLockResponse?.ackMessage?.status?.status === AcknowledgeStatus.MAX_LOCKS_EXCEEDED && (
            <Atoms.FooterButton
              key='max-locks'
              disabled={loading}
              iconKey={loading ? 'IconLoading' : undefined}
              onClick={switchProjects}
              label={t('project.lock.switch')}
            />
          ),
        ]}
      />
    </Dialog>
  );
}

interface DetailsTextParams {
  projectName: string;
  lockedProjectName: string;
  username?: string;
}

function getDetailsText({ projectName, lockedProjectName, username }: DetailsTextParams, status?: AcknowledgeStatus) {
  if (status === AcknowledgeStatus.MAX_LOCKS_EXCEEDED) {
    return (
      <>
        <Trans i18nKey={'project.lock.maxLocks'} values={{ projectName, lockedProjectName, username }} />
        <SameLine>
          {FLAGS.getComponent('warningMessage')({ width: '1.5rem' })}
          <Trans i18nKey={'project.lock.stopsTraining'} />
        </SameLine>
      </>
    );
  }
  return <Trans i18nKey={getErrorMessageByStatus(status)} values={{ projectName, lockedProjectName, username }} />;
}

function getErrorMessageByStatus(status?: AcknowledgeStatus) {
  switch (status) {
    case AcknowledgeStatus.PROJECT_LOCKED:
      return 'project.lock.isLockedBy';
    default:
      return 'project.lock.error';
  }
}

const SameLine = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  column-gap: 0.5rem;
`;
