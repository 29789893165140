// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import React from 'react';

import { FLAGS } from '@tls/treact-ui';
import { ProjectMetadata, ProjectStatus } from './ProjectMetadata';

export const workingCopy = 'WORKING_COPY';
export type WorkingCopyType = 'WORKING_COPY';

export type ProjectVersion = string | WorkingCopyType;

export function GetExportTranslationKey(version?: ProjectVersion) {
  if (version === workingCopy) return 'project.evaluation.export.titleWorkingCopy';
  return 'project.evaluation.export.title';
}

export function GetModelTitleTranslationKey(version?: ProjectVersion) {
  if (version === workingCopy) return 'project.evaluation.model.titleWorkingCopy';
  return 'project.evaluation.model.title';
}

export function GetTypeTranslationKey() {
  return 'project.type.detection';
}

export function GetStatusTranslationKey(status?: ProjectStatus, provisioning?: boolean) {
  const provisioningText = provisioning ? 'project.status.provisioning' : undefined;
  switch (status) {
    case ProjectStatus.TRAINING_ACTIVE:
      return 'project.status.active';
    case ProjectStatus.TRAINING_STOPPED:
      return provisioningText ?? 'project.status.stopped';
    case ProjectStatus.TRAINING_NOT_STARTED:
    default:
      return provisioningText ?? 'project.status.notStarted';
  }
}

export type ProjectStatusIconProps = {
  status?: ProjectStatus;
  width: string;
};

export function ProjectStatusIcon({ status, width }: ProjectStatusIconProps) {
  switch (status) {
    case ProjectStatus.TRAINING_ACTIVE:
      return <FLAGS.COMPONENTS.loading width={width} />;
    case ProjectStatus.TRAINING_STOPPED:
      return <FLAGS.COMPONENTS.hold width={width} />;
    case ProjectStatus.TRAINING_NOT_STARTED:
    default:
      return <FLAGS.COMPONENTS.disconnected width={width} />;
  }
}

export function GetFirstProjectUseCaseMessage(project?: ProjectMetadata) {
  if ((project?.projectUseCase?.modelClasses.length ?? 0) < 1) return undefined;
  return project?.projectUseCase?.modelClasses[0];
}
