// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { useCallback } from 'react';
import useDownloadFileApi from './useDownloadApi';
import { ToastMessageType, queueToast } from 'components/treactui-template/organisms/toast/ToastMessage';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export default function useProjectExportApi(projectId: string) {
  const { loading, lastStatus, downloadAndSaveFile } = useDownloadFileApi();

  const { t } = useTranslation();
  const exportProject = useCallback(async () => {
    const id = queueToast({
      headline: t('project.export.title'),
      flag: ToastMessageType.Information,
      message: t('project.export.details'),
      options: {
        autoClose: false,
      },
    });
    await downloadAndSaveFile(`projects/${projectId}/export`, `${projectId}.zip`);
    toast.dismiss(id);
  }, [downloadAndSaveFile, projectId, t]);

  return { loading, lastStatus, exportProject };
}
