// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { Atoms, ICONS } from '@tls/treact-ui';
import { getColorOption } from 'components/common/ColorOptions';
import BackdropLoading from 'components/treactui-template/organisms/backdrop/loading/BackdropLoading';
import useProjectTrainingApi from 'hooks/useProjectTrainingApi';
import useProjectsMetadata from 'hooks/useProjectsMetadata';
import {
  GetFirstProjectUseCaseMessage,
  GetStatusTranslationKey,
  ProjectStatusIcon,
} from 'model/ProjectMetaMessageExtensions';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import TrainingChart from './chart/TrainingChart';
import useTrainingStatusApi from 'hooks/useTrainingStatusApi';
import useProjectLabels from 'hooks/useProjectLabels';
import NoContentBackdrop from 'components/treactui-template/organisms/backdrop/NoContentBackdrop';
import { ProjectStatus } from 'model/ProjectMetadata';

function getLabel(status?: ProjectStatus) {
  if (status === ProjectStatus.TRAINING_ACTIVE) return 'project.label.training.stop';
  return 'project.label.training.start';
}

function getIcon(status?: ProjectStatus, isLoading?: boolean) {
  if (isLoading) return <ICONS.IconLoading width={'1.5rem'} />;
  if (status === ProjectStatus.TRAINING_ACTIVE) return <ICONS.IconStop />;
  return <ICONS.IconPlay />;
}

export default function TrainingStatusChart() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { selectedProject } = useProjectsMetadata(id);
  const status = selectedProject?.projectStatus;

  const { labels } = useProjectLabels();

  const { history = [], loading } = useProjectTrainingApi(id ?? '');
  const { changeTrainingStatus, loading: loadingTraining, status: changeStatus } = useTrainingStatusApi(id ?? '');

  const onStatusChange = useCallback(() => {
    changeTrainingStatus(
      selectedProject?.projectStatus === ProjectStatus.TRAINING_ACTIVE
        ? ProjectStatus.TRAINING_STOPPED
        : ProjectStatus.TRAINING_ACTIVE
    );
  }, [changeTrainingStatus, selectedProject?.projectStatus]);

  return (
    <Container>
      <Header>
        <ProjectStatusIcon status={status} width='1.5rem' />
        <span className='font-size_m font-weight_bold'>
          {t(GetStatusTranslationKey(status, changeStatus === 200 && loadingTraining))}
        </span>
        <Atoms.Button
          icon={getIcon(status, loadingTraining)}
          label={t(getLabel(status))}
          variant='secondary'
          disabled={loadingTraining || labels.length < 1}
          onClick={onStatusChange}
        />
      </Header>
      <ChartContainer>
        <TrainingChart
          history={history}
          color={`rgb(${getColorOption(GetFirstProjectUseCaseMessage(selectedProject)?.labelColor).id})`}
        />
        <NoContentBackdrop
          show={history.length < 1 && !loading}
          title={t('project.label.training.chart.noData.title')}
          details={t('project.label.training.chart.noData.details')}
        />
        <BackdropLoading label={t('loading')} loading={loading && history.length < 1} />
      </ChartContainer>
    </Container>
  );
}
const ChartContainer = styled.div`
  position: relative;
  height: calc(100% - 2.5rem);
`;
const Container = styled.div`
  padding: 0.5rem 0.5rem 0;
  height: calc(100% - 0.5rem);
`;

const Header = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto auto;
  align-items: center;
  column-gap: 0.5rem;
`;
