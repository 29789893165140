// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ContractOverview } from 'model/ContractOverview';

export interface LicenseState {
  contract?: ContractOverview;
  loading: boolean;
  hide: boolean;
}

const initialState: LicenseState = {
  contract: undefined,
  loading: true,
  hide: false,
};

export const licenseSlice = createSlice({
  name: 'license',
  initialState,
  reducers: {
    setContract: (state, action: PayloadAction<ContractOverview>) => {
      state.loading = false;
      state.contract = action.payload;
      state.hide = false;
    },
    hideHeader: state => {
      state.hide = true;
    },
  },
});

export const { setContract, hideHeader } = licenseSlice.actions;
export default licenseSlice.reducer;
