// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import React, { useCallback, useEffect } from 'react';
import { Atoms, Molecules } from '@tls/treact-ui';
import { useTranslation } from 'react-i18next';
import { PencilType, ToolType, ToolMode } from 'model/Tools';
import {
  changePencilToolType,
  changePencilWidth,
  changeToolType,
  changeToolMode,
} from 'store/reducers/projectToolSlice';
import IconDrawCircle from 'components/treactui-template/icons/IconDrawCircle';
import IconDrawSquare from 'components/treactui-template/icons/IconDrawSquare';
import IconPencil from 'components/treactui-template/icons/IconPencil';
import IconPolygon from 'components/treactui-template/icons/IconPoligon';
import IconIntelligentScissor from 'components/treactui-template/icons/IconIntelligentScissor';
import useProjectLabels from 'hooks/useProjectLabels';

function format(e: number) {
  return `${e.toFixed(0)}%`;
}

export default function ToolSelection() {
  const { toolType, pencilType, pencilWidth, mode, pencilWidthStep, zoomLevel } = useAppSelector(s => s.tools);
  const { selectedMd5 } = useProjectLabels();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const onChange = useCallback((type: ToolType) => () => dispatch(changeToolType(type)), [dispatch]);
  const onPencilType = useCallback((type: PencilType) => () => dispatch(changePencilToolType(type)), [dispatch]);
  const onPencilWidthChange = useCallback((newWidth: number) => dispatch(changePencilWidth(newWidth)), [dispatch]);
  const onChangeToolMode = useCallback((type: ToolMode) => dispatch(changeToolMode(type)), [dispatch]);

  const handleButtonPress = useCallback(
    (event: KeyboardEvent) => {
      const key = event.key.toLowerCase();

      if (key === 'a') {
        onPencilWidthChange(pencilWidth - getStep(pencilWidthStep, zoomLevel));
      } else if (key === 'd') {
        onPencilWidthChange(pencilWidth + getStep(pencilWidthStep, zoomLevel));
      } else if (toolType === ToolType.Pencil && key === 't') {
        const newPencilMode = mode === ToolMode.Draw ? ToolMode.Erase : ToolMode.Draw;
        onChangeToolMode(newPencilMode);
      }
    },
    [toolType, onPencilWidthChange, pencilWidth, pencilWidthStep, zoomLevel, mode, onChangeToolMode]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleButtonPress);

    return () => {
      window.removeEventListener('keydown', handleButtonPress);
    };
  }, [handleButtonPress]);

  return (
    <Container>
      <span className='font-size_m font-weight_bold'>{t('project.label.tools.title')}</span>
      <ToggleContainer>
        {/* @ts-expect-error */}
        <Atoms.Radio
          asAtom
          icon={<IconPencil width={'1.5rem'} key='icon-pencil' />}
          label={t('project.label.tools.pencil.title')}
          selected={toolType === ToolType.Pencil}
          onChange={onChange(ToolType.Pencil)}
          disabled={!selectedMd5}
        />
        <PadLeft>
          <Molecules.SegmentedControl asAtom>
            <Molecules.SegmentedControl.SegmentedControlButton
              icon={<IconDrawCircle width={'1.5rem'} />}
              disabled={toolType !== ToolType.Pencil || !selectedMd5}
              selected={pencilType === PencilType.Circle}
              label={t('project.label.tools.pencil.circle')}
              onClick={onPencilType(PencilType.Circle)}
            />
            <Molecules.SegmentedControl.SegmentedControlButton
              icon={<IconDrawSquare width={'1.5rem'} />}
              disabled={toolType !== ToolType.Pencil || !selectedMd5}
              selected={pencilType === PencilType.Square}
              label={t('project.label.tools.pencil.square')}
              onClick={onPencilType(PencilType.Square)}
            />
          </Molecules.SegmentedControl>
          <span className='margin-top font-size_s'>{t('project.label.tools.pencil.width')}</span>
          <div id='slider'>
            <Molecules.SliderHorizontal
              max={100}
              min={0}
              showMin
              formatFn={format}
              initial={pencilWidth}
              onChange={onPencilWidthChange}
              disabled={toolType !== ToolType.Pencil || !selectedMd5}
            />
          </div>
        </PadLeft>

        {/* @ts-expect-error */}
        <Atoms.Radio
          asAtom
          icon={<IconIntelligentScissor width={'1.5rem'} key='icon-scissor' />}
          label={t('project.label.tools.intelligentScissor')}
          selected={toolType === ToolType.IntelligentScissor}
          onChange={onChange(ToolType.IntelligentScissor)}
          disabled={!selectedMd5}
        />
        {/* @ts-expect-error */}
        <Atoms.Radio
          asAtom
          icon={<IconPolygon width={'1.5rem'} key='icon-polygon' />}
          label={t('project.label.tools.polygon')}
          selected={toolType === ToolType.Polygon}
          onChange={onChange(ToolType.Polygon)}
          disabled={!selectedMd5}
        />
      </ToggleContainer>
    </Container>
  );
}
const Container = styled.div`
  margin-top: 0.5rem;
`;

const ToggleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0.5rem 0;
`;

const PadLeft = styled.div`
  padding-left: 1.5rem;
  display: flex;
  flex-direction: column;

  .margin-top {
    margin-top: 1rem;
  }

  #slider > div > div {
    padding: 0.5rem 1rem 2rem 1rem;
  }
`;

function getStep(pencilWidthStep: number, zoomLevel: number) {
  const step = pencilWidthStep;
  if (zoomLevel <= 1.125) {
    return step * 20;
  } else if (zoomLevel <= 1.5) {
    return step * 15;
  } else if (zoomLevel <= 2.5) {
    return step * 10;
  } else if (zoomLevel <= 3.5) {
    return step * 5;
  }
  return step;
}
