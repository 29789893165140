// SPDX-FileCopyrightText: 2024 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { Organisms } from '@tls/treact-ui';
import { ThreeSectorContent, TwoRowCell } from '@tls/treact-ui-templates';
import useUsersApi from 'hooks/useUsersApi';
import { User } from 'model/User';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import UserDetails from './Users/UserDetails';
import PageContent from 'components/treactui-template/organisms/page/PageContent';
import useFilteredUsers from 'hooks/useFilteredUsers';
import SelectUser from './Users/SelectUser';
import useMediaQuery from 'hooks/useMediaQuery';

export default function Users() {
  const { users, loadingUsers, editUser, deleteUser } = useUsersApi();
  const { t } = useTranslation();

  const filteredUsers = useFilteredUsers(users);

  const renderTableRowCells = useCallback((user: User) => {
    return [
      <Organisms.Table.Cell key='User' width='15rem'>
        <TwoRowCell row1={user.userName} row2={user.firstName + ' ' + user.lastName} row1Bold />
      </Organisms.Table.Cell>,
      <Organisms.Table.Cell key='email' width='15rem'>
        {user.email}
      </Organisms.Table.Cell>,
    ];
  }, []);

  const { isMedium, isSmall, isExtraSmall } = useMediaQuery();

  return (
    <PageContent displayAsPaper disableScroll>
      <ThreeSectorContent
        isSmallWidth={isMedium || isSmall || isExtraSmall}
        selectionAdapters={[
          {
            menuLabel: 'user',
            tableHeaderCells: [
              <Organisms.Table.HeaderCell key='User' width='15rem'>
                {t('user.userName')}
              </Organisms.Table.HeaderCell>,
              <Organisms.Table.HeaderCell key='mail' width='15rem'>
                {t('user.email')}
              </Organisms.Table.HeaderCell>,
            ],
            listElementKeySelector: (user: User) => user.id ?? '',
            listElements: filteredUsers ?? [],
            renderTableRowCells: renderTableRowCells,
            renderDetail: (listElement: User, handleBack) => {
              if (!listElement) return <SelectUser />;
              return (
                <UserDetails
                  user={listElement}
                  users={users}
                  isLoading={loadingUsers}
                  addOrEditUser={editUser}
                  isUsernameEditable={false}
                  deleteUser={deleteUser}
                  onClose={isMedium || isSmall || isExtraSmall ? handleBack : undefined}
                />
              );
            },
          },
        ]}
      />
    </PageContent>
  );
}
