// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF

import { createContext } from 'react';

const EvaluateContext = createContext({
  refresh: async () => {},
});

export default EvaluateContext;
