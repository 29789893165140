// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import React from 'react';
import { TFunction } from 'i18next';
import { FilterTypes as FilterType } from 'store/reducers/uiSettingsSlice';
import { GetStatusTranslationKey, ProjectStatusIcon } from 'model/ProjectMetaMessageExtensions';
import { Option } from '@tls/treact-ui/lib/components/Molecules/Filter/Filter/FilterOption';
import { ProjectStatus } from 'model/ProjectMetadata';

export default function GenerateFilterEntries(
  filterTypes: FilterType[],
  t: TFunction<'translation', unknown>,
  tags?: FilterOption[],
  projects?: FilterOption[]
): FilterEntry[] {
  return filterTypes?.map(f => generateFilterEntry(f, t, tags, projects)) ?? ([] as FilterEntry[]);
}

function generateFilterEntry(
  filterType: FilterType,
  t: TFunction<'translation', undefined>,
  tags?: FilterOption[],
  projects?: FilterOption[]
): FilterEntry {
  const result: FilterEntry = {
    id: filterType,
    label: t(`filter.label.${filterType}`),
    options: [],
  };

  switch (filterType) {
    case FilterType.Tag:
      result.options = tags ?? [];
      break;
    case FilterType.Project:
      result.options = projects ?? [];
      break;
    case FilterType.Status:
      result.options = [
        {
          id: GetStatusTranslationKey(ProjectStatus.TRAINING_ACTIVE),
          label: t(GetStatusTranslationKey(ProjectStatus.TRAINING_ACTIVE)),
          icon: <ProjectStatusIcon status={ProjectStatus.TRAINING_ACTIVE} width='1.5rem' />,
        },
        {
          id: GetStatusTranslationKey(ProjectStatus.TRAINING_NOT_STARTED),
          label: t(GetStatusTranslationKey(ProjectStatus.TRAINING_NOT_STARTED)),
          icon: <ProjectStatusIcon status={ProjectStatus.TRAINING_NOT_STARTED} width='1.5rem' />,
        },
        {
          id: GetStatusTranslationKey(ProjectStatus.TRAINING_STOPPED),
          label: t(GetStatusTranslationKey(ProjectStatus.TRAINING_STOPPED)),
          icon: <ProjectStatusIcon status={ProjectStatus.TRAINING_STOPPED} width='1.5rem' />,
        },
      ];
      break;
  }

  return result;
}

export type FilterOption = {
  label: string;
  id: string;
  icon?: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
} & Option;

export type FilterEntry = {
  label: string;
  id: string;
  options: FilterOption[];
};
