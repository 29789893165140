// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { z } from 'zod';

const ContractObject = z.object({
  contractId: z.string(),
  startDate: z.coerce.date(),
  endDate: z.coerce.date(),
  customerNumber: z.string(),
  endCustomerNumber: z.string(),
  status: z.string(),
  materialNumber: z.string(),
});

export type Contract = z.infer<typeof ContractObject>;

export function parseContract(object: unknown): Contract | undefined {
  try {
    return ContractObject.parse(object);
  } catch (error) {
    console.error('Unable to parse data', error);
    return undefined;
  }
}
