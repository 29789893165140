// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { get } from '@tls/react-saf-ui';
import { AxiosRequestConfig } from 'axios';
import { useCallback, useState } from 'react';
import saveFile from 'utilities/saveFile';

const options: AxiosRequestConfig = {
  responseType: 'arraybuffer',
  headers: {
    'Content-Type': 'application/json',
  },
};

function getTitle(headers) {
  return headers['content-disposition']
    ?.split(';')
    ?.find((c: string) => c.trim().startsWith('filename='))
    ?.replace('filename=', '')
    ?.replaceAll('"', '')
    ?.trim();
}

export default function useDownloadFileApi() {
  const [loading, setLoading] = useState(false);
  const [lastStatus, setLastStatus] = useState(200);

  const downloadAndSaveFile = useCallback(async (url: string, defaultFileName: string) => {
    setLoading(true);
    setLastStatus(200);

    const result = await get(url, false, false, options);
    const { data, status, headers } = result;
    setLastStatus(status);

    if (status === 200) {
      const title = getTitle(headers) ?? defaultFileName;
      await saveFile([data], headers['content-type'] ?? 'application/zip', title);
    }
    setLoading(false);
  }, []);

  return { loading, lastStatus, downloadAndSaveFile };
}
