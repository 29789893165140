// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { IconProps } from '@tls/treact-ui/lib/components/Icons/types';
import React from 'react';

const IconPredict = ({ id, width, height, viewBox, strokeWidth, stroke, fill, ...other }: IconProps) => {
  return (
    <svg
      id={id || 'svg-predict'}
      data-name='svg-predict'
      data-testid='svg-predict'
      xmlns='http://www.w3.org/2000/svg'
      width={width || '2.25rem'}
      height={height || '2.25rem'}
      viewBox={viewBox || '0 0 48 48'}
      strokeWidth={strokeWidth || '0'}
      stroke={stroke || 'currentColor'}
      fill={fill || 'currentColor'}
      {...other}
    >
      <path
        d='M34.5176 4L32.8506 12.333L24.5176 14L32.8506 15.667L34.5176 24L36.1846 15.667L44.5176 14L36.1846 12.333L34.5176 4ZM6.51758 39L9.51758 42L28.5176 23V20H25.5176L6.51758 39Z'
        fill={fill}
        stroke={stroke}
      />
    </svg>
  );
};

export default IconPredict;
