// SPDX-FileCopyrightText: 2024 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import useTelemetryApi from 'hooks/useTelemetryApi';
import React, { useEffect } from 'react';
import { setUpAppInsights } from 'utilities/appInsights/AppInsights';

export default function TelemetryFetcher() {
  const { data } = useTelemetryApi();

  useEffect(() => {
    if (!data?.connectionString || !data?.instrumentationKey) return;
    setUpAppInsights(data.connectionString, data.instrumentationKey);
  }, [data?.connectionString, data?.instrumentationKey]);

  return <React.Fragment />;
}
