// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { post } from '@tls/react-saf-ui';
import { ExportStrategyProgressMessage } from '@tls/sw91-communication/types/com.api_tai_exporter';
import { HubContext } from 'components/common/emitter/HubContext';
import { ProjectVersion } from 'model/ProjectMetaMessageExtensions';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import useDownloadFileApi from './useDownloadApi';

export function useModelExportApi(projectId: string) {
  const [loading, setLoading] = useState(false);
  const [lastStatus, setLastStatus] = useState<number | undefined>(200);
  const [exportProgress, setExportProgress] = useState<number>(0);

  const resolve = useRef<(success: boolean) => void>();
  const reject = useRef<(success: boolean) => void>();
  const [exportingModel, setExportingModel] = useState<string>();

  const { events } = useContext(HubContext);

  // Listen to events as soon as the exportingModel was set
  useEffect(() => {
    function onProgressUpdate(message: ExportStrategyProgressMessage) {
      const progress = message.progress ?? 0;
      setExportProgress(progress);
      if (progress >= 1) {
        setLoading(false);
      }
    }
    function onProgressError() {
      if (!reject.current) return;
      reject.current(false);
    }

    events.on('onExportProgress', onProgressUpdate);
    events.on('onExportFailed', onProgressError);
    return () => {
      events.off('onExportProgress', onProgressUpdate);
      events.off('onExportFailed', onProgressError);
    };
  }, [events, exportingModel, reject]);

  // Resolve the getResult callback once the progress reaches 100%
  useEffect(() => {
    if (exportProgress < 1) return;
    if (!resolve.current) return;
    resolve.current(true);
  }, [exportProgress, resolve]);

  const getExportResult = useCallback(
    (): Promise<boolean> =>
      new Promise<boolean>((currentResolve, currentReject) => {
        resolve.current = currentResolve;
        reject.current = currentReject;
      }),
    []
  );

  const triggerExport = useCallback(
    async (modelId: ProjectVersion) => {
      setLoading(true);
      setExportProgress(0);
      setLastStatus(200);

      setExportingModel(modelId);
      const result = await post(`projects/${projectId}/models/${modelId}/export/trigger`);

      const { status } = result;
      setLastStatus(status);
    },
    [projectId]
  );

  const saveFile = useDownloadFileApi();
  const downloadAndSaveFile = useCallback(
    async (modelId: ProjectVersion) => {
      setLastStatus(undefined);
      await saveFile.downloadAndSaveFile(`projects/${projectId}/models/${modelId}`, `${projectId}-${modelId}.tai`);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectId, saveFile.downloadAndSaveFile]
  );

  return {
    downloadAndSaveFile,
    triggerExport,
    loading: loading || saveFile.loading,
    lastStatus: lastStatus ?? saveFile.lastStatus,
    getExportResult,
    exportProgress,
  };
}
