// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { get } from '@tls/react-saf-ui';
import { RefObject } from 'react';

function isHTMLImageElement(element: HTMLImageElement | RefObject<HTMLImageElement>): element is HTMLImageElement {
  return (element as HTMLImageElement)?.dataset !== undefined;
}

export function loadArrayIntoImageElement(
  array: Uint8Array,
  type: string,
  imageElement: HTMLImageElement | RefObject<HTMLImageElement>
) {
  const blob = new Blob([array], { type });
  loadBlobIntoImageElement(blob, imageElement);
}

async function loadBlobIntoImageElement(blob: Blob, imageElement: HTMLImageElement | RefObject<HTMLImageElement>) {
  const reader = new window.FileReader();

  const element = GetImageElement(imageElement);

  reader.onload = function () {
    const imageDataUrl = reader.result;
    if (!imageDataUrl || imageDataUrl.toString().trim() === 'data:') return;

    element?.setAttribute('src', imageDataUrl.toString());
  };

  if (blob.size === 0) return;
  reader.readAsDataURL(blob);
}

export default async function loadImageIntoElementByUrl(
  url: string,
  imageElement: HTMLImageElement | RefObject<HTMLImageElement>
) {
  const response = await get(url, false, false, {
    responseType: 'blob',
  });

  const element = GetImageElement(imageElement);

  const blobResponse = response.data as Blob;
  if (response.status >= 400 || blobResponse?.size === undefined || blobResponse.size === 0) {
    return element?.onerror && element.onerror(response.status, url);
  }

  loadBlobIntoImageElement(blobResponse, imageElement);
}

function GetImageElement(imageElement: HTMLImageElement | RefObject<HTMLImageElement>) {
  let element: HTMLImageElement | null;
  if (!isHTMLImageElement(imageElement)) {
    element = imageElement.current;
  } else {
    element = imageElement;
  }
  return element;
}

export function blobToBase64(blob: Blob) {
  return new Promise<string | ArrayBuffer | null>((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}
