// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Atoms, Molecules, Organisms } from '@tls/treact-ui';
import Ellipsis from 'components/treactui-template/atoms/Ellipsis';
import { useTranslation } from 'react-i18next';
import { ImageType } from 'model/Image';
import ImageTableEntry from './ImageTableEntry';
import { useAppSelector } from 'store/hooks';
import sortBy from 'lodash/sortBy';
import styled from 'styled-components';
import { SSimContext } from 'components/project/label/similarity/SSimContext';
import { ImageListOrderContext } from './ImageListOrderContext';
const Table = Organisms.Table;

export type Props = {
  data: ImageType[];
  loading: boolean;
};

const ENTRIES_PER_PAGE = 50;

export default function ImageTable({ data, loading }: Props) {
  const { t } = useTranslation();

  const { selectedMd5 } = useAppSelector(s => s.image);
  const { result } = useContext(SSimContext);
  const { currentOrder } = useContext(ImageListOrderContext);

  const sortArray = useCallback((array: ImageType[], sortProp: string) => sortBy(array, sortProp), []);

  const onSortByName = useCallback((array: ImageType[]) => sortArray(array, 'imageName'), [sortArray]);
  const onSortByLabel = useCallback((array: ImageType[]) => sortArray(array, 'hasMask'), [sortArray]);
  const onSortByLabelOrder = useCallback((array: ImageType[]) => sortArray(array, 'labelOrder'), [sortArray]);

  const dataWithLabelOrder = useMemo<ImageType[]>(() => {
    const maxOrder = result?.length > 0 ? result.length + 1 : undefined;
    return data.map(d => ({ ...d, labelOrder: result.find(r => r.md5 === d.imageMd5)?.labelOrder ?? maxOrder }));
  }, [result, data]);

  const [page, setPage] = useState(1);

  const pagingIsActive = dataWithLabelOrder.length > ENTRIES_PER_PAGE - 1;
  return (
    <TableContainer pagingIsActive={`${pagingIsActive}`} loading={`${loading}`}>
      <Table list={dataWithLabelOrder}>
        {tableList => {
          if (currentOrder) currentOrder.current = tableList.map(t => t.imageMd5 ?? '');
          return (
            <Table.Content>
              <Table.Header>
                <Table.HeaderRow>
                  <Table.HeaderSortCell noIndent width='20%' alignment='LEFT' sortKey='label' sortFn={onSortByLabel}>
                    <Ellipsis>{t('project.label.images.table.label')}</Ellipsis>
                  </Table.HeaderSortCell>
                  <Table.HeaderSortCell width='60%' alignment='LEFT' sortKey='name' sortFn={onSortByName}>
                    {t('project.label.images.table.name')}
                  </Table.HeaderSortCell>
                  <Table.HeaderSortCell width='25%' alignment='LEFT' sortKey='labelOrder' sortFn={onSortByLabelOrder}>
                    <Ellipsis>{t('project.label.images.table.order')}</Ellipsis>
                  </Table.HeaderSortCell>
                </Table.HeaderRow>
              </Table.Header>
              <Table.Body>
                <ScrollableContainer>
                  {tableList
                    .slice((page - 1) * ENTRIES_PER_PAGE, page * ENTRIES_PER_PAGE)
                    .filter(t => t.imageName)
                    .map((entry, idx) => (
                      <ImageTableEntry
                        key={`${entry.imageName}:${entry.imageMd5}`}
                        entry={entry}
                        index={idx}
                        selected={entry.imageMd5 === selectedMd5}
                      />
                    ))}
                </ScrollableContainer>
              </Table.Body>
            </Table.Content>
          );
        }}
      </Table>
      {loading && <Atoms.Loader label='' />}
      {pagingIsActive && (
        <PagingContainer>
          <Molecules.Paging
            id='paging'
            currentPage={page}
            itemCount={dataWithLabelOrder.length}
            onSelect={setPage}
            popupLabel={t('project.label.images.pagination.goToPage')}
            pageSize={ENTRIES_PER_PAGE}
          />
        </PagingContainer>
      )}
    </TableContainer>
  );
}

const ScrollableContainer = styled.div`
  overflow-y: auto;
  height: 100%;
`;

const PagingContainer = styled.div`
  height: 3.5rem;
  margin: 0.5rem 0.5rem 0 0;

  #paging {
    justify-content: center;
  }
`;

const TableContainer = styled.div<{ pagingIsActive: string; loading: string }>`
  position: relative;
  height: calc(
    100% ${({ pagingIsActive }) => (pagingIsActive === 'true' ? '- 3.5rem' : '')}
      ${({ loading }) => (loading === 'true' ? '- 2.5rem' : '')}
  );
`;
