// SPDX-FileCopyrightText: 2024 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { z } from 'zod';

const ContractObject = z.object({
  isActive: z.boolean(),
  lastStartDate: z.coerce.date(),
  lastActiveEndDate: z.coerce.date(),
  freeTrainingSessions: z.number(),
  totalContracts: z.number(),
});

export type ContractOverview = z.infer<typeof ContractObject>;

export function parseContractOverview(object: unknown): ContractOverview {
  return ContractObject.parse(object);
}
