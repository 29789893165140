// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Dictionary } from 'model/Dictionary';
import { loadStoreStateFromLocalStorage } from 'store/rehydrate';
import { AppRoutes, ProjectsSubPath } from 'utilities/router/AppRoutes';

export type DisplayType = ProjectsSubPath.Tiles | ProjectsSubPath.List;

export type OrderOption = {
  label: string;
  id: string;
};

export enum FilterTypes {
  Tag = 'tag',
  Project = 'project',
  Status = 'status',
}

export interface UiSettingsState {
  displayType: DisplayType;
  order: 'asc' | 'desc';
  oderBy: string;
  orderByOptions: OrderOption[];
  filterTypesToDisplay: FilterTypes[];
  filterText: string;
  filterType: Dictionary<string[]>;
  showFilterText: boolean;
  showThirdPartyLicenses: boolean;
  showSettings: boolean;
  returnUrl?: string;
}

const lastWeek = new Date();
lastWeek.setDate(lastWeek.getDate() - 7);

const initialState: UiSettingsState = loadStoreStateFromLocalStorage(l => l?.uiSettings, {
  displayType: ProjectsSubPath.Tiles,
  order: 'asc',
  orderByOptions: [],
  oderBy: 'name',
  filterOptions: {},
  filterTypesToDisplay: [FilterTypes.Status, FilterTypes.Project, FilterTypes.Tag],
  filterType: {},
  filterText: '',
  showFilterText: true,
  nodeUiSettings: {},
  showThirdPartyLicenses: false,
  showSettings: false,
  returnUrl: AppRoutes.Projects,
} as UiSettingsState);

export const uiSettingsSlice = createSlice({
  name: 'ui-settings',
  initialState,
  reducers: {
    updateDisplayType: (state, action: PayloadAction<DisplayType>) => {
      state.displayType = action.payload;
    },
    updateDashboardOrder: (state, action: PayloadAction<'asc' | 'desc'>) => {
      state.order = action.payload;
    },
    updateDashboardOrderBy: (state, action: PayloadAction<string>) => {
      state.oderBy = action.payload;
    },
    updateFilterType: (state, action: PayloadAction<Dictionary<string[]>>) => {
      state.filterType = action.payload;
    },
    updateFilterText: (state, action: PayloadAction<string>) => {
      state.filterText = action.payload;
    },
    updateFilterTypesToDisplay: (state, action: PayloadAction<FilterTypes[]>) => {
      state.filterTypesToDisplay = action.payload;
    },
    updateOrderByOptions: (state, action: PayloadAction<OrderOption[]>) => {
      state.orderByOptions = action.payload;
    },
    updateShowFilterText: (state, action: PayloadAction<boolean>) => {
      state.showFilterText = action.payload;
    },
    toggleThirdPartyLicenses: state => {
      state.showThirdPartyLicenses = !state.showThirdPartyLicenses;
    },
    toggleSettings: state => {
      state.showSettings = !state.showSettings;
    },
    setReturnRoute: (state, action: PayloadAction<string>) => {
      state.returnUrl = action.payload;
    },
  },
});

export const {
  updateDisplayType,
  updateDashboardOrder,
  updateDashboardOrderBy,
  updateFilterText,
  updateFilterType,
  updateFilterTypesToDisplay,
  updateOrderByOptions,
  updateShowFilterText,
  toggleThirdPartyLicenses,
  toggleSettings,
  setReturnRoute,
} = uiSettingsSlice.actions;

export default uiSettingsSlice.reducer;
