// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import NoProjects from './NoProjects';
import React from 'react';

export default function List() {
  return (
    <>
      <NoProjects />
    </>
  );
}
