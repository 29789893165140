// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface DeleteProjectState {
  id?: string;
  show: boolean;
}

const initialState: DeleteProjectState = {
  id: undefined,
  show: false,
};

export const deleteProjectSlice = createSlice({
  name: 'delete-project',
  initialState,
  reducers: {
    toggleDeleteDialog: state => {
      state.show = !state.show;
      if (!state.show) {
        state.id = undefined;
      }
    },
    setDeleteId: (state, action: PayloadAction<string | undefined>) => {
      state.id = action.payload;
      state.show = true;
    },
  },
});

export const { toggleDeleteDialog, setDeleteId } = deleteProjectSlice.actions;
export default deleteProjectSlice.reducer;
