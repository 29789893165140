// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { TFunction } from 'i18next';
import React, { ReactElement } from 'react';
import { COLORS, ICONS } from '@tls/treact-ui';
import {
  trLightOrange,
  trLightPink,
  trLightPurple,
  trLightViolet,
  trLime,
} from 'components/treactui-template/atoms/AdditionalColors';
import { Color } from '@tls/sw91-communication/types/com.base';

export type ColorOptionType = {
  id: string;
  label: string;
  icon: ReactElement;
};

function colorToId(color?: Color) {
  if (!color) return COLORS.trblue2.rgb;
  return `${color.red},${color.green},${color.blue}`;
}

export function IdToColor(id: string): Color {
  const splitId = id.split(',');
  if (splitId.length !== 3) return IdToColor(COLORS.trblue2.rgb);

  return {
    red: parseInt(splitId[0]),
    green: parseInt(splitId[1]),
    blue: parseInt(splitId[2]),
  };
}

export function getColorOption(color?: Color) {
  const id = colorToId(color);
  return colorOptions.find(c => c.id === id) ?? colorOptions[0];
}

export const colorOptions: ColorOptionType[] = [
  {
    id: COLORS.trblue2.rgb,
    label: 'color.blue',
    icon: <ICONS.IconStop key='blue' color={COLORS.trblue2.hex} height='1.5rem' />,
  },
  {
    id: trLightOrange.rgb,
    label: 'color.orange',
    icon: <ICONS.IconStop key='orange' color={trLightOrange.hex} height='1.5rem' />,
  },
  { id: trLime.rgb, label: 'color.lime', icon: <ICONS.IconStop key='lime' color={trLime.hex} height='1.5rem' /> },
  {
    id: trLightPurple.rgb,
    label: 'color.purple',
    icon: <ICONS.IconStop key='purple' color={trLightPurple.hex} height='1.5rem' />,
  },
  {
    id: trLightViolet.rgb,
    label: 'color.violet',
    icon: <ICONS.IconStop key='violet' color={trLightViolet.hex} height='1.5rem' />,
  },
  {
    id: trLightPink.rgb,
    label: 'color.pink',
    icon: <ICONS.IconStop key='pink' color={trLightPink.hex} height='1.5rem' />,
  },
];

export const defaultColorId: Color = {
  red: parseInt(colorOptions[0].id.split(',')[0]),
  green: parseInt(colorOptions[0].id.split(',')[1]),
  blue: parseInt(colorOptions[0].id.split(',')[2]),
};

export const colorKeySelector = (o: ColorOptionType): React.Key => o.id;
export const colorLabelSelector = (t: TFunction) => (o: ColorOptionType) => t(o.label);
export const colorIconSelector = (o: ColorOptionType) => o.icon;
