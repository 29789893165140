// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { COLORS } from '@tls/treact-ui';
import {
  ChartData,
  CoreChartOptions,
  DatasetChartOptions,
  ElementChartOptions,
  LineControllerChartOptions,
  PluginChartOptions,
  ScaleChartOptions,
} from 'chart.js';
import { _DeepPartialObject } from 'chart.js/dist/types/utils';
import { AnnotationOptions } from 'chartjs-plugin-annotation';
import { useRef } from 'react';

export type Coordinate = {
  x: number;
  y: number;
};

export type ModelBoxPlotChartType = ChartData<'scatter', Coordinate[], string>;
export function useDefaultDataSet() {
  return useRef<ModelBoxPlotChartType>({
    datasets: [
      {
        data: [],
        pointBorderWidth: [],
        pointRadius: [],
        pointBorderColor: [],
        hoverBorderColor: COLORS.trblue3.hex,
        borderColor: COLORS.trblue4.hex,
        pointStyle: [],
        pointHoverRadius: 8,
        pointHoverBorderWidth: 2,
      },
    ],
  });
}

export type OptionType = _DeepPartialObject<
  CoreChartOptions<'scatter'> &
    ElementChartOptions<'scatter'> &
    PluginChartOptions<'scatter'> &
    DatasetChartOptions<'scatter'> &
    ScaleChartOptions<'scatter'> &
    AnnotationOptions<'box'> &
    LineControllerChartOptions
>;

export function useDefaultOptions() {
  return useRef<OptionType>({
    responsive: true,
    clip: false,
    font: {
      family: 'Open Sans, sans-serif',
    },
    plugins: {
      legend: {
        display: false,
      },
      annotation: {
        annotations: {
          box: {
            type: 'box',
            xMin: -0.1,
            xMax: 0.1,
            yMin: 0,
            yMax: 0,
            borderWidth: 1,
            backgroundColor: `rgba(${COLORS.trgrey5.rgb}, 0.25)`,
          },
          median: {
            xMax: 0.1,
            xMin: -0.1,
            yMax: 0,
            yMin: 0,
            borderWidth: 1.5,
            type: 'line',
          },
          verticalUpperWhisk: {
            xMin: 0,
            xMax: 0,
            yMax: 0,
            yMin: 0,
            borderWidth: 1.5,
            type: 'line',
          },
          verticalLowerWhisk: {
            xMin: 0,
            xMax: 0,
            yMax: 0,
            yMin: 0,
            borderWidth: 1.5,
            type: 'line',
          },
          horizontalLowerWhisk: {
            xMax: 0.075,
            xMin: -0.075,
            yMax: 0.0,
            yMin: 0.0,
            borderWidth: 1.5,
            type: 'line',
          },
          horizontalUpperWhisk: {
            xMax: 0.075,
            xMin: -0.075,
            yMax: 0,
            yMin: 0,
            borderWidth: 1.5,
            type: 'line',
          },
        },
        animations: {
          duration: 100,
        },
      },
      tooltip: {
        backgroundColor: COLORS.trwhite.hex,
        titleColor: COLORS.trblack.hex,
        bodyColor: COLORS.trblack.hex,
        borderColor: COLORS.trblue4.hex,
        footerColor: COLORS.trblack.hex,
        borderWidth: 0.3,
        boxPadding: 5,
        cornerRadius: 0,
        callbacks: {
          label: () => '',
        },
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'y',
        },
        limits: {
          y: {
            max: 1,
            min: 0,
          },
        },
        pan: {
          enabled: true,
          mode: 'y',
        },
      },
    },
    backgroundColor: COLORS.trgrey7.hex,
    borderWidth: 1,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          display: false,
          precision: 0,
        },
        border: {
          display: false,
        },
        max: 1,
        min: -1,
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          display: true,
          padding: 5,
        },
        border: {
          display: true,
          color: COLORS.trgrey4.hex,
        },
        max: 1,
        min: -0,
        title: {
          text: 'IoU',
          display: true,
          font: {
            weight: 'bold',
          },
          color: COLORS.trblack.hex,
        },
      },
    },
    layout: {
      padding: {
        left: 10,
        top: 20,
        bottom: 10,
      },
    },
    maintainAspectRatio: false,
  });
}
