// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { IconProps } from '@tls/treact-ui/lib/components/Icons/types';
import React from 'react';

const IconHome = ({ id, width, height, viewBox, strokeWidth, stroke, fill, ...other }: IconProps) => {
  return (
    <svg
      id={id ?? 'svg-icon-home'}
      data-name='svg-icon-home'
      data-testid='svg-icon-home'
      xmlns='http://www.w3.org/2000/svg'
      width={width ?? '2.5rem'}
      height={height ?? '2.55rem'}
      viewBox={viewBox ?? '0 0 18 18'}
      strokeWidth={strokeWidth ?? '0'}
      stroke={stroke ?? 'currentColor'}
      fill={fill ?? 'currentColor'}
      {...other}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.99973 0L0 6.546V18H6.27308V11.4551H11.7269V18H18V6.546L8.99973 0Z'
        fill={fill ?? 'currentColor'}
      />
    </svg>
  );
};

export default IconHome;
