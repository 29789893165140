// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import React, { RefObject } from 'react';

export type NavbarContextType = {
  navbarRef?: RefObject<HTMLUxcAppHeaderElement>;
};

export const NavbarContext = React.createContext<NavbarContextType>({
  navbarRef: undefined,
});
