// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { useQuery } from '@tanstack/react-query';
import { get } from '@tls/react-saf-ui';
import { Bom } from '@tls/treact-ui-templates/dist/templates/LicenseComponent/CycloneDXModels';
import { useEffect, useState } from 'react';

export default function useBomApi() {
  const [bom, setBom] = useState<Bom>();
  const [loading, setLoading] = useState(true);

  const { data } = useQuery({
    queryKey: ['thirdPartyLicenses'],
    queryFn: async () => await get('v1/bom'),
  });

  useEffect(() => {
    if (!data?.data || !Array.isArray(data?.data) || data.data.length < 1) return;

    async function fetchData() {
      setLoading(true);
      const newBoms: Bom[] = [];

      for (const id of data.data) {
        const url = `v1/bom/${encodeURIComponent(id)}`;
        const { data, status } = await get(url);

        if (status > 199 && status < 300 && data) {
          newBoms.push(data);
        }
      }

      //Merge all boms into one
      setBom(
        newBoms.reduce(
          (acc, val) => {
            if (!acc) return val;
            val.components.push(...acc.components);
            return val;
          },
          undefined as Bom | undefined
        )
      );
      setLoading(false);
    }
    fetchData();
  }, [data?.data]);

  return { bom, loading };
}
