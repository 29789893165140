// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { COLORS } from '@tls/treact-ui';
import styled from 'styled-components';

export const InfoTag = styled.div`
  background: ${COLORS.trgrey1.hex};
  border: 1px solid transparent;
  color: ${COLORS.trgrey3.hex};
  padding: 0 0.25rem;
  white-space: nowrap;
`;
