// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF

export enum OS {
  Unknown,
  Windows,
  MacOs,
}

export default function GetOS() {
  let os = OS.Unknown;
  if (navigator.userAgent.indexOf('Win') !== -1) os = OS.Windows;
  if (navigator.userAgent.indexOf('Mac') !== -1) os = OS.MacOs;
  return os;
}
