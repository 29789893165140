// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { StrategyType } from '@tls/sw91-communication/types/com.base';
import { Atoms, ICONS, Molecules } from '@tls/treact-ui';
import {
  ColorOptionType,
  colorIconSelector,
  colorKeySelector,
  colorLabelSelector,
  colorOptions,
  getColorOption,
} from 'components/common/ColorOptions';
import useProjectsMetadata from 'hooks/useProjectsMetadata';
import { GetFirstProjectUseCaseMessage } from 'model/ProjectMetaMessageExtensions';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';

const strategyOptions = [
  { id: StrategyType.SIMPLE_STRATEGY, labelKey: 'highPerformance' },
  { id: StrategyType.TWO_STEP_STRATEGY, labelKey: 'highPrecision' },
];

export type ProjectSettings = {
  projectName: string;
  strategy: StrategyType;
  labelName: string;
  labelColor: ColorOptionType;
  tags: string[];
};

export type ProjectSettingsProps = {
  onChange: (newSettings: ProjectSettings) => void;
  containerRef?: React.RefObject<Element>;
};

type Tag = {
  label: string;
};

const tagKeySelector = (t: Tag) => t.label;
const nullTag: Tag = { label: ' ' };

export default function ProjectSettingsForm({ onChange, containerRef }: ProjectSettingsProps) {
  const { id, duplicateSourceId } = useAppSelector(s => s.projectSettings);
  const { t } = useTranslation();

  const [projectName, setProjectName] = useState<string>('');
  const [projectStrategy, setProjectStrategy] = useState(strategyOptions[0]);
  const { selectedProject, allTags } = useProjectsMetadata(id ?? duplicateSourceId);

  // Set up Tag logic.
  const [tags, setTags] = useState<string[]>([]);
  const [selectedTag, setSelectedTag] = useState<Tag>(nullTag);
  const [allTagsAsObject, setAllTagsAsObject] = useState<Tag[]>([]);

  useEffect(() => {
    const newTags: Tag[] = [...allTags.map(t => ({ label: t }))];
    if (!allTags.includes(selectedTag.label)) {
      newTags.push(selectedTag);
    }
    setAllTagsAsObject(newTags);
  }, [allTags, selectedTag]);

  const addSelectedTag = useCallback(() => {
    const trimmedLabel = selectedTag.label?.trim();
    if (!trimmedLabel || tags.includes(selectedTag.label)) return;
    setTags([...tags, trimmedLabel]);
  }, [selectedTag.label, tags]);

  const removeTag = useCallback(
    (tag: string) => () => {
      setTags(tags.filter(t => t !== tag));
    },
    [tags]
  );

  //Set up class label information
  const [labelName, setLabelName] = useState<string>(t('project.label.title'));
  const [color, setColor] = useState<ColorOptionType>(colorOptions[0]);

  useEffect(() => {
    setProjectName(selectedProject?.projectName ?? '');
    setTags(selectedProject?.tags ?? []);

    setProjectStrategy(strategyOptions.find(s => s.id === selectedProject?.strategyType) ?? strategyOptions[0]);

    const firstColor = GetFirstProjectUseCaseMessage(selectedProject);
    setColor(getColorOption(firstColor?.labelColor));
    setLabelName(firstColor?.className ?? 'Label');
  }, [selectedProject, t]);

  useEffect(() => {
    onChange({
      labelName,
      labelColor: color,
      projectName,
      tags,
      strategy: projectStrategy.id,
    });
  }, [color, labelName, onChange, projectName, tags, projectStrategy]);

  return (
    <Container>
      <TwoColumns>
        <Atoms.InputFieldText
          label={t('project.name')}
          flag='mandatory'
          value={projectName}
          onValueChange={setProjectName}
          asAtom
        />
        {!!id || !!duplicateSourceId ? (
          <Atoms.OutputText
            label={t('project.strategy.title')}
            infoPrimary={t(`project.strategy.${projectStrategy.labelKey}`)}
          />
        ) : (
          <Molecules.DropdownContent
            label={t('project.strategy.title')}
            className='no-shadow'
            options={strategyOptions}
            selectedOption={projectStrategy}
            keySelector={k => k.id}
            labelSelector={k => t(`project.strategy.${k.labelKey}`)}
            onChange={setProjectStrategy}
          />
        )}
      </TwoColumns>
      <Atoms.Divider className='left-divider' headline={t('project.settings.label.title')} label='' />
      <TwoColumns>
        <Atoms.InputFieldText
          flag={id ? 'none' : 'mandatory'}
          label={t('project.settings.label.name')}
          value={labelName}
          onValueChange={setLabelName}
          asAtom
        />
        <Molecules.DropdownContent
          label={t('project.settings.label.color')}
          className='no-shadow'
          options={colorOptions}
          selectedOption={color}
          keySelector={colorKeySelector}
          labelSelector={colorLabelSelector(t)}
          iconSelector={colorIconSelector}
          onChange={setColor}
          containerRef={containerRef}
        />
      </TwoColumns>

      <Atoms.Divider className='left-divider push-up' headline={t('project.tag.title')} label='' />
      <TwoColumns>
        <Molecules.Combobox
          options={allTagsAsObject}
          selectedOption={selectedTag}
          keySelector={tagKeySelector}
          labelSelector={tagKeySelector}
          noResultText=''
          onChange={setSelectedTag}
          containerRef={containerRef}
          allowCustomValue
          className='no-shadow'
          label={t('project.settings.tag.name')}
        />
        <CenterButton>
          <Atoms.Button
            variant='secondary'
            icon={<ICONS.IconPlus />}
            label={t('project.settings.tag.add')}
            onClick={addSelectedTag}
          />
        </CenterButton>
      </TwoColumns>
      <TagContainer>
        {tags.map(t => (
          //@ts-expect-error
          <Atoms.Tag key={t} label={t} onClose={removeTag(t)} />
        ))}
      </TagContainer>
    </Container>
  );
}

const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: wrap;
  margin-top: -1rem;
`;

const TwoColumns = styled.div`
  display: grid;
  grid-template-columns: 55% 45%;
  column-gap: 1rem;
  width: calc(100% - 1rem);
  margin-bottom: 0.5rem;
`;

const CenterButton = styled.div`
  margin: auto 0;
`;

const Container = styled.div`
  .left-divider {
    margin: 0rem 0 0.1875rem 0;
    text-align: left;
  }

  .push-up {
    margin: -1rem 0 0.1875rem 0;
  }

  .no-shadow {
    button {
      box-shadow: none;
    }
    div {
      box-shadow: none !important;
    }
  }
`;
