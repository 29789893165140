// SPDX-FileCopyrightText: 2024 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { Atoms, ICONS, Organisms } from '@tls/treact-ui';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useUserContractsApi from 'hooks/useUserContractsApi';
import styled from 'styled-components';
import { User } from 'model/User';
import useRolesApi from 'hooks/useRolesApi';
import ContractsTable from '../Contracts/ContractsTable';

const { IconLoading } = ICONS;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 1px + 1px);
`;

const Form = styled.form`
  flex-grow: 1;
  overflow-y: auto;
`;

const Grid = styled.div`
  display: grid;
  padding: 1rem;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
`;
const GridItem = styled.div``;

enum EditAction {
  Save,
  Delete,
}
export default function UserDetails({
  user,
  users,
  addOrEditUser,
  deleteUser,
  onClose,
  isUsernameEditable = true,
  isLoading = false,
  showContracts = true,
}: Readonly<Props>) {
  const { t } = useTranslation();
  const { roles } = useRolesApi();
  const { data: contracts, isLoading: isContractsLoading } = useUserContractsApi(user.id ?? '');

  const [editUser, setEditUser] = useState(user);
  const [action, setAction] = useState<EditAction>();

  const isNameAlreadyExist = useMemo(
    () =>
      (users?.filter(otherUser => otherUser.id !== user.id && otherUser.userName === editUser.userName)?.length ?? 0) >
      0,
    [editUser, user, users]
  );

  const onDelete = useCallback(async () => {
    setAction(EditAction.Delete);
    if (deleteUser) await deleteUser(user);
    setAction(undefined);
    if (onClose) onClose();
  }, [deleteUser, onClose, user]);

  const onAccept = useCallback(async () => {
    setAction(EditAction.Save);
    await addOrEditUser(editUser);
    setAction(undefined);
    if (onClose) onClose();
  }, [editUser, onClose, addOrEditUser]);

  useEffect(() => {
    setEditUser(user);
  }, [user]);

  const toggleRoles = useCallback(
    (newRole: string) => {
      if (editUser?.roles?.includes(newRole)) {
        setEditUser({
          ...editUser,
          roles: editUser.roles?.filter(role => role !== newRole) ?? [],
        });
      } else if (editUser) {
        setEditUser({
          ...editUser,
          roles: [...(editUser.roles ?? []), newRole],
        });
      }
    },
    [editUser, setEditUser]
  );

  const hasUserChanges = useMemo(() => JSON.stringify(editUser) !== JSON.stringify(user), [editUser, user]);

  const editing = action === EditAction.Save;

  const rightActions: React.JSX.Element[] = [];
  if (onClose) {
    rightActions.push(
      <Atoms.FooterButton
        key='abort'
        variant={'secondary'}
        label={t('cancel')}
        onClick={onClose}
        disabled={isLoading}
      />
    );
  }
  rightActions.push(
    <Atoms.FooterButton
      key='save'
      variant={'primary'}
      label={t('save')}
      onClick={onAccept}
      icon={editing ? <IconLoading /> : undefined}
      disabled={isLoading || !hasUserChanges || editing}
    />
  );

  if (editUser.id !== user.id) return <></>;

  const deleting = action === EditAction.Delete;

  return (
    <Root>
      <Form autoComplete='off'>
        <Grid>
          <GridItem>
            <Atoms.InputFieldText
              label={t('user.userName')}
              value={editUser.userName}
              type={isNameAlreadyExist ? 'error' : 'none'}
              helperText={isNameAlreadyExist ? t('user.validation.userNameError') : undefined}
              onValueChange={newValue =>
                setEditUser({
                  ...editUser,
                  userName: newValue,
                })
              }
              disabled={!isUsernameEditable}
            />
            <Atoms.InputFieldText
              label={t('user.email')}
              value={editUser.email ?? ''}
              onValueChange={newValue =>
                setEditUser({
                  ...editUser,
                  email: newValue,
                })
              }
            />
          </GridItem>
          <GridItem>
            <Atoms.InputFieldText
              label={t('user.lastName')}
              value={editUser.lastName ?? ''}
              onValueChange={newValue =>
                setEditUser({
                  ...editUser,
                  lastName: newValue,
                })
              }
            />

            <Atoms.InputFieldText
              label={t('user.firstName')}
              value={editUser.firstName ?? ''}
              onValueChange={newValue =>
                setEditUser({
                  ...editUser,
                  firstName: newValue,
                })
              }
            />
          </GridItem>

          <GridItem>
            <span className='font-size_m font-weight_bold title'>{t('settings.roles.title')}</span>
            {roles?.map(r => (
              <LeftAlignedCheckbox
                key={r.id}
                label={r.name}
                value={
                  editUser.roles?.includes(r.id ?? '')
                    ? Atoms.Checkbox.Selection.selected
                    : Atoms.Checkbox.Selection.unselected
                }
                onValueChange={() => toggleRoles(r.id ?? '')}
              />
            ))}
          </GridItem>
        </Grid>
        {showContracts ? <ContractsTable data={contracts} isLoading={isContractsLoading} /> : null}
      </Form>
      <Organisms.Footer
        left={
          deleteUser
            ? [
                <Atoms.FooterButton
                  key='delete'
                  variant={'secondary'}
                  label={t('delete')}
                  onClick={onDelete}
                  icon={deleting ? <IconLoading /> : undefined}
                  disabled={isLoading || deleting}
                />,
              ]
            : []
        }
        right={rightActions}
      />
    </Root>
  );
}

interface Props {
  user: User;
  users?: User[];
  onClose?: () => void;
  addOrEditUser: (user: User) => Promise<void>;
  deleteUser?: (user: User) => Promise<void>;
  isUsernameEditable?: boolean;
  isLoading?: boolean;
  showContracts?: boolean;
}

const LeftAlignedCheckbox = styled(Atoms.Checkbox)`
  align-items: start;
`;
