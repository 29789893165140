// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { Atoms, Molecules, Organisms } from '@tls/treact-ui';
import DeleteProjectDialog from 'components/project/DeleteDialog';
import Options from 'components/projectSelection/Options';
import PageContent from 'components/treactui-template/organisms/page/PageContent';
import PageHeader from 'components/treactui-template/organisms/page/PageHeader';
import PageWrapper from 'components/treactui-template/organisms/page/PageWrapper';
import useProjectsMetadata from 'hooks/useProjectsMetadata';
import { DefaultBreakpoints } from 'hooks/useMediaQuery';
import FilterGridContainer, { ActionButtonShell, PageTitleShell } from 'layout/header/FilterGridContainer';
import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router';
import { Navigate, useNavigate, Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { AppRoutes, ProjectSubPath } from 'utilities/router/AppRoutes';
import ShareProjectDialog from 'components/project/ShareProjectDialog';
import { useAppSelector } from 'store/hooks';
import CreateProjectDialog from 'components/projectSelection/CreateProjectDialog';
import ParamsWithId from 'model/ParamsWithId';
import SSimProvider from 'components/project/label/similarity/SSimProvider';
import ImagesProvider from 'components/project/images/ImagesProvider';

export default function Project() {
  const { t } = useTranslation();
  const location = useLocation();
  const { id } = useParams<ParamsWithId>();
  const { selectedProject } = useProjectsMetadata(id);
  const { projectName, lockedBy } = selectedProject ?? {};

  const navigate = useNavigate();
  const goToSubpath = useCallback((path: string) => () => navigate(path), [navigate]);

  const headerReference = useRef<HTMLDivElement>(null);

  const isContractLoading = useAppSelector(s => s.license.loading);
  const isActive = useAppSelector(s => s.license.contract?.isActive ?? false);
  if (
    [
      AppRoutes.Project,
      `${AppRoutes.Project}/`,
      `${AppRoutes.Project}/${id}`,
      AppRoutes.ProjectWithId(id ?? '', ''),
    ].includes(location.pathname)
  ) {
    return <Navigate to={AppRoutes.ProjectWithId(id ?? '', ProjectSubPath.Label)} />;
  }

  if (
    !isActive &&
    !isContractLoading &&
    [AppRoutes.ProjectWithId(id ?? '', ProjectSubPath.Label)].includes(location.pathname)
  ) {
    return <Navigate to={AppRoutes.ProjectWithId(id ?? '', ProjectSubPath.Evaluate)} />;
  }

  return (
    <PageWrapper>
      <PageHeader>
        <FilterGridContainer pxBreakpoint={DefaultBreakpoints.md} showActionButton showView showFilter={false}>
          <PageTitleShell>
            <Molecules.Breadcrumbs>
              <Atoms.Breadcrumb label={t('projects.title')} onClick={goToSubpath(AppRoutes.Projects)} />
              <Atoms.Breadcrumb label={projectName ?? ''} selected />
            </Molecules.Breadcrumbs>
          </PageTitleShell>
          <ActionButtonShell>
            <Options projectId={id ?? ''} projectName={projectName} lockedBy={lockedBy} />
          </ActionButtonShell>
        </FilterGridContainer>
      </PageHeader>
      <PageContent displayAsPaper disableScroll>
        <div ref={headerReference}>
          <Organisms.SubMenuHorizontal>
            <Organisms.SubMenuHorizontal.SubMenuTab
              label={t('project.label.title')}
              selected={location.pathname?.startsWith(AppRoutes.ProjectLabelFullPath(id ?? ''))}
              onClick={goToSubpath(AppRoutes.ProjectLabelFullPath(id ?? ''))}
              centered
              //@ts-expect-error: Missing type
              disabled={!isActive}
              key='label'
            />
            <Organisms.SubMenuHorizontal.SubMenuTab
              label={t('project.evaluation.title')}
              selected={location.pathname?.startsWith(AppRoutes.ProjectEvaluationFullPath(id ?? ''))}
              onClick={goToSubpath(AppRoutes.ProjectEvaluationFullPath(id ?? ''))}
              centered
              key='evaluation'
            />
          </Organisms.SubMenuHorizontal>
        </div>
        <PageContainer headerHeight={headerReference.current?.clientHeight}>
          <ImagesProvider>
            <SSimProvider>
              <Outlet />
            </SSimProvider>
          </ImagesProvider>
        </PageContainer>
      </PageContent>
      <CreateProjectDialog />
      <DeleteProjectDialog />
      <ShareProjectDialog />
    </PageWrapper>
  );
}

const PageContainer = styled.div<{ headerHeight?: number }>`
  height: calc(100% - ${({ headerHeight }) => headerHeight}px);
  position: relative;
`;
