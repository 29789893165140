// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { useQuery } from '@tanstack/react-query';
import { get, put } from '@tls/react-saf-ui';
import { DataUsageAgreement } from '@tls/sw91-communication/types/com.base';
import { useCallback } from 'react';

export default function useSettingsApi() {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['dataAgreement'],
    queryFn: async () => await get('settings/data-agreement', false, true),
  });

  const setDataAgreement = useCallback(
    async (value: DataUsageAgreement) => {
      const result = await put('settings/data-agreement', { usage: value });
      refetch();
      return result;
    },
    [refetch]
  );

  return { dataUsageAgreement: data?.data as DataUsageAgreement | undefined, isLoading, setDataAgreement };
}
