// SPDX-FileCopyrightText: 2017-2022 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
export default async function saveFile(data: BlobPart[], type: string, filename: string) {
  return new Promise<void>(resolve => {
    const newBlob = new Blob(data, { type });

    const objectData = URL.createObjectURL(newBlob);
    const link = document.createElement('a');
    link.href = objectData;
    link.download = filename;
    link.click();
    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      URL.revokeObjectURL(objectData);
      resolve();
    }, 600);
  });
}
