// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import React from 'react';

export type PageContextType = {
  headerHeightInPx: number;
  navBarHeightInPx: number;
  setHeaderHeight: (headerHeightInPx: number) => void;
  setNavHeight: (setNavHeight: number) => void;
};

export const PageContext = React.createContext<PageContextType>({
  headerHeightInPx: 0,
  navBarHeightInPx: 0,
  setHeaderHeight: function (headerHeightInPx: number): void {
    this.headerHeightInPx = headerHeightInPx;
  },
  setNavHeight: function (setNavHeight: number): void {
    this.navBarHeightInPx = setNavHeight;
  },
});
