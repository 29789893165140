// SPDX-FileCopyrightText: 2024 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { IconProps } from '@tls/treact-ui/lib/components/Icons/types';
import React from 'react';

const IconLocked = ({ id, width, height, viewBox, strokeWidth, stroke, fill, ...other }: IconProps) => {
  return (
    <svg
      id={id ?? 'svg-locked'}
      data-name='svg-locked'
      data-testid='svg-locked'
      xmlns='http://www.w3.org/2000/svg'
      width={width ?? '2.25rem'}
      height={height ?? '2.25rem'}
      viewBox={viewBox ?? '0 0 25 31'}
      strokeWidth={strokeWidth ?? '0'}
      stroke={stroke ?? 'currentColor'}
      fill={fill ?? 'currentColor'}
      {...other}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.751 22.514C9.654 21.875 9 20.748 9 19.5C9 17.571 10.57 16 12.5 16C14.429 16 16 17.571 16 19.5C16 20.748 15.345 21.875 14.248 22.513L14 22.659V27H13.935L13.791 27.248C13.516 27.719 13.034 28 12.5 28C11.966 28 11.483 27.719 11.208 27.248L11.063 27H11V22.659L10.751 22.514ZM12.5 3C15.532 3 18 5.468 18 8.5V14H7V8.5C7 5.468 9.467 3 12.5 3ZM21 14V8.5C21 3.813 17.186 0 12.5 0C7.813 0 4 3.813 4 8.5V14H0V31H25V14H21Z'
        fill={fill ?? 'currentColor'}
      />
    </svg>
  );
};

export default IconLocked;
