// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import React from 'react';

export type EvaluationContextType = {
  index: number;
  length: number;
  updateIndex: (index: number) => void;
  updateLength: (length: number) => void;
};

export const EvaluationContext = React.createContext<EvaluationContextType>({
  index: 0,
  length: 0,
  updateIndex: function (index: number): void {
    if (index < 0) index = 0;
    else if (index >= this.length) {
      index = this.length - 1;
    }
    this.index = index;
  },
  updateLength: function (length: number): void {
    this.length = length;
    if (this.index > this.length) {
      this.index = this.length - 1;
    }
  },
});
