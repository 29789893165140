// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProjectVersion } from 'model/ProjectMetaMessageExtensions';

export interface ZoomLevel {
  min: number;
  max: number;
}

export interface EvaluationState {
  hoveredMd5s?: string[];
  selectedVersions?: ProjectVersion[];
  zoomLevel?: ZoomLevel;
  activeMd5?: string;
}

const initialState: EvaluationState = {};

export const projectImageSlice = createSlice({
  name: 'evaluation',
  initialState,
  reducers: {
    setHoveredMd5s: (state, action: PayloadAction<string[]>) => {
      state.hoveredMd5s = action.payload;
    },
    setSelectedVersions: (state, action: PayloadAction<ProjectVersion[]>) => {
      state.selectedVersions = action.payload;
    },
    setZoomLevel: (state, action: PayloadAction<ZoomLevel>) => {
      state.zoomLevel = action.payload;
    },
    setActiveMd5: (state, action: PayloadAction<string | undefined>) => {
      state.activeMd5 = action.payload;
    },
  },
});

export const { setHoveredMd5s, setSelectedVersions, setZoomLevel, setActiveMd5 } = projectImageSlice.actions;
export default projectImageSlice.reducer;
