// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { useCallback } from 'react';
import { useAppDispatch } from 'store/hooks';
import { changePencilWidthStep } from 'store/reducers/projectToolSlice';

export default function useToolHelpers() {
  const dispatch = useAppDispatch();

  const setStepFromBlob = useCallback(
    async (blob: Blob) => {
      const image = await createImageBitmap(blob);

      if (image.width < 1) return;

      dispatch(changePencilWidthStep(100 / image.width));
    },
    [dispatch]
  );
  return { setStepFromBlob };
}
