// SPDX-FileCopyrightText: 2024 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { useQuery } from '@tanstack/react-query';
import { del, get, post, put } from '@tls/react-saf-ui';
import { Role } from 'model/Role';
import { GenericResponse } from 'model/reponse/GenericResponse';
import { useCallback } from 'react';

export default function useRolesApi() {
  const {
    data: roles,
    isLoading: loadingRoles,
    refetch: getRoles,
  } = useQuery<GenericResponse<Role[] | undefined>>({
    queryKey: ['roles'],
    queryFn: async () => await get('roles', false, true),
  });

  const { data: permissions, isLoading: loadingPermissions } = useQuery<GenericResponse<string[]>>({
    queryKey: ['permissions'],
    queryFn: async () => await get('roles/permissions', false, true),
  });

  const editRole = useCallback(
    async (role: Role) => {
      if (!role.id) return;
      const result = await put(`roles/${role.id}`, role);
      getRoles();
      return result;
    },
    [getRoles]
  );

  const addRole = useCallback(
    async (role: Role) => {
      const result = await post('roles', role);
      getRoles();
      return result;
    },
    [getRoles]
  );
  const deleteRole = useCallback(
    async (role: Role) => {
      if (!role.id) return;
      const result = await del(`roles/${role.id}`);
      getRoles();
      return result;
    },
    [getRoles]
  );

  return {
    roles: Array.isArray(roles?.data) ? roles.data : [],
    loadingRoles,
    getRoles,
    editRole,
    addRole,
    deleteRole: deleteRole,
    permissions: permissions?.data,
    loadingPermissions,
  };
}
