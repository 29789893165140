// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { put } from '@tls/react-saf-ui';
import { EarlyStoppingMessage } from '@tls/sw91-communication/types/com.api_number_cruncher';
import { HubContext } from 'components/common/emitter/HubContext';
import { ToastMessageType, queueToast } from 'components/treactui-template/organisms/toast/ToastMessage';
import { Id, toast } from 'react-toastify';
import { ProjectStatus } from 'model/ProjectMetadata';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store/hooks';
import { updateProjectStatus } from 'store/reducers/projectsSlice';

export default function useTrainingStatusApi(projectId: string) {
  const [lastStatus, setLastStatus] = useState(200);

  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [currentToastId, setCurrentToastId] = useState<Id>();

  const onStatusUpdated = useCallback(() => {
    setLoading(false);
    if (currentToastId) {
      toast.dismiss(currentToastId);
      setCurrentToastId(undefined);
    }
  }, [currentToastId]);

  const onEarlyStopping = useCallback(
    (message: EarlyStoppingMessage) => {
      dispatch(
        updateProjectStatus({
          projectId: message.messageHeader?.projectId,
          status: ProjectStatus.TRAINING_STOPPED,
        })
      );

      queueToast({
        headline: t('project.label.training.title'),
        flag: ToastMessageType.Information,
        message: t('project.label.training.earlyStopping'),
      });
    },
    [dispatch, t]
  );

  const { events } = useContext(HubContext);
  useEffect(() => {
    events.on('onProjectStatusChanged', onStatusUpdated);
    events.on('onEarlyStopping', onEarlyStopping);

    return () => {
      events.off('onProjectStatusChanged', onStatusUpdated);
      events.off('onEarlyStopping', onEarlyStopping);
    };
  }, [events, onEarlyStopping, onStatusUpdated]);

  const changeTrainingStatus = useCallback(
    async (newTrainingStatus: ProjectStatus) => {
      if (!projectId) return;

      setLoading(true);
      const api = newTrainingStatus === ProjectStatus.TRAINING_ACTIVE ? 'start' : 'stop';
      const { status: trainingPutStatus } = await put(`projects/${projectId}/training/${api}`, {
        projectStatus: newTrainingStatus,
      });

      setLastStatus(trainingPutStatus);

      if (newTrainingStatus !== ProjectStatus.TRAINING_ACTIVE || trainingPutStatus !== 200) return;

      const id = queueToast({
        headline: t('project.status.provisioning'),
        message: t('project.label.training.provisioning.details'),
        flag: ToastMessageType.Information,
        options: {
          autoClose: false,
        },
      });
      setCurrentToastId(id);
    },
    [projectId, t]
  );

  return { status: lastStatus, changeTrainingStatus, loading };
}
