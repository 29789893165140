// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import React, { useState, useEffect, useCallback } from 'react';
import { Atoms } from '@tls/treact-ui';
import { useTranslation } from 'react-i18next';
import PageWrapper from 'components/treactui-template/organisms/page/PageWrapper';
import PageHeader from 'components/treactui-template/organisms/page/PageHeader';
import PageContent from 'components/treactui-template/organisms/page/PageContent';
import { Outlet, useLocation, Navigate } from 'react-router-dom';
import { AppRoutes } from 'utilities/router/AppRoutes';
import FilterGridContainer, {
  ActionButtonShell,
  FilterOptionsShell,
  FilterShell,
  PageTitleShell,
  SearchShell,
  SecondRowShell,
} from 'layout/header/FilterGridContainer';
import useMediaQuery, { DefaultBreakpoints } from 'hooks/useMediaQuery';
import Search from 'components/header/Search';
import FilterButton from 'components/header/FilterButton';
import HeaderFilter from 'components/header/HeaderFilter';
import { updateDisplayType } from 'store/reducers/uiSettingsSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import AnimateHeight from 'react-animate-height';
import AddProjectButton from 'components/common/AddProjectButton';
import DeleteProjectDialog from 'components/project/DeleteDialog';
import ProjectLockedDialog from 'components/projectSelection/ProjectLockedDialog';
import ShareProjectDialog from 'components/project/ShareProjectDialog';
import CreateProjectDialog from 'components/projectSelection/CreateProjectDialog';

export default function Projects() {
  const { t } = useTranslation();

  const location = useLocation();
  const dispatch = useAppDispatch();
  const previousDisplay = useAppSelector(s => s.uiSettings.displayType);
  useEffect(() => {
    if (location.pathname.startsWith(AppRoutes.ProjectTilesFull)) {
      dispatch(updateDisplayType(AppRoutes.ProjectsTilesSubPath));
    } else if (location.pathname.startsWith(AppRoutes.ProjectListFull)) {
      dispatch(updateDisplayType(AppRoutes.ProjectsListSubPath));
    }
  }, [dispatch, location.pathname]);

  const { width } = useMediaQuery();
  const [showFilter, setShowFilter] = useState(false);
  const showFilterCallback = useCallback((show: boolean) => () => setShowFilter(show), []);
  let filterActions = (
    <>
      <SearchShell>
        <Search />
      </SearchShell>
      <FilterShell>
        <FilterButton onOpen={showFilterCallback(true)} onClose={showFilterCallback(false)} isOpen={showFilter} />
      </FilterShell>
    </>
  );

  if (width < DefaultBreakpoints.md) {
    filterActions = (
      <SecondRowShell showFilter key='second-row'>
        {filterActions}
      </SecondRowShell>
    );
  }

  if ([AppRoutes.Projects, `${AppRoutes.Projects}/`].includes(location.pathname)) {
    return (
      <Navigate
        to={previousDisplay === AppRoutes.ProjectsListSubPath ? AppRoutes.ProjectListFull : AppRoutes.ProjectTilesFull}
      />
    );
  }

  return (
    <PageWrapper>
      <PageHeader>
        <FilterGridContainer pxBreakpoint={DefaultBreakpoints.md} showActionButton showView={false} showFilter={true}>
          <PageTitleShell>
            <Atoms.Headline label={t('projects.title')} />
          </PageTitleShell>
          <ActionButtonShell>
            <AddProjectButton />
          </ActionButtonShell>
          {filterActions}
          <FilterOptionsShell>
            <AnimateHeight duration={100} height={showFilter ? 'auto' : 0}>
              <HeaderFilter onClose={showFilterCallback(false)} />
            </AnimateHeight>
          </FilterOptionsShell>
        </FilterGridContainer>
      </PageHeader>
      <PageContent displayAsPaper>
        <Outlet />
      </PageContent>
      <CreateProjectDialog />
      <DeleteProjectDialog />
      <ProjectLockedDialog />
      <ShareProjectDialog />
    </PageWrapper>
  );
}
