// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { Atoms, ICONS } from '@tls/treact-ui';
import useProjectLabels from 'hooks/useProjectLabels';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import styled from 'styled-components';
import CanvasContainer from './canvas/CanvasContainer';
import IconPredict from 'components/treactui-template/icons/IconPredict';
import { workingCopy } from 'model/ProjectMetaMessageExtensions';
import { useAppSelector } from 'store/hooks';
import { resetMask, setMask } from './canvas/DrawHandlerExtension';
import ParamsWithId from 'model/ParamsWithId';

export default function LabelImage() {
  const { t } = useTranslation();
  const { id } = useParams<ParamsWithId>();
  const { selectedMd5, name, labels } = useProjectLabels();

  const [loading, setLoading] = useState<'reset' | 'predict'>();

  const predict = useCallback(async () => {
    if (!!loading || !selectedMd5) return;
    setLoading('predict');
    await setMask(`projects/${id}/predict/${workingCopy}/${selectedMd5}`);
    setLoading(undefined);
  }, [id, loading, selectedMd5]);

  const reset = useCallback(() => {
    setLoading('reset');
    resetMask();
    setLoading(undefined);
  }, []);

  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (event.key.toLowerCase() === 'p') {
        predict();
      }
    },
    [predict]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  const predictEnabled = useAppSelector(s => s.label.predictEnabled);

  const hasMask = labels.find(l => l.imageMd5 === selectedMd5)?.hasMask;

  return (
    <Container>
      <Header>
        <span className='font-size_m font-weight_bold'>{t('project.label.image.title')}</span>
        <p className='font-size_m ellipsis'>{name}</p>
        <Atoms.Button
          icon={loading === 'predict' ? <ICONS.IconLoading /> : <IconPredict width='1.75rem' />}
          label={t('project.label.image.predict')}
          variant='secondary'
          onClick={predict}
          disabled={!predictEnabled || !!loading || !selectedMd5}
        />
        <Atoms.Button
          icon={loading === 'reset' ? <ICONS.IconLoading /> : <ICONS.IconDelete />}
          label={t('project.label.image.deleteLabel')}
          variant='secondary'
          onClick={reset}
          disabled={!!loading || !selectedMd5}
        />
      </Header>
      <CanvasContainer imageId={selectedMd5} projectId={id} hasMask={hasMask} />
    </Container>
  );
}
const Container = styled.div`
  position: relative;
  height: 100%;
  display: grid;
  grid-template-rows: 3rem auto;
`;

const Header = styled.div`
  padding: 0.5rem 0.5rem 0;
  display: grid;
  grid-template-columns: auto 1fr auto auto;
  grid-template-rows: auto 1fr auto;
  align-items: center;
  column-gap: 0.5rem;
  .ellipsis {
    text-overflow: ellipsis;
    word-break: normal;
    white-space: nowrap;
    overflow: hidden;
  }
  p {
    margin: 0;
  }
`;
