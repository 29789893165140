// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import UpdateEmitter from 'components/common/emitter/UpdateEmitter';
import React from 'react';

export type HubContextType = {
  events: UpdateEmitter;
};

export const HubContext = React.createContext<HubContextType>({
  events: new UpdateEmitter(),
});
