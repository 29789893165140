// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import React, { useCallback, useEffect } from 'react';
import { Atoms, COLORS, FLAGS, ICONS, Organisms } from '@tls/treact-ui';
import { useTranslation } from 'react-i18next';
import useProjectImportApi from 'hooks/useProjectImportApi';
import { useAppDispatch } from 'store/hooks';
import { toggleDialog } from 'store/reducers/projectSettingsSlice';
import styled, { css } from 'styled-components';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';
import { ToastByStatus } from 'components/treactui-template/organisms/toast/ToastMessage';
import { ImportProjectAckMessage } from '@tls/sw91-communication/types/com.api_db_access';
import { AcknowledgeStatus } from '@tls/sw91-communication/types/com.base';
import useProjectDetailsNavigation from 'hooks/useProjectDetailsNavigation';
import { AppRoutes } from 'utilities/router/AppRoutes';
import { fetchProjects } from 'store/reducers/projectsSlice';
const { Dialog } = Organisms;

const InformationFlag = FLAGS.COMPONENTS.information;
const { IconLoading, IconFolderOpen } = ICONS;

type Props = {
  saveOnEnter?: boolean;
};

export default function ImportProjectDialogContent({ saveOnEnter = false }: Readonly<Props>) {
  const { t } = useTranslation();

  const { uploadProject, uploading, estimatedRemainingTime = 0, progress } = useProjectImportApi();

  let saveIcon: React.JSX.Element | undefined = <IconLoading />;
  if (!uploading) {
    saveIcon = undefined;
  }

  const dispatch = useAppDispatch();

  const onClose = useCallback(() => {
    dispatch(toggleDialog());
  }, [dispatch]);

  const { open, getRootProps, getInputProps, acceptedFiles } = useDropzone();

  const { navigateToDetails } = useProjectDetailsNavigation();

  const onSave = useCallback(async () => {
    if (acceptedFiles.length === 0) return;

    const result = await uploadProject(acceptedFiles[0]);

    ToastByStatus(result.status, t('projects.import.title'), t('projects.import.error'), t('projects.import.success'));

    if (!result.data) return;
    const createResult = ImportProjectAckMessage.fromJSON(result.data);

    if (createResult?.messageHeader?.projectId && createResult?.status?.status === AcknowledgeStatus.SUCCESS) {
      navigateToDetails(
        AppRoutes.ProjectLabelFullPath(createResult.messageHeader.projectId),
        createResult.messageHeader.projectId
      );
      dispatch(fetchProjects());
      dispatch(toggleDialog());
    }
  }, [acceptedFiles, dispatch, navigateToDetails, t, uploadProject]);

  const handleEnterPress = useCallback(
    (event: KeyboardEvent) => {
      const { key } = event;

      if (key === 'Enter') {
        onSave();
      }
    },
    [onSave]
  );

  useEffect(() => {
    if (!saveOnEnter) return;
    window.addEventListener('keydown', handleEnterPress);

    return () => {
      window.removeEventListener('keydown', handleEnterPress);
    };
  }, [handleEnterPress, saveOnEnter]);

  let fileName = '';
  if (acceptedFiles.length > 0) {
    fileName = acceptedFiles[0].name;
  }

  const details =
    estimatedRemainingTime && estimatedRemainingTime > 0 ? (
      <Info>
        <InformationFlag width='1.25rem' />
        <span>{t('projects.import.estimated', { time: estimatedRemainingTime.toFixed(1) })}</span>
      </Info>
    ) : undefined;

  return (
    <>
      <Dialog.DialogContent>
        <TextInputSection>
          <Atoms.InputFieldText
            flag='mandatory'
            label={t('projects.import.path')}
            value={fileName}
            disabled
            onClick={open}
            onValueChange={() => {}}
          />
          <div className='open'>
            <Atoms.Button variant='secondary' icon={<IconFolderOpen />} onClick={open} disabled={uploading} />
          </div>
        </TextInputSection>
        {!uploading ? (
          <UploadFile
            {...getRootProps({
              className: classNames({
                dropzone: true,
                disabled: uploading,
              }),
            })}
          >
            <input {...getInputProps()} />
            <span className='font-size_m'>{t('projects.import.description')}</span>
          </UploadFile>
        ) : (
          <LoaderContainer>
            <ProgressContainer>
              <span>{t('projects.import.progress')}</span>
              <Atoms.ProgressBar percentage={progress ?? 0} width={100} />
              <span className='font-size_xl'>{progress?.toFixed(0) ?? 0}%</span>
            </ProgressContainer>
            {details}
          </LoaderContainer>
        )}
      </Dialog.DialogContent>
      <Dialog.Footer
        right={[
          <Atoms.FooterButton key='cancel' label={t('cancel')} onClick={onClose} variant='secondary' />,
          <Atoms.FooterButton
            key='import'
            icon={saveIcon}
            label={t('projects.import.shortTitle')}
            onClick={onSave}
            disabled={uploading || acceptedFiles.length === 0}
          />,
        ]}
      />
    </>
  );
}

const TextInputSection = styled.section`
  display: flex;
  column-gap: 1rem;

  .open {
    margin: auto;
  }
`;

const UploadFile = styled.section`
  margin: 0rem;
  border: 2px dashed ${COLORS.trgrey6.hex};
  height: 100%;
  background-color: rgba(${COLORS.trgrey1.rgb}, 0.7);
  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ theme }) => css`
    :hover {
      border-color: ${theme.trcolor2.hex};
      background: ${COLORS.trwhite.hex};
      color: ${theme.trcolor1.hex};
    }

    &:disabled,
    &.disabled {
      border-color: rgba(${COLORS.trgrey2.rgb}, 0.8);
      background-color: rgba(${COLORS.trgrey1.rgb}, 0.2);
      color: rgba(${COLORS.trgrey2.rgb}, 0.8);
    }
  `}
`;

const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: auto 0;
`;

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const Info = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
`;
