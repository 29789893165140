// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { COLORS } from '@tls/treact-ui';
import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { PageContext } from './PageContext';

const PageContentContainer = styled.div<{
  headerHeight: number;
  navHeight: number;
  displayAsPaper?: boolean;
  disableScroll: boolean;
}>`
  ${({ headerHeight, navHeight }) => css`
    height: calc(100vh - ${headerHeight + navHeight + 1}px);
  `}

  ${({ displayAsPaper }) => {
    if (displayAsPaper)
      return css`
        box-shadow: rgb(161 161 161) 0px 0px 6px 0px;
        border: 1px solid ${COLORS.trgrey2.hex};
        margin: 0 1rem;
        background-color: ${COLORS.trwhite.hex};
      `;
  }}
  position: relative;
  ${({ disableScroll }) => {
    if (disableScroll)
      return css`
        overflow-y: hidden;
      `;
    return css`
      overflow-y: auto;
    `;
  }}
`;

export default function PageContent({ displayAsPaper, children, disableScroll }: React.PropsWithChildren<Props>) {
  const { headerHeightInPx, navBarHeightInPx } = useContext(PageContext);
  return (
    <PageContentContainer
      headerHeight={headerHeightInPx}
      navHeight={navBarHeightInPx}
      displayAsPaper={displayAsPaper}
      disableScroll={disableScroll ?? false}
    >
      {children}
    </PageContentContainer>
  );
}

export type Props = {
  displayAsPaper?: boolean;
  disableScroll?: boolean;
};
