// SPDX-FileCopyrightText: 2024 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { User } from 'model/User';
import { useAppSelector } from 'store/hooks';

export default function useFilteredUsers(users?: User[]) {
  const filter = useAppSelector(s => s.uiSettings.filterText)?.toLocaleLowerCase();

  return users?.filter(
    u =>
      u.userName?.toLocaleLowerCase()?.includes(filter) ??
      u.firstName?.toLocaleLowerCase()?.includes(filter) ??
      u.lastName?.toLocaleLowerCase()?.includes(filter) ??
      u.email?.toLocaleLowerCase()?.includes(filter)
  );
}
