// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { Atoms, Molecules } from '@tls/treact-ui';
import useProjectLabels from 'hooks/useProjectLabels';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setTransparency, toggleArtifactDetection } from 'store/reducers/projectToolSlice';
import styled from 'styled-components';

function format(e: number) {
  return `${e.toFixed(0)} %`;
}

export default function ToolSettings() {
  const { t } = useTranslation();

  const artifactDetection = useAppSelector(s => s.tools.artifactDetection);
  const transparency = useAppSelector(s => s.tools.transparency);
  const { selectedMd5 } = useProjectLabels();
  const dispatch = useAppDispatch();
  const onCheckbox = useCallback(() => dispatch(toggleArtifactDetection()), [dispatch]);
  const onTransparencyChange = useCallback((value: number) => dispatch(setTransparency(value)), [dispatch]);

  const handleButtonPress = useCallback(
    (event: KeyboardEvent) => {
      const key = event.key.toLowerCase();

      if (key === 'e') {
        onTransparencyChange(transparency + 5);
      } else if (key === 'q') {
        onTransparencyChange(transparency - 5);
      }
    },
    [onTransparencyChange, transparency]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleButtonPress);

    return () => {
      window.removeEventListener('keydown', handleButtonPress);
    };
  }, [handleButtonPress]);

  return (
    <Container>
      <p className='font-size_m font-weight_bold'>{t('project.label.tools.settings.title')}</p>
      <span className='font-size_s'>{t('project.label.tools.settings.transparency')}</span>
      <div id='slider'>
        <Molecules.SliderHorizontal
          max={100}
          showMin
          formatFn={format}
          initial={transparency}
          onChange={onTransparencyChange}
          disabled={!selectedMd5}
        />
      </div>
      <Checkbox>
        <Atoms.Checkbox
          value={artifactDetection ? 'selected' : 'unselected'}
          label={t('project.label.tools.settings.detection')}
          onValueChange={onCheckbox}
          asAtom
          disabled={!selectedMd5}
        />
      </Checkbox>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  #slider > div > div {
    padding: 0.5rem 1.5rem 2rem 1rem;
  }
`;

const Checkbox = styled.div`
  display: flex;
  justify-items: left;
  align-items: flex-start;
`;
