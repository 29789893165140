// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import React, { useCallback, useEffect, useRef } from 'react';
import { COLORS } from '@tls/treact-ui';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TooltipItem,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import { updateDataPoints, useDefaultData, useDefaultOptions } from './defaults';
import { HistoryInformationMessage } from '@tls/sw91-communication/types/com.base';
import zoomPlugin from 'chartjs-plugin-zoom';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, zoomPlugin);

export type Props = {
  history: HistoryInformationMessage[];
  color: string;
};

export default function TrainingChart({ history, color = COLORS.trblue4.hex }: Props) {
  const { t, i18n } = useTranslation();

  const data = useDefaultData();
  const options = useDefaultOptions();
  const ref = useRef<ChartJSOrUndefined<'line', number[], number>>(null);

  useEffect(() => {
    updateDataPoints(history, data);

    ref.current?.update();
  }, [data, history]);

  const renderLabel = useCallback(
    (i: TooltipItem<'line'>) =>
      `${t('project.label.training.chart.iou')}: ${(i.raw as number)?.toLocaleString(i18n.language, {
        maximumFractionDigits: 2,
      })}`,
    [i18n.language, t]
  );

  const renderFooter = useCallback(
    (i: TooltipItem<'line'>[]) => {
      if (
        i.some(d => {
          return d.dataset.pointStyle && d.dataset.pointStyle[d.dataIndex] === 'rect';
        })
      )
        return t('project.label.training.chart.modelUpdated');
    },
    [t]
  );

  useEffect(() => {
    const dataset = { ...data.current.datasets[0] };
    if (dataset.borderColor) dataset.borderColor = color;
    if (dataset.backgroundColor) dataset.backgroundColor = color;
    if (options.current.plugins?.tooltip?.borderColor) options.current.plugins.tooltip.borderColor = color;
    if (options.current.scales?.x?.title)
      options.current.scales.x.title.text = t('project.label.training.chart.epochs');
    if (options.current.scales?.y?.title) options.current.scales.y.title.text = t('project.label.training.chart.iou');
    if (options.current.plugins?.tooltip?.callbacks?.label)
      options.current.plugins.tooltip.callbacks.label = renderLabel;
    if (options.current.plugins?.tooltip?.callbacks?.footer)
      options.current.plugins.tooltip.callbacks.footer = renderFooter;
    if (dataset.label) dataset.label = t('project.label.training.chart.modelUpdated');
    if (options.current.scales?.y?.ticks)
      options.current.scales.y.ticks.callback = tickValue => tickValue.toLocaleString(i18n.language);

    data.current.datasets = [dataset];

    ref.current?.update();
  }, [color, data, i18n.language, options, renderFooter, renderLabel, t]);

  useEffect(() => {
    const lastEntry = history.slice(-1);
    if (options.current.plugins?.zoom?.limits?.x === undefined || lastEntry.length !== 1 || !lastEntry[0].epochNumber)
      return;
    options.current.plugins.zoom.limits.x.max = Math.ceil(lastEntry[0].epochNumber * 1.1);
  }, [history, options]);

  return (
    <Container className='chart-container'>
      <Line options={options.current} data={data.current} height={100} ref={ref} />
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;
