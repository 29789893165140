// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { COLORS } from '@tls/treact-ui';
import { BoxPlotType } from 'hooks/useModelEvaluationApi';
import { ModelBoxPlotChartType, OptionType } from './defaults';

export function SetBoxPlotOptions(boxPlot: BoxPlotType, options: OptionType) {
  const { thirdQuartile, firstQuartile, median, lowerWhisker, upperWhisker } = boxPlot;
  const annotations = options.plugins?.annotation?.annotations;

  if (!annotations) return;

  const top = Math.max(thirdQuartile, firstQuartile);
  const bottom = Math.min(thirdQuartile, firstQuartile);
  const topWhisk = Math.max(lowerWhisker, upperWhisker);
  const bottomWhisk = Math.min(lowerWhisker, upperWhisker);
  annotations['box'].yMax = top;
  annotations['box'].yMin = bottom;
  annotations['median'].yMax = median;
  annotations['median'].yMin = median;

  annotations['horizontalUpperWhisk'].yMax = topWhisk;
  annotations['horizontalUpperWhisk'].yMin = topWhisk;
  annotations['verticalUpperWhisk'].yMax = topWhisk;
  annotations['verticalUpperWhisk'].yMin = top;
  annotations['verticalLowerWhisk'].yMax = bottom;
  annotations['verticalLowerWhisk'].yMin = bottomWhisk;
  annotations['horizontalLowerWhisk'].yMax = bottomWhisk;
  annotations['horizontalLowerWhisk'].yMin = bottomWhisk;
}

export function setHighlightedData(data: ModelBoxPlotChartType, selectedIdxs: number[], activeId?: number) {
  if (
    !data.datasets[0].pointBorderWidth ||
    !data.datasets[0].pointRadius ||
    !data.datasets[0].pointBorderColor ||
    !data.datasets[0].pointStyle
  )
    return;
  for (let idx = 0; idx < data.datasets[0].data.length; idx++) {
    data.datasets[0].pointStyle[idx] = 'crossRot';
    if (idx === activeId) {
      data.datasets[0].pointBorderWidth[idx] = 4;
      data.datasets[0].pointRadius[idx] = 10;
      data.datasets[0].pointBorderColor[idx] = COLORS.trblue3.hex;
    } else if (selectedIdxs.includes(idx)) {
      data.datasets[0].pointBorderWidth[idx] = 3;
      data.datasets[0].pointRadius[idx] = 9.5;
      data.datasets[0].pointBorderColor[idx] = COLORS.trblue2.hex;
    } else {
      data.datasets[0].pointBorderWidth[idx] = 1.5;
      data.datasets[0].pointRadius[idx] = 6;
      data.datasets[0].pointBorderColor[idx] = COLORS.trgrey3.hex;
    }
  }
}
