// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { Atoms, COLORS, ICONS, Molecules } from '@tls/treact-ui';
import { UseClickEventHandler } from '@tls/treact-ui/lib/components/Meta';
import IconFilterReset from 'components/treactui-template/icons/IconFilterReset';
import useMediaQuery, { DefaultBreakpoints } from 'hooks/useMediaQuery';
import useProjectsMetadata from 'hooks/useProjectsMetadata';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateFilterType } from 'store/reducers/uiSettingsSlice';
import styled from 'styled-components';
import FilterDivider from './FilterDivider';
import GenerateFilterEntries, { FilterEntry, FilterOption } from './GenerateFilterEntry';
import SortBy from './SortBy';
import { Dictionary } from 'model/Dictionary';

export type Props = {
  onClose: UseClickEventHandler<Element>;
};

export default function HeaderFilter({ onClose }: Props) {
  const { t } = useTranslation();
  let resetLabel = t('filter.reset');
  const { width } = useMediaQuery();

  const [filters, setFilters] = useState<FilterEntry[]>([]);

  const filterTypeToDisplay = useAppSelector(s => s.uiSettings.filterTypesToDisplay);
  const { projects, allTags } = useProjectsMetadata();
  const filterType = useAppSelector(s => s.uiSettings.filterType);
  const dispatch = useAppDispatch();

  if (width < DefaultBreakpoints.md) {
    resetLabel = '';
  }

  useEffect(() => {
    const tagFilterOptions: FilterOption[] = [];
    const projectFilter: FilterOption[] = [];

    for (const project of projects) {
      projectFilter.push({
        id: project.projectId ?? '',
        label: project.projectName ?? '',
      });
    }

    for (const tag of allTags) {
      tagFilterOptions.push({ id: tag, label: tag });
    }

    setFilters(GenerateFilterEntries(filterTypeToDisplay, t, tagFilterOptions, projectFilter));
  }, [allTags, filterTypeToDisplay, projects, t]);

  return (
    <GridContainer>
      <GroupAndSortByShell>
        <SortBy />
      </GroupAndSortByShell>
      <FilterDivider />
      <FilterShell>
        {filters.map(f => (
          <Molecules.FilterContent
            key={`Filter-${f.id}`}
            label={f.label}
            keySelector={selectKey}
            labelSelector={selectLabel}
            iconSelector={selectIcon}
            onChange={onFilterTypeChange(dispatch, filterType, f.id)}
            selectedItemsLabelSelector={o => t(selectedItemsLabel(f.options, selectLabel, o), { count: o.length })}
            options={f.options}
            selectedOptions={f.options.filter(o => filterType[f.id.toString()]?.includes(o.id)) ?? []}
            closeLabel={t('close')}
            saveLabel={t('filter.save')}
            noResultText={t('filter.noResult')}
            noBorder
            className='dropdown'
          />
        ))}
      </FilterShell>
      <ResetFilterShell>
        <Atoms.Button
          className='buttonWithoutBorder'
          icon={<IconFilterReset width='1.5rem' key='filter-reset-button' />}
          variant='secondary'
          label={resetLabel}
          onClick={resetFilter(dispatch)}
        />
        <Atoms.Button
          className='buttonWithoutBorder'
          icon={<ICONS.IconClose key='filter-close-button' />}
          variant='secondary'
          onClick={onClose}
        />
      </ResetFilterShell>
    </GridContainer>
  );
}

const GridContainer = styled.div`
  display: grid;
  margin: 0.5rem 0 0 0;
  grid-template-columns: 1fr 2rem 3fr auto;
  grid-template-areas: 'group-and-sort-by divider filter reset-filter';
  column-gap: 0.75rem;
  background-color: ${COLORS.trwhite.hex};

  > .pushDown {
    margin-top: auto;
    margin-bottom: auto;
  }
`;

const GroupAndSortByShell = styled.div`
  grid-area: group-and-sort-by;
  display: grid;
  grid-auto-flow: column;
  column-gap: 1rem;
`;

const FilterShell = styled.div`
  grid-area: filter;
  display: grid;
  grid-auto-flow: column;
  column-gap: 1rem;
  grid-row-gap: 1rem;
`;

const ResetFilterShell = styled.div`
  grid-area: reset-filter;
  margin: auto 0 1.5rem 0;
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 0.5rem;
`;

const emptyIcon = (key: string) => <React.Fragment key={key} />;
const selectIcon = (option: FilterOption) => option.icon ?? emptyIcon(option.id + option.label);

const selectLabel = (option: FilterOption) => option.label;

const selectKey = (option: FilterOption) => option.id;

function resetFilter(dispatch): UseClickEventHandler<Element> | undefined {
  return function () {
    dispatch(updateFilterType({}));
  };
}

function onFilterTypeChange(dispatch, filterType: Dictionary<string[]>, filterId: string) {
  return function (options: FilterOption[]) {
    const nextOptions = { ...filterType };
    if (options.length > 0) {
      nextOptions[filterId] = options.map(o => o.id);
    } else if (nextOptions[filterId]) {
      delete nextOptions[filterId];
    }
    dispatch(updateFilterType(nextOptions));
  };
}

function selectedItemsLabel<T>(allItems: T[], getLabel: (item: T) => string, filteredItems: T[]) {
  if ((filteredItems?.length ?? 0) < 1) return 'filter.none';
  if (filteredItems.length === allItems.length) return 'filter.everythingLabel';
  if (filteredItems.length === 1) return getLabel(filteredItems[0]);
  return 'filter.multiple';
}
