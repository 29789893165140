// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { createContext } from 'react';

export interface ImageListOrderContextType {
  currentOrder?: React.MutableRefObject<string[]>;
}

export const ImageListOrderContext = createContext<ImageListOrderContextType>({});
