// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { Atoms, COLORS, ICONS } from '@tls/treact-ui';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ImageTable from './images/ImageTable';
import useFileUpload from './upload/UploadFile';
import Backdrop from 'components/treactui-template/organisms/backdrop/Backdrop';
import { ToastByStatus } from 'components/treactui-template/organisms/toast/ToastMessage';
import { useParams } from 'react-router';
import { useAppDispatch } from 'store/hooks';
import { changeSelectedImage } from 'store/reducers/projectImageSlice';
import UseDeleteImage from './images/UseDeleteImage';
import useProjectLabels from 'hooks/useProjectLabels';
import IconTouchLoading from 'components/treactui-template/icons/IconTouchLoading';
import useProjectsMetadata from 'hooks/useProjectsMetadata';
import IconListSettings from 'components/treactui-template/icons/IconListSettings';
import LabelOrderSettingsDialog from './images/LabelOrderSettingsDialog';
import Badge from 'components/treactui-template/atoms/Badge';
import { SSimContext } from 'components/project/label/similarity/SSimContext';
import { ImageListOrderContext } from './images/ImageListOrderContext';
import ParamsWithId from 'model/ParamsWithId';

const { IconPlus, IconReload, IconMinus, IconLoading } = ICONS;
export default function ImagesList() {
  const { t } = useTranslation();

  const { id } = useParams<ParamsWithId>();
  const { selectedProject } = useProjectsMetadata(id);

  const { loading: loadingImages, labels: labelPackages = [], selectedMd5 } = useProjectLabels();
  const { isDragActive, isDragReject, getRootProps, open, loading, fileRejections, percentage } = useFileUpload(
    true,
    (selectedProject?.previewImage?.previewImageData?.length ?? 0) < 1
  );

  const { isCalculating, updateAvailable, refreshResult } = useContext(SSimContext);
  //Display toast message if file upload was rejected
  useEffect(() => {
    if ((fileRejections?.length ?? 0) < 1) return;
    ToastByStatus(
      503,
      t('project.label.images.upload.title'),
      t(`project.label.images.upload.rejectedFile${fileRejections.length > 1 ? 's' : ''}`, {
        number: fileRejections.length,
      }),
      ''
    );
  }, [fileRejections, t]);

  const dispatch = useAppDispatch();

  //Select the first image if the selected image is not in the list
  useEffect(() => {
    if (labelPackages.length < 1) return;

    if (labelPackages?.findIndex(l => l?.imageMd5 === selectedMd5) > -1) return;

    const firstImage = labelPackages[0];
    dispatch(changeSelectedImage(firstImage?.imageMd5));
  }, [selectedMd5, labelPackages, dispatch]);

  const { queueDelete } = UseDeleteImage();

  const { currentOrder } = useContext(ImageListOrderContext);

  const handleButtonPress = useCallback(
    (event: KeyboardEvent) => {
      const { key } = event;

      let idx = currentOrder?.current.findIndex(l => l === selectedMd5) ?? 0;
      if (key === 'ArrowUp') {
        idx = Math.max(idx - 1, 0);
      } else if (key === 'ArrowDown') {
        idx = Math.min(idx + 1, (currentOrder?.current?.length ?? 0) - 1);
      } else if (key === 'Delete') {
        queueDelete();
        return;
      }
      const newMd5 = currentOrder?.current[idx];
      if (newMd5 === selectedMd5) return;
      dispatch(changeSelectedImage(newMd5));
    },
    [currentOrder, selectedMd5, dispatch, queueDelete]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleButtonPress);

    return () => {
      window.removeEventListener('keydown', handleButtonPress);
    };
  }, [handleButtonPress]);

  const orderLabel = () => {
    refreshResult();
  };

  const content =
    !loading && labelPackages.length < 1 ? (
      <>
        {loadingImages && <Atoms.Loader label='' />}
        <NoDataContainer>
          <IconTouchLoading onClick={open} />
          <HeaderTitle>{t('project.label.images.noData.title')}</HeaderTitle>
          <span className='font-size_s'>{t('project.label.images.drop')}</span>
          <Atoms.Button label={t('project.label.images.import')} icon={<IconPlus />} onClick={open} />
        </NoDataContainer>
      </>
    ) : (
      <Content progress={`${loading}`}>
        {loading && <StyledProgress percentage={percentage} width={100} />}
        <ImageTable data={labelPackages} loading={loadingImages} />
      </Content>
    );

  const [openLabeOrderSettings, setOpenLabeOrderSettings] = useState(false);
  const toggleOpenSettings = useCallback(() => {
    setOpenLabeOrderSettings(!openLabeOrderSettings);
  }, [openLabeOrderSettings]);

  return (
    <Container {...getRootProps()}>
      <Header>
        <span className='font-size_m font-weight_bold'>{t('project.label.images.title')}</span>
        <Badge display={updateAvailable} value={'1'}>
          <Atoms.Button
            icon={isCalculating ? <IconLoading /> : <IconReload />}
            label={t('project.label.images.order.title')}
            variant='secondary'
            onClick={orderLabel}
            disabled={isCalculating}
          />
        </Badge>
        <Atoms.Button icon={<IconListSettings width='1.5rem' />} variant='secondary' onClick={toggleOpenSettings} />
        <VerticalSeparator />
        <Atoms.Button icon={<IconPlus />} variant='primary' onClick={open} />
        <VerticalSeparator />
        <Atoms.Button icon={<IconMinus />} variant='secondary' onClick={queueDelete} disabled={!selectedMd5} />
      </Header>
      {content}
      <Backdrop showBackdrop={isDragActive}>
        <Centered>{t(isDragReject ? 'project.label.images.dropRejection' : 'project.label.images.drop')}</Centered>
      </Backdrop>
      <LabelOrderSettingsDialog open={openLabeOrderSettings} onClose={toggleOpenSettings} />
    </Container>
  );
}

const StyledProgress = styled(Atoms.ProgressBar)`
  margin-top: 0.5rem;
`;

const VerticalSeparator = styled.div`
  border-left: 1px solid ${COLORS.trgrey2.hex};
  height: 100%;
`;

const Container = styled.div`
  padding: 0.5rem 0 0 0.5rem;
  position: relative;
  height: calc(100% - 0.5rem);
`;

const Header = styled.div`
  display: grid;
  padding-right: 0.5rem;
  grid-template-columns: 1fr auto auto auto auto auto auto;
  align-items: center;
  column-gap: 0.5rem;
`;

const Content = styled.div<{ progress: string }>`
  height: calc(100% - 2.5rem ${({ progress }) => progress === 'true' && ' - 1.5rem'});
`;

const Centered = styled.div`
  text-align: center;
`;

const NoDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  width: 100%;
`;

const HeaderTitle = styled.span`
  font-size: 1.2rem;
  font-weight: 700;
  color: ${COLORS.trblue35.hex};
`;
