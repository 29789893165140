// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { IconProps } from '@tls/treact-ui/lib/components/Icons/types';
import React from 'react';

const IconShare = ({ id, width, height, viewBox, fill, stroke, ...other }: IconProps) => {
  return (
    <svg
      id={id ?? 'svg-share'}
      width={width ?? '36'}
      height={height ?? '36'}
      viewBox={viewBox ?? '0 0 36 36'}
      stroke={stroke ?? 'currentColor'}
      fill={fill ?? 'currentColor'}
      xmlns='http://www.w3.org/2000/svg'
      {...other}
    >
      <path
        d='M29 1C26.2386 1 24 3.23858 24 6C24 6.14783 24.0064 6.29417 24.019 6.43874L11.0085 13.5354C9.94595 12.5808 8.5408 12 7 12C3.68629 12 1 14.6863 1 18C1 21.3137 3.68629 24 7 24C8.5408 24 9.94595 23.4192 11.0085 22.4646L24.019 29.5613C24.0064 29.7058 24 29.8522 24 30C24 32.7614 26.2386 35 29 35C31.7614 35 34 32.7614 34 30C34 27.2386 31.7614 25 29 25C27.8447 25 26.7809 25.3918 25.9343 26.0498L12.9245 18.9542C12.9742 18.6435 13 18.3247 13 18C13 17.6753 12.9742 17.3565 12.9245 17.0458L25.9343 9.95018C26.7809 10.6082 27.8447 11 29 11C31.7614 11 34 8.76142 34 6C34 3.23858 31.7614 1 29 1Z'
        fill={fill}
      />
    </svg>
  );
};

export default IconShare;
