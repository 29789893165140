// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { ModelClassMessage } from '@tls/sw91-communication/types/com.base';

export function AssignLabelColor(image: ImageData, colors: ModelClassMessage[]) {
  for (let i = 0; i < image.data.length; i += 4) {
    image.data[i + 3] = 0;
    const index = image.data[i];
    if (index === 0) continue;

    const color = colors.length >= index ? colors[index - 1] : undefined;
    image.data[i] = color?.labelColor?.red ?? 81;
    image.data[i + 1] = color?.labelColor?.green ?? 167;
    image.data[i + 2] = color?.labelColor?.blue ?? 235;
    image.data[i + 3] = 190;
  }
}
