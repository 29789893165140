// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { COLORS } from '@tls/treact-ui';
import styled from 'styled-components';

const Background = styled.div<{ appBaseUrl: string }>`
  background-image: url(${({ appBaseUrl }) => `${appBaseUrl}/images/NotFoundBackground.png`});
  background-size: cover;
  height: 100vh;
  display: grid;

  @media screen and (min-width: 870px) {
    grid-template-columns: 50% 50%;
    .icon {
      padding-top: 2rem;
      margin-left: auto;
    }
    .text {
      padding-top: 1rem;
    }
  }

  @media screen and (max-width: 869px) {
    grid-template-columns: 100%;
    grid-template-rows: auto 1fr;
    .icon {
      margin: 2rem auto 0;
    }
    .text {
      margin: 0 auto;
    }
  }
`;

const Title = styled.p`
  font-size: 3rem;
  font-weight: 700;
  line-height: 3rem;
  color: ${COLORS.trblue35.hex};
`;

const Details = styled.div`
  margin-top: 4rem;

  .details {
    max-width: 25rem;
  }

  .margin-top {
    margin-top: 0.625rem;
  }
`;

export { Background, Title, Details };
