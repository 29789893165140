// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { COLORS } from '@tls/treact-ui';
import { css } from 'styled-components';

export const AnimationCss = css`
  animation-name: color;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
  animation-timing-function: ease;

  @keyframes color {
    from {
    }
    to {
      background-color: rgba(${COLORS.trgrey2.rgb}, 0.2);
    }
  }
`;
