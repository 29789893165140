// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { Atoms, Organisms } from '@tls/treact-ui';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setLabelOrderUpdateInterval, toggleAutomaticLabelOrderUpdate } from 'store/reducers/projectLabelSlice';

export type Props = {
  open: boolean;
  onClose: () => void;
};

export default function LabelOrderSettingsDialog({ open, onClose }: Readonly<Props>) {
  const { t } = useTranslation();

  const automate = useAppSelector(s => s.label.automaticLabelOrderUpdate);
  const interval = useAppSelector(s => s.label.labelOrderUpdateInterval);

  const dispatch = useAppDispatch();
  const toggleAutomate = useCallback(() => dispatch(toggleAutomaticLabelOrderUpdate()), [dispatch]);
  const setInterval = useCallback((e: number | null) => dispatch(setLabelOrderUpdateInterval(e)), [dispatch]);
  return (
    <Organisms.Dialog open={open} enableClosing onClose={onClose}>
      <Organisms.Dialog.DialogHeader label={t('project.label.images.order.settings')} />
      <Organisms.Dialog.DialogContent>
        <Atoms.Switch value={automate} label={t('project.label.images.order.automate')} onChange={toggleAutomate} />
        <Atoms.InputFieldValue
          label={t('project.label.images.order.refresh')}
          suffix={t('project.label.images.order.seconds')}
          onValueChange={setInterval}
          value={interval}
          disabled={!automate}
          step={1}
          min={5}
          max={90}
        />
      </Organisms.Dialog.DialogContent>
      <Organisms.Dialog.Footer
        right={[
          <Atoms.FooterButton key='label-settings-cancel' label={t('close')} variant='secondary' onClick={onClose} />,
        ]}
      />
    </Organisms.Dialog>
  );
}
