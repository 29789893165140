// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { IconProps } from '@tls/treact-ui/lib/components/Icons/types';
import React from 'react';

const IconVersion = ({ id, width, height, viewBox, strokeWidth, stroke, fill, ...other }: IconProps) => {
  return (
    <svg
      id={id || 'svg-icon-filter'}
      data-name='svg-icon-filter'
      data-testid='svg-icon-filter'
      xmlns='http://www.w3.org/2000/svg'
      width={width || '2.5rem'}
      height={height || '2.55rem'}
      viewBox={viewBox || '0 0 36 36'}
      strokeWidth={strokeWidth || '0'}
      stroke={stroke || 'currentColor'}
      fill={fill || 'currentColor'}
      {...other}
    >
      <path d='M15 1H33V35H15V1Z' fill={fill} />
      <path d='M9 4.1875H12V31.8125H9V4.1875Z' fill={fill} />
      <path d='M3 7.375H6V28.625H3V7.375Z' fill={fill} />
    </svg>
  );
};

export default IconVersion;
