// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { ProjectMetaMessage } from '@tls/sw91-communication/types/com.base';

export enum ProjectStatus {
  TRAINING_NOT_STARTED = 0,
  TRAINING_ACTIVE = 1,
  TRAINING_STOPPED = 2,
}

export type ProjectMetadata = {
  projectStatus: ProjectStatus;
  lockedBy: string;
} & ProjectMetaMessage;
