// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { Atoms, COLORS, ICONS } from '@tls/treact-ui';
import IconExport from 'components/treactui-template/icons/IconExport';
import { GetModelTitleTranslationKey, ProjectVersion } from 'model/ProjectMetaMessageExtensions';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { EvaluationContext, EvaluationContextType } from './EvaluationContext';
import ModeLBoxPlot from './ModelBoxPlot';
import ImagePreviewContainer from './ImagePreviewContainer';

export type Props = {
  version: ProjectVersion;
  onExport: () => void;
  loading: boolean;
  disabled?: boolean;
  evaluationId?: string;
};

export default function ModelResult({ version, onExport, loading, disabled, evaluationId }: Props) {
  const { t } = useTranslation();
  const { id } = useParams();
  const [index, setIndex] = useState<number>(-1);
  const [length, setLength] = useState<number>(0);

  const value: EvaluationContextType = useMemo(
    () => ({
      index,
      updateIndex: setIndex,
      length,
      updateLength: setLength,
    }),
    [index, setIndex, length, setLength]
  );

  const versionString = t(version ?? '');

  return (
    <Container>
      <EvaluationContext.Provider value={value}>
        <ChartContainer>
          <span className='font-size_l'>{t(GetModelTitleTranslationKey(version), { version: versionString })}</span>
          <ModeLBoxPlot projectId={id ?? ''} modelId={version} evaluationId={evaluationId} />
        </ChartContainer>
        <ImageContainer>
          <ImagePreviewContainer version={version} />
        </ImageContainer>
        <Atoms.Button
          icon={loading ? <ICONS.IconLoading /> : <IconExport />}
          label={t('project.evaluation.export.title', { version: '' })}
          onClick={onExport}
          disabled={loading || disabled}
        />
      </EvaluationContext.Provider>
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: calc(50% - 2.75rem) calc(50% - 2.75rem) 2.5rem;
  row-gap: 1rem;
  position: relative;
`;

const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`;

const ImageContainer = styled.div`
  border: 1px solid ${COLORS.trgrey5.hex};
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`;
