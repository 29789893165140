// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { configureStore } from '@reduxjs/toolkit';
import deleteProjectSlice from './reducers/projectDeleteSlice';
import projectSettingsSlice from './reducers/projectSettingsSlice';
import projectsSlice from './reducers/projectsSlice';
import projectToolSlice from './reducers/projectToolSlice';
import uiSettingsSlice from './reducers/uiSettingsSlice';
import { saveStoreToLocalStorage } from './rehydrate';
import projectImageSlice from './reducers/projectImageSlice';
import projectEvaluationSlice from './reducers/projectEvaluationSlice';
import projectLabelSlice from './reducers/projectLabelSlice';
import projectLockedSlice from './reducers/projectLockedSlice';
import shareProjectSlice from './reducers/projectShareSlice';
import licenseSlice from './reducers/licenseSlice';

//IMPORTANT: Add type to storeTypes interface in ./StoreTypes.ts
export const store = configureStore({
  reducer: {
    uiSettings: uiSettingsSlice,
    projects: projectsSlice,
    projectSettings: projectSettingsSlice,
    deleteProject: deleteProjectSlice,
    tools: projectToolSlice,
    image: projectImageSlice,
    evaluation: projectEvaluationSlice,
    label: projectLabelSlice,
    projectLocked: projectLockedSlice,
    shareProject: shareProjectSlice,
    license: licenseSlice,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

store.subscribe(() => saveStoreToLocalStorage(store.getState()));

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
