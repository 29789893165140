// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { del } from '@tls/react-saf-ui';
import { LabelPackageInfoMessage } from '@tls/sw91-communication/types/com.api_db_access';
import { ImagesContext } from 'components/project/images/ImagesContext';
import { queueToast, ToastByStatus, ToastMessageType } from 'components/treactui-template/organisms/toast/ToastMessage';
import useProjectLabels from 'hooks/useProjectLabels';
import ParamsWithId from 'model/ParamsWithId';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function UseDeleteImage() {
  const { selectedMd5, name } = useProjectLabels();
  const { id } = useParams<ParamsWithId>();

  const { removeImage, addImage, images } = useContext(ImagesContext);

  const { t } = useTranslation();
  const deleteImage = useCallback(
    async (imageMd5: string, image?: LabelPackageInfoMessage) => {
      const result = await del(`projects/${id}/labels/${imageMd5}`);
      ToastByStatus(
        result.status,
        t('project.label.images.delete.title'),
        t('project.label.images.delete.error', { name })
      );

      if ((result.status < 200 || result.status > 299) && image) {
        addImage(image);
      }
    },
    [id, t, name, addImage]
  );

  const queueDelete = useCallback(() => {
    if (!selectedMd5 || !id) return;
    let cancel = false;

    const image = images?.find(i => i?.imageMd5 === selectedMd5);
    const unsubscribe = toast.onChange(async item => {
      if (item.status !== 'removed' || item.id !== toastId) return;
      unsubscribe();

      if (!cancel) await deleteImage(selectedMd5, image);
      else if (image) addImage(image);
    });

    removeImage(selectedMd5);
    const toastId = queueToast({
      headline: t('project.label.images.delete.title'),
      message: t('project.label.images.delete.singleDetails', { name }),
      flag: ToastMessageType.Information,
      link: {
        label: t('cancel'),
        onClick: () => {
          cancel = true;
        },
      },
      closeOnLinkClick: true,
      options: {
        autoClose: 4000,
      },
    });
  }, [deleteImage, id, name, selectedMd5, t, removeImage, images, addImage]);

  return { queueDelete };
}
