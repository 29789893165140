// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF

import React, { useEffect, useState } from 'react';
import { i18nextInit } from './i18nextInit';
import { Loading } from '@tls/react-saf-ui';
import { logo, SplashLoading } from '../logo';
import { i18nProvider } from '@trumpf/ux.i18n';

/* We need to get language from the user in the future
const getBrowserLanguage = () =>
  ((navigator.languages && navigator.languages[0]) ?? navigator.language).substring(0, 2);
*/
type Props = {
  appBaseUrl?: string;
};
export default function I18nextProvider({ appBaseUrl, children }: React.PropsWithChildren<Props>): JSX.Element {
  const [i18nextInitialized, setI18nextInitialized] = useState(false);

  useEffect(() => {
    if (i18nextInitialized) return undefined;
    async function init() {
      await i18nextInit('en', appBaseUrl ?? '');
      setI18nextInitialized(true);
    }
    init();
    i18nProvider.changeLanguage('en');
  }, [i18nextInitialized, appBaseUrl]);

  if (!i18nextInitialized)
    return (
      <Loading basePath={appBaseUrl} logo={logo}>
        <SplashLoading />
      </Loading>
    );

  return <>{children}</>;
}
