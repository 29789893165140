// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ShareProjectState {
  id?: string;
  show: boolean;
}

const initialState: ShareProjectState = {
  id: undefined,
  show: false,
};

export const shareProjectSlice = createSlice({
  name: 'share-project',
  initialState,
  reducers: {
    toggleShareDialog: state => {
      state.show = !state.show;
      if (!state.show) {
        state.id = undefined;
      }
    },
    setShareId: (state, action: PayloadAction<string | undefined>) => {
      state.id = action.payload;
      state.show = true;
    },
  },
});

export const { toggleShareDialog, setShareId } = shareProjectSlice.actions;
export default shareProjectSlice.reducer;
