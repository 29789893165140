// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { ProjectMetaMessage } from '@tls/sw91-communication/types/com.base';
import { ProjectMetadata, ProjectStatus } from 'model/ProjectMetadata';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';

export default function useProjectsMetadata(projectId = '') {
  const { loading = true, lastStatus, projectsMetadata, status, lockedBy } = useAppSelector(s => s.projects);
  const [allTags, setAllTags] = useState<string[]>([]);
  const [selectedProject, setSelectedProject] = useState<ProjectMetadata>();
  const [initialized, setInitialized] = useState(false);
  const [projects, setProjects] = useState<ProjectMetadata[]>([]);

  useEffect(() => {
    const tags = new Set<string>();
    const newProjects = projectsMetadata.map(s => {
      const metadata = ProjectMetaMessage.fromJSON(s);

      for (const tag of metadata.tags ?? []) {
        tags.add(tag);
      }

      try {
        return {
          ...metadata,
          projectStatus: status[metadata?.projectId ?? ''] ?? ProjectStatus.TRAINING_NOT_STARTED,
          lockedBy: lockedBy[metadata?.projectId ?? ''] ?? '',
        };
      } catch (e) {
        console.error(e);
      }
      return { ...metadata, projectStatus: ProjectStatus.TRAINING_NOT_STARTED, lockedBy: '' };
    });

    setProjects(newProjects);

    setAllTags([...tags]);
    setInitialized(true);
  }, [projectsMetadata, projectId, status, lockedBy]);

  useEffect(() => {
    setSelectedProject(projects.find(p => p.projectId === projectId));
  }, [projectId, projects]);

  return {
    loading: loading || !initialized,
    lastStatus,
    projects,
    selectedProject,
    allTags,
  };
}
