// SPDX-FileCopyrightText: 2024 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import useRolesApi from 'hooks/useRolesApi';
import { Role } from 'model/Role';
import React, { useCallback } from 'react';
import RoleDetails from './roles/RoleDetails';
import PageContent from 'components/treactui-template/organisms/page/PageContent';
import { useNavigate } from 'react-router';
import { AppRoutes } from 'utilities/router/AppRoutes';

export default function AddRole() {
  const { roles, loadingRoles, addRole, permissions } = useRolesApi();
  const navigate = useNavigate();

  const createRole = useCallback(
    async (role: Role) => {
      const result = await addRole(role);
      if (result.status === 200) navigate(AppRoutes.SettingsRole);
    },
    [addRole, navigate]
  );

  return (
    <PageContent displayAsPaper disableScroll>
      <RoleDetails
        role={{} as Role}
        roles={roles}
        isLoading={loadingRoles}
        addOrEditRole={createRole}
        isBuiltInRole={false}
        onClose={() => navigate(AppRoutes.SettingsRole)}
        permissions={permissions}
      />
    </PageContent>
  );
}
