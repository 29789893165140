// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { Atoms, Organisms } from '@tls/treact-ui';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { toggleThirdPartyLicenses } from 'store/reducers/uiSettingsSlice';
import ThirdPartyLicenses from './ThirdPartyLicenses';
import { LicenseProvider, useBackBehavior } from '@tls/treact-ui-templates';
import styled from 'styled-components';

export function ThirdPartyLicensesDialog() {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const onClose = useCallback(() => {
    dispatch(toggleThirdPartyLicenses());
  }, [dispatch]);
  const open = useAppSelector(s => s.uiSettings.showThirdPartyLicenses);

  return (
    <Organisms.Dialog open={open} onClose={onClose} size={'huge'} enableClosing>
      <LicenseProvider>
        <Organisms.Dialog.DialogHeader label={t('thirdPartyLicenses.title')} />
        <Organisms.Dialog.DialogContent>
          <ContentWrapper>
            <ThirdPartyLicenses />
          </ContentWrapper>
        </Organisms.Dialog.DialogContent>
        <Footer onClose={onClose} />
      </LicenseProvider>
    </Organisms.Dialog>
  );
}

const ContentWrapper = styled.div`
  height: calc(100% + 2rem);
  margin: -1rem;
`;

const Footer = ({ onClose }) => {
  const { t } = useTranslation();

  const { showBackButton, onBack } = useBackBehavior();

  const rightButtons = useMemo(() => {
    const buttons = [<Atoms.FooterButton variant='secondary' key='cancel' label={t('close')} onClick={onClose} />];
    if (showBackButton) {
      buttons.unshift(<Atoms.FooterButton variant='primary' key='back' label={t('back')} onClick={onBack} />);
    }
    return buttons;
  }, [onBack, onClose, showBackButton, t]);

  return <Organisms.Dialog.Footer right={rightButtons} />;
};
