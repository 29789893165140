// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import React, { PropsWithChildren, useMemo, useRef } from 'react';
import { ImageListOrderContext } from './ImageListOrderContext';

export default function ImageListOrderProvider({ children }: Readonly<PropsWithChildren>) {
  const currentOrder = useRef<string[]>([]);

  const value = useMemo(() => ({ currentOrder }), []);
  return <ImageListOrderContext.Provider value={value}>{children}</ImageListOrderContext.Provider>;
}
