// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import React from 'react';
import Backdrop from './Backdrop';
import styled from 'styled-components';
import { COLORS } from '@tls/treact-ui';

type Props = {
  title?: string;
  details?: string;
  show: boolean;
};

export default function NoContentBackdrop({ title, details: subtitle, show }: Props) {
  return (
    <Backdrop showBackdrop={show}>
      <HeaderTitle>{title}</HeaderTitle>
      <span className='font-size_s'>{subtitle}</span>
    </Backdrop>
  );
}

const HeaderTitle = styled.span`
  font-size: 1.2rem;
  font-weight: 700;
  color: ${COLORS.trblue35.hex};
`;
