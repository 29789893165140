// SPDX-FileCopyrightText: 2024 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { useQuery } from '@tanstack/react-query';
import { del, get, post, put } from '@tls/react-saf-ui';
import { User } from 'model/User';
import { GenericResponse } from 'model/reponse/GenericResponse';
import { useCallback } from 'react';

export default function useUsersApi() {
  const {
    data: users,
    isLoading: loadingUsers,
    refetch: getUsers,
  } = useQuery<GenericResponse<User[]>>({
    queryKey: ['users'],
    queryFn: async () => await get('users', false, true),
  });

  const editUser = useCallback(
    async (user: User) => {
      if (!user.id) return;
      const result = await put(`users/${user.id}`, user);
      getUsers();
      return result;
    },
    [getUsers]
  );

  const addUser = useCallback(
    async (user: User) => {
      const result = await post('users', user);
      getUsers();
      return result;
    },
    [getUsers]
  );
  const deleteUser = useCallback(
    async (user: User) => {
      if (!user.id) return;
      const result = await del(`users/${user.id}`);
      getUsers();
      return result;
    },
    [getUsers]
  );

  return { users: users?.data, loadingUsers, getUsers, editUser, addUser, deleteUser };
}
