// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { useState, useEffect } from 'react';

export default function useMediaQuery(props?: Props) {
  const { breakpoints } = props ?? {};
  const currentBreakpoints = breakpoints ?? DefaultBreakpoints;
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const detectSize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', detectSize);

    return () => {
      window.removeEventListener('resize', detectSize);
    };
  }, []);

  return {
    width,
    height,
    isExtraSmall: width <= currentBreakpoints.xs,
    isSmall: width > currentBreakpoints.xs && width <= currentBreakpoints.sm,
    isMedium: width > currentBreakpoints.sm && width <= currentBreakpoints.md,
    isLarge: width > currentBreakpoints.md && width <= currentBreakpoints.lg,
    isExtraLarge: width > currentBreakpoints.lg && width <= currentBreakpoints.xlg,
    isHuge: width > currentBreakpoints.xlg,
  };
}

export type Props = {
  breakpoints?: BreakpointsType;
};

export const DefaultBreakpoints: BreakpointsType = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1200,
  xlg: 1536,
};

export type BreakpointsType = {
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xlg: number;
};

export const PixelBreakpoints = {
  xs: `${DefaultBreakpoints.xs}px`,
  preSm: `${DefaultBreakpoints.sm - 1}px`,
  sm: `${DefaultBreakpoints.sm}px`,
  preMd: `${DefaultBreakpoints.md - 1}px`,
  md: `${DefaultBreakpoints.md}px`,
  preLg: `${DefaultBreakpoints.lg - 1}px`,
  lg: `${DefaultBreakpoints.lg}px`,
  preXlg: `${DefaultBreakpoints.xlg - 1}px`,
  xlg: `${DefaultBreakpoints.xlg}px`,
};
