// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import React, { useCallback, useEffect, useRef } from 'react';
import { COLORS, ICONS, Organisms } from '@tls/treact-ui';
import { ImageType } from 'model/Image';
import Ellipsis from 'components/treactui-template/atoms/Ellipsis';
import { useAppDispatch } from 'store/hooks';
import { changeSelectedImage } from 'store/reducers/projectImageSlice';
import IconLabel from 'components/treactui-template/icons/IconLabel';
import { AnimationCss } from 'components/treactui-template/atoms/animation/AnimatePulse';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { loadArrayIntoImageElement } from 'components/treactui-template/meta/loadImageIntoElement';
import { useSafContext } from '@tls/react-saf-ui';

const Table = Organisms.Table;
export type Props = {
  entry: ImageType;
  index: number;
  selected?: boolean;
};

export default function ImageTableEntry({ entry, index, selected }: Props) {
  const dispatch = useAppDispatch();
  const className: string = selected ? 'selected' : '';
  const { id } = useParams();

  const onClick = useCallback(() => dispatch(changeSelectedImage(entry.imageMd5)), [dispatch, entry.imageMd5]);

  const imageElement = useRef<HTMLImageElement>(null);
  useEffect(() => {
    if (!id || !entry.previewData) return;

    loadArrayIntoImageElement(entry.previewData, 'image/png', imageElement);
  }, [id, entry.imageMd5, entry.previewData]);

  const { appBaseUrl } = useSafContext();

  return (
    <Table.Row key={`${entry.imageName}:${entry.imageMd5}`} index={index} className={className} onClick={onClick}>
      <Table.Cell width={'15%'} noIndent>
        <Image src={`${appBaseUrl}/images/image-placeholder.png`} ref={imageElement} />
      </Table.Cell>
      <Table.Cell width={'10%'} alignment='CENTER' noIndent>
        {entry.hasMask ? <IconLabel width='1rem' /> : <ICONS.IconCircle width='1rem' />}
      </Table.Cell>
      <Table.Cell width={'75%'} noIndent>
        <Ellipsis>{entry.imageName}</Ellipsis>
      </Table.Cell>
      <Table.Cell width={'15%'} noIndent>
        {entry.labelOrder ?? '-'}
      </Table.Cell>
    </Table.Row>
  );
}

const Image = styled.img`
  width: 2.5rem;
  margin: 0.25rem;
  background-color: rgba(${COLORS.trgrey2.rgb}, 0.4);
  ${AnimationCss}
`;
