// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { Atoms, ICONS } from '@tls/treact-ui';
import Centered from 'layout/Centered';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  label?: string;
  spinner?: boolean;
};

const { IconLoading } = ICONS;

export default function CenteredLoader({ label, spinner }: Readonly<Props>) {
  const { t } = useTranslation();
  return <Centered width={70}>{spinner ? <IconLoading /> : <Atoms.Loader label={label ?? t('loading')} />}</Centered>;
}
