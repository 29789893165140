// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF

export const trLightOrange = {
  hex: '#f27b26',
  rgb: '242,123,38',
} as const;
export const trLightBrown = {
  hex: '#a87542',
  rgb: '168,117,66',
} as const;
export const trLime = {
  hex: '#bbd03a',
  rgb: '187,208,58',
} as const;
export const trLightPurple = {
  hex: '#7b45e6',
  rgb: '123,69,230',
} as const;
export const trLightViolet = {
  hex: '#b146ea',
  rgb: '177,70,234',
} as const;
export const trLightPink = {
  hex: '#eb467a',
  rgb: '235,70,122',
} as const;
export const trDarkOrange = {
  hex: '#9e4b10',
  rgb: '158,75,16',
} as const;
export const trDarkBrown = {
  hex: '#684a2c',
  rgb: '104,74,44',
} as const;
export const trDarkLime = {
  hex: '#4f5718',
  rgb: '79,87,24',
} as const;
export const trDarkGreen = {
  hex: '#20541e',
  rgb: '32,84,30',
} as const;
export const trBlue4 = {
  hex: '#285172',
  rgb: '40,81,114)',
} as const;
export const trDarkPurple = {
  hex: '#6e2c91',
  rgb: '110,44,145',
} as const;
export const trDarkViolet = {
  hex: '#472885',
  rgb: '71,40,133',
} as const;
export const trDarkPink = {
  hex: '#73223c',
  rgb: '115,34,60',
} as const;
