// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
export enum ToolType {
  Pencil = 'Pencil',
  IntelligentScissor = 'IntelligentScissor',
  Polygon = 'Polygon',
}

export enum PencilType {
  Circle = 'Circle',
  Square = 'Square',
}

export enum ToolMode {
  Draw = 'Draw',
  Erase = 'Erase',
}
