// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import styled, { css } from 'styled-components';

export interface FilterGridContainerProp {
  pxBreakpoint: number;
  showView: boolean;
  showActionButton: boolean;
  showFilter: boolean;
}

const getViewArea = (include: boolean) => (include ? ' view' : '');
const getFilterArea = (include: boolean) => (include ? ' filter' : '');
const getActionButtonArea = (include: boolean) => (include ? ' action-button' : '');
const getFilterOptionsArea = (include: boolean) => (include ? ' filter-options' : '');
const getSecondRowArea = (include: boolean) => (include ? ' second-row' : '');
const getTemplateColumn = (include: boolean) => (include ? 'auto' : '');

const FilterGridContainer = styled.div<FilterGridContainerProp>`
  ${({ pxBreakpoint, showView: sv, showActionButton: ab, showFilter: sf }) => css`
    @media screen and (min-width: ${pxBreakpoint}px) {
      grid-template-areas: 'page-title search${getFilterArea(sf)}${getViewArea(sv)}${getActionButtonArea(ab)}';
      grid-template-columns: 1fr auto ${getTemplateColumn(sf)} ${getTemplateColumn(sv)} ${getTemplateColumn(ab)};

      grid-template-areas:
        'page-title search${getFilterArea(sf)}${getViewArea(sv)}${getActionButtonArea(ab)}'
        'filter-options filter-options${getFilterOptionsArea(
          sf
        )}${getFilterOptionsArea(sv)}${getFilterOptionsArea(ab)}';

      column-gap: 1rem;
    }

    @media screen and (max-width: ${pxBreakpoint - 1}px) {
      grid-template-areas:
        'page-title${getViewArea(sv)}${getActionButtonArea(ab)}'
        'second-row${getSecondRowArea(sv)} ${getSecondRowArea(ab)}';
      grid-template-columns: 1fr ${getTemplateColumn(sv)} ${getTemplateColumn(ab)};

      grid-template-areas:
        'page-title${getViewArea(sv)}${getActionButtonArea(ab)}'
        'second-row${getSecondRowArea(sv)}${getSecondRowArea(ab)}'
        'filter-options${getFilterOptionsArea(sv)}${getFilterOptionsArea(ab)}';
      row-gap: 0.5rem;
    }
  `}
  display: grid;

  margin: 0.5rem 1rem;

  > div {
    margin-top: auto;
    margin-bottom: auto;
  }

  > .pushRight {
    margin-left: 1rem;
  }
`;

export default FilterGridContainer;

export const PageTitleShell = styled.div`
  grid-area: page-title;
`;

export const SearchShell = styled.div`
  grid-area: search;
`;

export const FilterShell = styled.div`
  grid-area: filter;
`;

type SecondRowProps = {
  showFilter?: boolean;
};

export const SecondRowShell = styled.div<SecondRowProps>`
  grid-area: second-row;
  display: grid;
  ${({ showFilter }) => css`
    grid-template-columns: 1fr ${showFilter && 'auto'};
    column-gap: 1rem;
    grid-template-areas: 'search ${showFilter && 'filter'}';
  `}
`;

export const FilterOptionsShell = styled.div`
  grid-area: filter-options;
  &.hidden {
    display: none;
  }
`;

export const ViewShell = styled.div`
  grid-area: view;
`;

export const ActionButtonShell = styled.div`
  grid-area: action-button;
`;
