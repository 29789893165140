// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { COLORS } from '@tls/treact-ui';
import React from 'react';
import styled, { css } from 'styled-components';

type Props = {
  showBackdrop: boolean;
  width?: number;
};

export default function Backdrop({ showBackdrop, children, width }: React.PropsWithChildren<Props>) {
  if (!showBackdrop) return <></>;
  return (
    <BackdropContainer>
      <CenteredContent width={width}>{children}</CenteredContent>
    </BackdropContainer>
  );
}

const BackdropContainer = styled.div`
  top: 0;
  left: 0;
  background: rgba(${COLORS.trwhite.rgb}, 0.95);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

const CenteredContent = styled.div<{ width?: number }>`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  ${({ width }) => {
    if (!width) return;

    return css`
      min-width: ${width}%;
    `;
  }}
`;
