// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import React from 'react';
import { createRoot } from 'react-dom/client';
import reportWebVitals from 'reportWebVitals';
import { ThemeProvider } from 'styled-components';
import { Meta, Style } from '@tls/treact-ui';
import { SafContext } from '@tls/react-saf-ui';
import { Provider } from 'react-redux';
import { store } from 'store/store';
import { logo, SplashLoading } from 'utilities/logo';
import uicSvgIcons from '@trumpf/ux.ui-community/dist/ux-ui-community/icons/icons.svg';
import { setAssetPath } from '@stencil/core';
import { UicIconset } from '@trumpf/ux.ui-community-react';
import 'index.css';
import '@trumpf/ux.ui/dist/ux-ui/ux-ui.css';
import '@trumpf/ux.ui-community/dist/ux-ui-community/ux-ui-community.css';
import App from 'App';
import { getAppBaseUrl } from '@tls/react-saf-ui/lib/utilities/api';
import { defineCustomElements } from '@trumpf/ux.ui/loader';

const appBaseUrl = getAppBaseUrl();
const accessPath = window.origin + appBaseUrl;
setAssetPath(accessPath);

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <UicIconset name='uic' resolver={name => `${accessPath}${uicSvgIcons}#${name}`} />
      <ThemeProvider theme={Meta.THEMES.Default}>
        <Style />
        <SafContext.Provider logo={logo} progressBar={<SplashLoading />}>
          <App />
        </SafContext.Provider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

defineCustomElements();
reportWebVitals();
