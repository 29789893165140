// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { useTranslation } from 'react-i18next';
import React from 'react';
import { ICONS } from '@tls/treact-ui';
import { useSafContext } from '@tls/react-saf-ui';
import { Background, Details, Title } from 'components/common/Background';
import styled from 'styled-components';

const { IconClose } = ICONS;
export default function NoAccess() {
  const { t } = useTranslation();

  const { appBaseUrl } = useSafContext();

  return (
    <Background appBaseUrl={appBaseUrl ?? ''}>
      <IconContainer className='icon'>
        <IconClose height={'10rem'} width={'10rem'} color='#285172' />
      </IconContainer>
      <Details className='text'>
        <Title>{t('accessDenied.title')}</Title>
        <div className='font-size_m details'>{t('accessDenied.details')}</div>
      </Details>
    </Background>
  );
}

const IconContainer = styled.div`
  margin-top: 5rem;
  margin-right: 5rem;
`;
