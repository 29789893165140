// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF

export enum ProjectsSubPath {
  List = 'list',
  Tiles = 'tiles',
}

export enum ProjectSubPath {
  Label = 'label',
  Evaluate = 'evaluate',
}

export enum SettingsSubPath {
  Users = 'users',
  AddUser = 'users/add',
  Contracts = 'contracts',
  Roles = 'roles',
  AddRole = 'roles/add',
}

export const AppRoutes = {
  Projects: '/projects',
  ProjectsTilesSubPath: ProjectsSubPath.Tiles,
  ProjectTilesFull: `/projects/${ProjectsSubPath.Tiles}`,
  ProjectsListSubPath: ProjectsSubPath.List,
  ProjectListFull: '/projects/list',

  Project: '/project',
  ProjectWithId: (id: string, detail: string) => `/project/${id}/${detail}`,
  ProjectLabelSubpath: ProjectSubPath.Label,
  ProjectLabelFullPath: (id: string) => `/project/${id}/${ProjectSubPath.Label}`,
  ProjectEvaluationSubPath: ProjectSubPath.Evaluate,
  ProjectEvaluationFullPath: (id: string) => `/project/${id}/${ProjectSubPath.Evaluate}`,

  Settings: '/settings',
  SettingsUser: `/settings/${SettingsSubPath.Users}`,
  SettingsUserSubpath: SettingsSubPath.Users,
  SettingsAddUser: `/settings/${SettingsSubPath.AddUser}`,
  SettingsContracts: `/settings/${SettingsSubPath.Contracts}`,
  SettingsRoleSubpath: SettingsSubPath.Roles,
  SettingsRole: `/settings/${SettingsSubPath.Roles}`,
  SettingsAddRole: `/settings/${SettingsSubPath.AddRole}`,
};
