// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { del, post, put } from '@tls/react-saf-ui';
import { StrategyType, ModelClassMessage } from '@tls/sw91-communication/types/com.base';
import { useCallback, useState } from 'react';

export type SaveProps = { name: string; tags: string[]; label: ModelClassMessage; strategy: StrategyType };
export type EditProps = { name: string; tags: string[]; id: string; label: ModelClassMessage };

export default function useProjectSelectionApi() {
  const [loading, setLoading] = useState(false);
  const [statusCode, setStatusCode] = useState(200);

  const save = useCallback(async ({ name, label, tags, strategy }: SaveProps) => {
    setLoading(true);
    const { data, status, statusText } = await post('projects', {
      projectName: name,
      tags: tags,
      modelClasses: [label],
      strategy: strategy,
    });
    setLoading(false);
    setStatusCode(status);
    return { data, status, statusText };
  }, []);

  const edit = useCallback(async ({ name, tags, id, label }: EditProps) => {
    setLoading(true);
    const { data, status, statusText } = await put(`projects/${id}`, {
      projectName: name,
      tags: tags,
      modelClasses: [label],
    });
    setLoading(false);
    setStatusCode(status);
    return { data, status, statusText };
  }, []);

  const duplicate = useCallback(async ({ name, tags, id, label }: EditProps) => {
    setLoading(true);
    const { data, status, statusText } = await post(`projects/${id}/duplicate`, {
      projectName: name,
      tags: tags,
      modelClasses: [label],
    });
    setLoading(false);
    setStatusCode(status);
    return { data, status, statusText };
  }, []);

  const remove = useCallback(async (id: string) => {
    setLoading(true);

    const { data, status, statusText } = await del(`projects/${id}`);
    setLoading(false);
    setStatusCode(status);
    return { data, status, statusText };
  }, []);

  return { save, edit, remove, duplicate, loading, statusCode };
}
