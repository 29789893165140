// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { useSafContext } from '@tls/react-saf-ui';
import { Atoms, ICONS, Organisms } from '@tls/treact-ui';
import { ToastByStatus } from 'components/treactui-template/organisms/toast/ToastMessage';
import useProjectsMetadata from 'hooks/useProjectsMetadata';
import useShareApi from 'hooks/useShareApi';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { toggleShareDialog } from 'store/reducers/projectShareSlice';
import styled from 'styled-components';
const { Dialog, Table } = Organisms;

export default function ShareProjectDialog() {
  const { t } = useTranslation();
  const [newUsername, setNewUsername] = React.useState('');

  const { show, id } = useAppSelector(s => s.shareProject);
  const { selectedProject } = useProjectsMetadata(id);
  const { projectName } = selectedProject ?? {};

  const dispatch = useAppDispatch();
  const onClose = useCallback(() => dispatch(toggleShareDialog()), [dispatch]);

  const { loadingUsers, addAccess, removeAccess, users } = useShareApi(id ?? '');

  const [loadingSave, setLoadingSave] = useState(false);
  const onSave = useCallback(async () => {
    setLoadingSave(true);
    const { status } = await addAccess(newUsername);

    ToastByStatus(
      status,
      t('project.settings.share.title', { name: projectName }),
      t('project.settings.share.error', { name: newUsername })
    );

    if (status === 200) {
      setNewUsername('');
    }
    setLoadingSave(false);
  }, [addAccess, newUsername, projectName, t]);

  const [removingUserId, setRemovingUserId] = useState<string | undefined>();
  const onRemove = useCallback(
    (userId: string) => async () => {
      setRemovingUserId(userId);
      const { status } = await removeAccess(userId);
      ToastByStatus(
        status,
        t('project.settings.share.title', { name: projectName }),
        t('project.settings.share.remove.error', { name: userId })
      );
      setRemovingUserId(undefined);
    },
    [projectName, removeAccess, t]
  );

  const { currentUser } = useSafContext();
  const generateUserRow = useCallback(
    (user: string) => {
      return (
        <Table.Row key={user}>
          <Table.Cell width={1} alignment='LEFT'>
            {user}
          </Table.Cell>
          {currentUser?.userName !== user && (
            <Table.Cell width={'fill'}>
              {removingUserId === user ? (
                <ICONS.IconLoading height='1.5rem' />
              ) : (
                <Atoms.Button variant='secondary' iconKey='IconDelete' onClick={onRemove(user)} />
              )}
            </Table.Cell>
          )}
        </Table.Row>
      );
    },
    [currentUser?.userName, onRemove, removingUserId]
  );

  let saveIcon = <ICONS.IconPlus />;
  if (loadingSave) {
    saveIcon = <ICONS.IconLoading />;
  }

  const loadingRow = useMemo(
    () => (
      <Table.Row>
        <Table.Cell width={1} alignment='CENTER'>
          <ICONS.IconLoading />
          <span className='pushRight'>{t('loading')}</span>
        </Table.Cell>
      </Table.Row>
    ),
    [t]
  );

  return (
    <Dialog enableClosing open={show} size={'large'} onClose={onClose}>
      <Dialog.DialogHeader label={t('project.settings.share.title', { name: projectName })} />
      <Dialog.DialogContent>
        <AddUserRegion>
          <Atoms.InputFieldText
            asAtom
            placeholder={t('project.settings.share.enterUsername')}
            value={newUsername}
            onValueChange={setNewUsername}
          />
          <Atoms.Button onClick={onSave} icon={saveIcon} disabled={loadingSave || newUsername?.length < 1} />
        </AddUserRegion>
        <Title className='font-size_m font-weight_bold'>{t('project.settings.share.userTitle')}</Title>
        <Table list={users} headless>
          {userList => (
            <Table.Content>
              <Table.Body scrollView={<ScrollableContainer />}>
                {loadingUsers ? loadingRow : userList.map(generateUserRow)}
              </Table.Body>
            </Table.Content>
          )}
        </Table>
      </Dialog.DialogContent>
      <Dialog.Footer
        right={[<Atoms.FooterButton key='cancel' label={t('close')} onClick={onClose} variant='secondary' />]}
      />
    </Dialog>
  );
}

const AddUserRegion = styled.div`
  display: flex;
  column-gap: 1rem;
  margin-bottom: 1rem;
`;

const ScrollableContainer = styled.div`
  overflow-y: auto;
  height: 100%;

  .pushRight {
    padding-left: 0.5rem;
  }
`;

const Title = styled.span`
  margin-bottom: 1rem;
`;
