// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF

import { ProjectMetadata } from './ProjectMetadata';
export type OrderByType = 'asc' | 'desc';

export enum OrderType {
  Name = 'name',
  ModifiedDate = 'modifiedDate',
  Status = 'status',
}

export function sort(a, b, order: OrderByType) {
  const factor = order === 'desc' ? -1 : 1;
  if (a === undefined && b === undefined) return 0;
  if (a === undefined) return -1 * factor;
  if (b === undefined) return 1 * factor;

  // Case-insensitive string comparison for 'name' field
  if (typeof a === 'string' && typeof b === 'string') {
    a = a.toLowerCase();
    b = b.toLowerCase();
  }

  if (a < b) return -1 * factor;
  if (a > b) return 1 * factor;

  return 0;
}

export const sortByName = (order: OrderByType) => (a: ProjectMetadata, b: ProjectMetadata) =>
  sort(a.projectName, b.projectName, order);

export const sortByModifiedDate = (order: OrderByType) => (a: ProjectMetadata, b: ProjectMetadata) =>
  sort(a.modifyTime, b.modifyTime, order);

export const sortByStatus = (order: OrderByType) => (a: ProjectMetadata, b: ProjectMetadata) =>
  sort(a.projectStatus, b.projectStatus, order);
