// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import React from 'react';
import styled from 'styled-components';
import ToolSelection from './tools/Selection';
import ToolMode from './tools/Mode';
import ToolSettings from './tools/Settings';

export default function LabelTools() {
  return (
    <Container>
      <ToolSelection />
      <ToolMode />
      <ToolSettings />
    </Container>
  );
}

const Container = styled.div`
  padding: 0 0.5rem 0;
  display: grid;
  grid-template-rows: auto 1fr auto;
  row-gap: 1rem;
  height: calc(100% - 1rem);
`;
