// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';
import { getAuthorizationHeader } from '@tls/react-saf-ui';
import { ToastByStatus } from 'components/treactui-template/organisms/toast/ToastMessage';
import UpdateEmitter from 'components/common/emitter/UpdateEmitter';
import { HistoryInformationMessage } from '@tls/sw91-communication/types/com.base';
import { EarlyStoppingMessage } from '@tls/sw91-communication/types/com.api_number_cruncher';
import {
  ExportStrategyDataMessage,
  ExportStrategyProgressMessage,
} from '@tls/sw91-communication/types/com.api_tai_exporter';
import { isModelEvaluationProgressResult } from 'model/ModelEvaluationProgressResult';
import { updateProjectStatus } from 'store/reducers/projectsSlice';
import { isProjectStatusAckMessage } from 'model/reponse/ProjectStatusAckMessage';
import { AppDispatch } from 'store/store';
import { GetAllLabelPackageInfoAckMessage } from '@tls/sw91-communication/types/com.api_db_access';

const RefreshStates = [HubConnectionState.Disconnected, HubConnectionState.Disconnecting];

class Connector {
  private connection?: signalR.HubConnection;
  static instance: Connector;
  static eventEmitter = new UpdateEmitter();
  private appBaseUrl: string;
  static reconnects = 0;
  static dispatch: AppDispatch;

  constructor(appBaseUrl: string) {
    this.appBaseUrl = appBaseUrl;
  }

  public setUpConnection() {
    this.connection = new HubConnectionBuilder()
      .withUrl(`${this.appBaseUrl}/api/live`, {
        accessTokenFactory: () => {
          return getAuthorizationHeader()?.Authorization?.split(' ')[1] ?? '';
        },
      })
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Information)
      .build();

    this.connection.onreconnecting(e => {
      if (e && (('statusCode' in e && e.statusCode === 401) || (e.message?.indexOf('401') ?? -1) > -1)) {
        // Token has expired. Start a new connection.
        this.connection?.stop();
      }
    });

    this.connection.onclose(() => {
      setTimeout(() => Connector.refreshInstance(this.appBaseUrl, function noOp() {}), 500);
    });

    this.connection.on('onhistoryinformationupdate', function (message) {
      Connector.eventEmitter.emit('onHistoryInformationUpdate', HistoryInformationMessage.fromJSON(message));
    });

    this.connection.on('onearlystopping', function (message) {
      Connector.eventEmitter.emit('onEarlyStopping', EarlyStoppingMessage.fromJSON(message));
    });

    this.connection.on('onexportprogress', function (message) {
      Connector.eventEmitter.emit('onExportProgress', ExportStrategyProgressMessage.fromJSON(message));
    });

    this.connection.on('onexportfailed', function (message) {
      Connector.eventEmitter.emit('onExportFailed', ExportStrategyDataMessage.fromJSON(message));
    });

    this.connection.on('onevaluationcompleted', function (message) {
      if (!isModelEvaluationProgressResult(message)) return;
      Connector.eventEmitter.emit('onEvaluationCompleted', message);
    });

    this.connection.on('onevaluationfailed', function (message) {
      if (!isModelEvaluationProgressResult(message)) return;
      Connector.eventEmitter.emit('onEvaluationFailed', message);
    });

    this.connection.on('onprojectstatuschanged', function (message) {
      if (!isProjectStatusAckMessage(message)) return;
      Connector.eventEmitter.emit('onProjectStatusChanged', message);
      Connector.dispatch(updateProjectStatus(message));
    });

    this.connection.on('onlabelpackageinfo', function (message) {
      if (!Array.isArray(message)) return;

      const typedMessage = message.map(GetAllLabelPackageInfoAckMessage.fromJSON);
      if (!typedMessage) return;
      Connector.eventEmitter.emit('onLabelPackageInfo', typedMessage);
    });
  }

  public async connect() {
    do {
      try {
        this.setUpConnection();
        await this.connection?.start();
      } catch {
        if (Connector.reconnects++ > 10) {
          ToastByStatus(500, 'Live updates', 'Unable to start a live connection');
          return;
        }
        await new Promise(resolve => setTimeout(resolve, 2000));
      }
    } while (this.connection?.state !== HubConnectionState.Connected && Connector.reconnects++ < 10);

    Connector.reconnects = 0;
  }

  public static async refreshInstance(appBaseUrl: string, onConnected: () => void): Promise<Connector | undefined> {
    const header = getAuthorizationHeader();
    if (!header.Authorization) {
      return undefined;
    }

    if (
      Connector.instance &&
      (Connector.instance.appBaseUrl !== appBaseUrl ||
        RefreshStates.includes(Connector.instance.connection?.state ?? HubConnectionState.Disconnected))
    ) {
      await this.forceRefresh(appBaseUrl, onConnected);
    }

    if (!Connector.instance) {
      await this.forceRefresh(appBaseUrl, onConnected);
    }

    return Connector.instance;
  }

  public static async forceRefresh(appBaseUrl: string, onConnected: () => void): Promise<Connector | undefined> {
    const header = getAuthorizationHeader();
    if (!header.Authorization) {
      return undefined;
    }

    if (Connector.instance) Connector.instance.connection?.stop();
    Connector.instance = new Connector(appBaseUrl);
    await Connector.instance.connect();

    if (Connector.instance.connection?.state === HubConnectionState.Connected) {
      onConnected();
    }

    return Connector.instance;
  }

  public static getEventEmitter(): UpdateEmitter | undefined {
    return Connector.eventEmitter;
  }

  public static SetDispatch(dispatch: AppDispatch) {
    Connector.dispatch = dispatch;
  }
}

export default Connector;
