// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSafContext } from '@tls/react-saf-ui';
import { Atoms, COLORS } from '@tls/treact-ui';
import { Background, Details, Title } from './Background';
import { AppRoutes } from 'utilities/router/AppRoutes';
import styled from 'styled-components';
import IconError from 'components/treactui-template/icons/IconError';
import IconHome from 'components/treactui-template/icons/IconHome';

export default function ErrorBound() {
  const { t } = useTranslation();
  const { appBaseUrl } = useSafContext();
  const goHome = useCallback(() => (window.location.href = `${appBaseUrl}${AppRoutes.Projects}`), [appBaseUrl]);

  return (
    <Background appBaseUrl={appBaseUrl ?? ''}>
      <Icon className='icon'>
        <IconError width={'15rem'} />
      </Icon>
      <Details className='text'>
        <Title>{t('error.title')}</Title>
        <div className='font-size_m details'>{t('error.details')}</div>

        <Atoms.Button
          icon={<IconHome />}
          className='margin-top'
          label={t('error.home')}
          onClick={goHome}
          variant='secondary'
        />
      </Details>
    </Background>
  );
}

const Icon = styled.div`
  font-size: 8rem;
  font-weight: 700;
  color: ${COLORS.trblue35.hex};
  margin-right: 10rem;
`;
