// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { COLORS } from '@tls/treact-ui';
import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { GetStatusTranslationKey, ProjectStatusIcon } from 'model/ProjectMetaMessageExtensions';
import IconImage from 'components/treactui-template/icons/IconImage';
import IconLabel from 'components/treactui-template/icons/IconLabel';
import IconVersion from 'components/treactui-template/icons/IconVersion';
import Ellipsis from 'components/treactui-template/atoms/Ellipsis';
import { AppRoutes, ProjectSubPath } from 'utilities/router/AppRoutes';
import Options from '../Options';
import { AnimationCss } from 'components/treactui-template/atoms/animation/AnimatePulse';
import { InfoTag } from 'components/treactui-template/atoms/InfoTag';
import { ProjectMetadata } from 'model/ProjectMetadata';
import { loadArrayIntoImageElement } from 'components/treactui-template/meta/loadImageIntoElement';
import useProjectDetailsNavigation from 'hooks/useProjectDetailsNavigation';
import { useSafContext } from '@tls/react-saf-ui';
import LockedInfo from './LockedInfo';
import BackdropLoading from 'components/treactui-template/organisms/backdrop/loading/BackdropLoading';

export type TileProps = {
  entry: ProjectMetadata;
};

const timeFormat: Intl.DateTimeFormatOptions = {
  dateStyle: 'medium',
  timeStyle: 'short',
};

export default function Tile({ entry }: Readonly<TileProps>) {
  const { t, i18n } = useTranslation();

  const { navigateToDetails, loading } = useProjectDetailsNavigation();

  const goToDetails = useCallback<React.MouseEventHandler<HTMLElement>>(
    e => {
      if (
        (e.target as HTMLElement)?.className?.includes('moveRight') ||
        (e.target as HTMLElement)?.className?.includes('DropdownItemButton')
      )
        return;

      navigateToDetails(AppRoutes.ProjectWithId(entry.projectId ?? '', ProjectSubPath.Label), entry.projectId);
    },
    [entry.projectId, navigateToDetails]
  );

  const tags =
    entry.tags?.map(t => (
      <InfoTag key={t}>
        <span className='font-size_xs font-weight_semibold'>{t}</span>
      </InfoTag>
    )) ?? [];

  if (tags.length < 1) {
    tags.push(
      <Ellipsis key='no-content' className='font-size_xs pushRight'>
        {t('project.tag.noTags')}
      </Ellipsis>
    );
  }

  const imageElement = useRef<HTMLImageElement>(null);
  useEffect(() => {
    if (!entry.projectId || !entry.previewImage?.previewImageData || entry.previewImage.previewImageData.length < 1) {
      return;
    }

    loadArrayIntoImageElement(entry.previewImage.previewImageData, 'image/png', imageElement);
  }, [entry?.previewImage?.previewImageData, entry.projectId]);

  const lastExport =
    !entry.strategyExportTime || entry.strategyExportTime.getTime() <= 0 ? null : entry.strategyExportTime;

  const { appBaseUrl } = useSafContext();
  return (
    <Container onClick={goToDetails}>
      <BackdropLoading loading={loading} label={t('project.actions.lock.aquire')} spinner />
      <Header>
        <ProjectStatusIcon status={entry.projectStatus} width={'1.5rem'} />
        <div>{t(GetStatusTranslationKey(entry.projectStatus))}</div>
        <Options projectId={entry.projectId ?? ''} projectName={entry.projectName ?? ''} lockedBy={entry.lockedBy} />
      </Header>
      <Title>
        <LockedInfo lockedBy={entry.lockedBy} />
        <Ellipsis className='font-size_xl'>{entry.projectName}</Ellipsis>
      </Title>
      <Divider />
      <Details>
        <span className='tall'>{t('project.tag.title')}</span>
        <TagContainer>
          <HideOverflow />
          {tags}
        </TagContainer>
        <span className='tall'>{t('project.lastExport')}</span>
        <Ellipsis className='font-size_xs pushRight'>
          {lastExport?.toLocaleString(i18n.language, timeFormat) ?? t('project.notExported')}
        </Ellipsis>
        <span className='tall'>{t('project.lastModified')}</span>
        <Ellipsis className='font-size_xs pushRight'>
          {entry.modifyTime?.toLocaleString(i18n.language, timeFormat)}
        </Ellipsis>
      </Details>
      <Divider />
      <Image src={`${appBaseUrl}/images/image-placeholder.png`} ref={imageElement} alt='preview' />
      <ImageDetails>
        <ImageDetail color={(entry.numberImages ?? 0) > 0 ? 'current' : COLORS.trgrey5.hex}>
          <IconImage width='1.5rem' />
          <span>{entry.numberImages}</span>
        </ImageDetail>
        <ImageDetail color={(entry.numberLabels ?? 0) > 0 ? 'current' : COLORS.trgrey5.hex}>
          <IconLabel width='1.5rem' />
          <span>{entry.numberLabels}</span>
        </ImageDetail>
        <ImageDetail color={entry.projectVersion ? 'current' : COLORS.trgrey5.hex}>
          <IconVersion width='1.5rem' />
          <span>{entry.projectVersion ?? '-'}</span>
        </ImageDetail>
      </ImageDetails>
    </Container>
  );
}

const Image = styled.img`
  height: 10rem;
  min-height: 10rem;
  width: 100%;
  min-width: 100%;
  background-color: rgba(${COLORS.trgrey2.rgb}, 0.4);
  ${AnimationCss}
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.5rem;
  height: 2.5rem;
`;

const Details = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0.25rem 1rem;
  span > {
    line-height: 1.5rem;
  }
  .pushRight {
    text-align: right;
    line-height: 1.5rem;
  }
  .tall {
    height: 1.5rem;
  }
  span {
    line-height: 1.5rem;
  }
`;

const ImageDetails = styled.div`
  display: flex;
  column-gap: 0.75rem;
`;

const ImageDetail = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 0.5rem;
  align-items: center;
  ${({ color }) => css`
    color: ${color};
  `}
`;

const Container = styled.div`
  position: relative;
  height: 26.5rem;
  width: 17rem;
  min-width: 17rem;
  box-shadow:
    0 2px 4px 0 rgba(0, 0, 0, 0.05),
    0 2px 8px 0 rgba(161, 161, 161, 0.4);
  padding: 0 0.5rem;
  border: 1px solid ${COLORS.trgrey5.hex};

  ${({ theme }) => css`
    @media (hover: hover) {
      &:hover {
        box-shadow:
          inset 0 0 0 1px ${theme.trcolor1.hex},
          0 0 3px 0 ${theme.trcolor3.hex};
      }
    }
  `}
`;

const Header = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  column-gap: 0.5rem;

  .moveRight {
    margin-right: -0.5rem;
  }
`;

const Divider = styled.div`
  border-bottom: 1px solid ${COLORS.trgrey5.hex};
  margin: 0.5rem 0;
`;

const HideOverflow = styled.div`
  background: linear-gradient(to left, rgba(${COLORS.trwhite.rgb}, 0.02) 0, ${COLORS.trwhite.hex} 100%);
  position: absolute;
  float: left;
  left: 0;
  height: 100%;
  width: 1rem;
`;

const TagContainer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  height: 1.5rem;
  column-gap: 0.25rem;
  justify-content: flex-end;
`;
