// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { AuthGuard, GetAuthRoutes, Loading, useSafContext } from '@tls/react-saf-ui';
import Evaluate from 'components/project/Evaluate';
import Label from 'components/project/Label';
import List from 'components/projectSelection/List';
import Tiles from 'components/projectSelection/Tiles';
import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { AppRoutes } from 'utilities/router/AppRoutes';
import Nav from './Nav';
import NotFound from 'pages/NotFound';
import Project from 'pages/Project';
import Projects from 'pages/Projects';
import NoAccess from 'pages/NoAccess';
import Settings from 'pages/Settings';
import Users from 'components/settings/Users';
import AddUser from 'components/settings/AddUser';
import Contracts from 'components/settings/Contracts/Contracts';
import Connector from 'hub/Connector';
import Roles from 'components/settings/Roles';
import AddRole from 'components/settings/AddRole';

export default function ApplicationRouter() {
  const { hasAccess, appBaseUrl, authService, logo, progressBar } = useSafContext();

  const [signalRConnectionEstablished, setSignalRConnectionEstablished] = useState(false);

  useEffect(() => {
    if (!authService?.isAuthenticated() || !hasAccess) {
      return;
    }

    function onConnected() {
      setSignalRConnectionEstablished(true);
    }

    let subscriptionId: number;
    if (authService?.subscribeTokenChange) {
      subscriptionId = authService.subscribeTokenChange(async () => {
        Connector.forceRefresh(appBaseUrl ?? '', onConnected);
      });
    }

    async function setUpConnection() {
      Connector.refreshInstance(appBaseUrl ?? '', onConnected);
    }
    setUpConnection();

    return () => {
      if (!authService.unsubscribeTokenChange || !subscriptionId) return;
      authService.unsubscribeTokenChange(subscriptionId);
    };
  }, [appBaseUrl, authService, hasAccess]);

  if (!hasAccess && authService?.isAuthenticated()) return <NoAccess />;

  // Ensure signalR connection is established, otherwise we can't receive pictures on page refresh
  if (!signalRConnectionEstablished) {
    return (
      <Routes>
        <Route element={<Nav />}>
          {GetAuthRoutes()}
          <Route
            path='*'
            element={
              <Loading basePath={appBaseUrl} logo={logo}>
                {progressBar}
              </Loading>
            }
          />
        </Route>
      </Routes>
    );
  }

  return (
    <Routes>
      <Route element={<Nav />}>
        {GetAuthRoutes()}

        <Route path='/' element={<Navigate to={AppRoutes.Projects} />} />
        <Route
          path={AppRoutes.Projects}
          element={
            <AuthGuard>
              <Projects />
            </AuthGuard>
          }
        >
          <Route path={AppRoutes.ProjectsTilesSubPath} element={<Tiles />} />
          <Route path={AppRoutes.ProjectsListSubPath} element={<List />} />
        </Route>
        <Route
          path={`${AppRoutes.Project}/:id`}
          element={
            <AuthGuard>
              <Project />
            </AuthGuard>
          }
        >
          <Route path={AppRoutes.ProjectLabelSubpath} element={<Label />} />
          <Route path={AppRoutes.ProjectEvaluationSubPath} element={<Evaluate />} />
        </Route>
        <Route
          path={AppRoutes.Settings}
          element={
            <AuthGuard>
              <Settings />
            </AuthGuard>
          }
        >
          <Route path={AppRoutes.SettingsUserSubpath} element={<Users />} />
          <Route path={AppRoutes.SettingsAddUser} element={<AddUser />} />
          <Route path={AppRoutes.SettingsRoleSubpath} element={<Roles />} />
          <Route path={AppRoutes.SettingsAddRole} element={<AddRole />} />
          <Route path={AppRoutes.SettingsContracts} element={<Contracts />} />
        </Route>
        <Route path='*' element={<NotFound />} />
      </Route>
    </Routes>
  );
}
