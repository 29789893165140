// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useProjectLockApi } from './useProjectLockApi';

export default function useProjectDetailsNavigation() {
  const navigate = useNavigate();

  const { lock, loading } = useProjectLockApi();

  const navigateToDetails = useCallback(
    async (url: string, projectIdNEW?: string) => {
      if (loading) return;

      const response = await lock(projectIdNEW ?? '');

      if (!response) {
        return;
      }
      navigate(url);
    },
    [loading, lock, navigate]
  );

  return { navigateToDetails, loading };
}
