// SPDX-FileCopyrightText: 2024 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import IconTouchLoading from 'components/treactui-template/icons/IconTouchLoading';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
export default function SelectRole() {
  const { t } = useTranslation();
  return (
    <Centered>
      <IconTouchLoading height='13rem' width='22rem' />
      <span className='font-size_l'>{t('settings.roles.selectRole')}</span>
    </Centered>
  );
}

const Centered = styled.div`
  vertical-align: middle;
  margin: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
