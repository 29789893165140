// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { COLORS, FLAGS, ICONS } from '@tls/treact-ui';
import FlagErrorInteraction from 'components/treactui-template/icons/FlagErrorInteraction';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { hideHeader } from 'store/reducers/licenseSlice';
import styled, { css } from 'styled-components';

const { IconClose } = ICONS;
const WarningFlag = FLAGS.COMPONENTS.warning;

const MS_PER_DAY = 1000 * 60 * 60 * 24;
const TEN_DAYS = MS_PER_DAY * 10;
const yearZero = new Date(0);

export default function LicenseInfo() {
  const { loading, contract, hide } = useAppSelector(s => s.license);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  if (loading) return <></>;

  const { lastActiveEndDate, isActive, lastStartDate } = contract ?? {
    freeTrainingSessions: 0,
    isActive: false,
    lastActiveEndDate: yearZero,
    lastStartDate: yearZero,
  };
  const expiration = lastActiveEndDate.getTime() - new Date().getTime();

  if (isActive && (expiration > TEN_DAYS || lastStartDate >= lastActiveEndDate)) return <></>;

  let flag = <WarningFlag width={'1.75rem'} />;
  let title = 'license.warning.title';
  if (expiration < MS_PER_DAY) title = 'license.warning.today';
  else if (expiration < MS_PER_DAY * 2) title = 'license.warning.tomorrow';

  let description = 'license.warning.details';

  if (!isActive) {
    const expired = lastActiveEndDate > yearZero;
    flag = <FlagErrorInteraction width={'1.75rem'} />;
    title = expired ? 'license.expired' : 'license.noLicense';
    description = 'license.details';
  } else if (hide) {
    //If the use hid a warning, we don't show it again
    return <></>;
  }

  return (
    <LicenseHeader isActive={isActive}>
      {flag}
      <span className='font-size_s font-weight_bold'>{t(title, { days: Math.floor(expiration / MS_PER_DAY) })}</span>
      <span className='font-size_s'>{t(description)}</span>
      {isActive && <IconClose className='button' width={'1rem'} onClick={() => dispatch(hideHeader())} />}
    </LicenseHeader>
  );
}

const LicenseHeader = styled.section<{ isActive: boolean }>`
  height: 2.25rem;
  padding: 0.5rem;

  ${({ isActive }) => css`
    background-color: ${isActive ? COLORS.trgrey1.hex : COLORS.trred2.hex};
    color: ${isActive ? COLORS.trblack.hex : COLORS.trwhite.hex};
  `}

  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.5rem;

  .button {
    :hover {
      cursor: pointer;
      color: ${({ theme }) => theme.trcolor1.hex};
    }
  }
`;
