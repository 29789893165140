// SPDX-FileCopyrightText: 2024 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { LabelPackageInfoMessage } from '@tls/sw91-communication/types/com.api_db_access';
import { createContext } from 'react';

export type EditImageProps = {
  imageMd5?: string;
  hasMask?: boolean;
  imageName?: string;
  previewData?: Uint8Array;
  isValidation?: boolean;
  updateTime?: Date;
};

export interface ImagesContextType {
  images: LabelPackageInfoMessage[];
  addImage: (message: LabelPackageInfoMessage) => void;
  removeImage: (imageMd5: string) => void;
  editImage: (props: EditImageProps) => void;
  loading: boolean;
}

export const ImagesContext = createContext<ImagesContextType>({
  images: [],
  addImage: function noRef() {},
  removeImage: function noRef() {},
  editImage: function noRef() {},
  loading: false,
});
