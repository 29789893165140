// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { RequestStrategyEvaluationAckMessage } from '@tls/sw91-communication/types/com.api_predictor';

export type ModelEvaluationProgressResult = {
  id: string | undefined;
} & RequestStrategyEvaluationAckMessage;

export function isModelEvaluationProgressResult(arg: any): arg is ModelEvaluationProgressResult {
  return (
    arg?.messageHeader !== undefined &&
    arg?.status !== undefined &&
    arg?.evaluationResult !== undefined &&
    arg?.progress !== undefined &&
    arg?.id !== undefined
  );
}
