// SPDX-FileCopyrightText: 2024 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { Role } from 'model/Role';
import { useAppSelector } from 'store/hooks';

export default function useFilteredRoles(users?: Role[]) {
  const filter = useAppSelector(s => s.uiSettings.filterText)?.toLocaleLowerCase();

  return users?.filter(
    u =>
      u.name?.toLocaleLowerCase()?.includes(filter) ??
      u.permissions?.some(p => p?.toLocaleLowerCase()?.includes(filter))
  );
}
