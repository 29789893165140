// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { post } from '@tls/react-saf-ui';
import { ProjectVersion } from 'model/ProjectMetaMessageExtensions';
import { useCallback, useState } from 'react';

export default function useModelEvaluationTriggerApi(projectId: string) {
  const [loading, setLoading] = useState(false);
  const [lastStatus, setLastStatus] = useState(200);
  const [evaluationId, setEvaluationId] = useState<string | undefined>(undefined);

  const trigger = useCallback(
    (versions: ProjectVersion[]) => {
      let disposed = false;
      async function getData() {
        setLoading(true);
        setLastStatus(200);

        const { data, status } = await post(`projects/${projectId}/models/evaluation/trigger`, {
          versions,
        });
        if (disposed) return;

        setLoading(false);
        setLastStatus(status);

        if (status < 200 || status >= 400 || !data) {
          return;
        }

        setEvaluationId(data);
      }
      getData();
      return () => {
        disposed = true;
      };
    },
    [projectId]
  );

  return { loading, lastStatus, trigger, evaluationId };
}
