// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import styled from 'styled-components';

export function TextWithEllipsis(value = '', maxLength = 6) {
  const text = value?.length > maxLength ? value.slice(0, maxLength - 1) : value ?? '';
  const suffix = value?.length > maxLength ? '…' : '';
  return text + suffix;
}

const Ellipsis = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

export default Ellipsis;
