// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { post } from '@tls/react-saf-ui';
import { useState } from 'react';

export default function useProjectImportApi() {
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState<number>();
  const [estimatedRemainingTime, setEstimatedRemainingTime] = useState<number>();

  async function uploadProject(project: File) {
    setUploading(true);

    const formData = new FormData();
    formData.append('project', project);
    //Add project content as readable stream

    const result = await post('projects/import', formData, false, false, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: progressEvent => {
        setProgress((progressEvent.progress ?? 0) * 100);
        setEstimatedRemainingTime(progressEvent.estimated);
      },
    });

    setProgress(undefined);
    setEstimatedRemainingTime(undefined);
    setUploading(false);
    return result;
  }

  return { uploading, uploadProject, progress, estimatedRemainingTime };
}
