// SPDX-FileCopyrightText: 2024 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import useUsersApi from 'hooks/useUsersApi';
import { User } from 'model/User';
import React, { useCallback } from 'react';
import UserDetails from './Users/UserDetails';
import PageContent from 'components/treactui-template/organisms/page/PageContent';
import { useNavigate } from 'react-router';
import { AppRoutes } from 'utilities/router/AppRoutes';

export default function AddUser() {
  const { users, loadingUsers, addUser } = useUsersApi();
  const navigate = useNavigate();

  const createUser = useCallback(
    async (user: User) => {
      const result = await addUser(user);
      if (result.status === 200) navigate(AppRoutes.SettingsUser);
    },
    [addUser, navigate]
  );

  return (
    <PageContent displayAsPaper disableScroll>
      <UserDetails
        user={{} as User}
        users={users}
        isLoading={loadingUsers}
        addOrEditUser={createUser}
        isUsernameEditable={true}
        showContracts={false}
        onClose={() => navigate(AppRoutes.SettingsUser)}
      />
    </PageContent>
  );
}
