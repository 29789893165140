// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { FLAGS, Organisms } from '@tls/treact-ui';
import React from 'react';
import { ToastOptions, toast } from 'react-toastify';

export enum ToastMessageType {
  Checked = 'checked',
  Error = 'error',
  Information = 'information',
  Warning = 'warning',
}

export type ToastMessageProps = {
  headline: string;
  message: string;
  flag: FLAGS.FlagKeys;
  closeToast?: () => void;
  link?: {
    label: string;
    onClick: () => void;
    disabled?: boolean;
  };
};

export type QueueToastProps = {
  closeOnLinkClick?: boolean;
  options?: ToastOptions<{}>;
} & ToastMessageProps;

export function queueToast({ closeOnLinkClick, options, message, ...other }: QueueToastProps) {
  return toast(({ closeToast }) => {
    if (other.link?.onClick && closeToast && closeOnLinkClick) {
      const onClick = other.link.onClick;
      other.link.onClick = () => {
        onClick();
        closeToast();
      };
    }
    return (
      <Organisms.Message {...other} onClose={closeToast}>
        {message}
      </Organisms.Message>
    );
  }, options);
}

export type LinkToastProps = {
  label: string;
  onClick: () => void;
  disabled?: boolean;
};

/**
 * Fires off a toast message depending on the status code
 * @param status The http status code
 * @param title The title of the message
 * @param detailsError string to display if status is error.
 * @param detailsSuccess string to display if status is success.
 * @param link optional link to display in the message
 */
export function ToastByStatus(
  status: number,
  title: string,
  detailsError?: string,
  detailsSuccess?: string,
  link?: LinkToastProps,
  options?: ToastOptions<{}>
) {
  if (status >= 200 && status < 400) {
    if (!detailsSuccess) return;
    queueToast({ headline: title, message: detailsSuccess, flag: ToastMessageType.Checked, link, options });
  } else {
    if (!detailsError) return;
    queueToast({ headline: title, message: detailsError, flag: ToastMessageType.Error, link, options });
  }
}
