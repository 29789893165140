// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import React, { useCallback } from 'react';
import { Atoms, Organisms } from '@tls/treact-ui';
import { ProjectVersion, workingCopy } from 'model/ProjectMetaMessageExtensions';
import sortBy from 'lodash/sortBy';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks';

import { Selection } from '@tls/treact-ui/lib/components/Atoms/Checkbox/Checkbox/constants';

const Table = Organisms.Table;
export type Props = {
  versions: ProjectVersion[];
  selected: ProjectVersion[];
  resetAll: () => void;
  onClick: (version: ProjectVersion) => () => void;
  disableUnselected: boolean;
  loading: boolean;
};

export default function VersionSelectionTable({
  versions,
  selected,
  resetAll,
  disableUnselected,
  onClick,
  loading,
}: Readonly<Props>) {
  const onSortBy = useCallback((array: ProjectVersion[]) => sortBy(array, ['major', 'minor', 'patch']), []);

  const { t } = useTranslation();

  let headerSelectionState: Selection = 'unselected';
  if (selected.length > 0 && selected.length === versions.length) {
    headerSelectionState = 'selected';
  } else if (selected.length > 0) {
    headerSelectionState = 'mixed';
  }

  const isActive = useAppSelector(s => s.license.contract?.isActive ?? false);

  return (
    <Table list={versions}>
      {versionList => (
        <Table.Content>
          <Table.Header>
            <Table.HeaderRow>
              <Table.HeaderCell width={'3.5rem'} alignment='CENTER' noIndent>
                <Atoms.Checkbox
                  asAtom
                  value={headerSelectionState}
                  onValueChange={resetAll}
                  disabled={selected.length === 0}
                />
              </Table.HeaderCell>
              <Table.HeaderSortCell width={'1'} sortKey='version' sortFn={onSortBy} noIndent>
                {t('project.evaluation.versions.title')}
              </Table.HeaderSortCell>
            </Table.HeaderRow>
          </Table.Header>
          <Table.Body>
            <ScrollableContainer>
              {loading && <Atoms.Loader label='' />}
              {versionList.map((versionString, index) => {
                const isSelected = selected.findIndex(s => s === versionString) > -1;
                const hideWorkingCopy = versionString === workingCopy && !isActive;
                const disabled = (disableUnselected && !isSelected) || hideWorkingCopy;
                return (
                  <Table.Row
                    className={isSelected ? 'selected' : ''}
                    key={versionString}
                    index={index}
                    onClick={onClick(versionString)}
                    disabled={disabled}
                  >
                    <Table.Cell width={'3.5rem'} alignment='CENTER' noIndent>
                      <Atoms.Checkbox
                        value={isSelected ? 'selected' : 'unselected'}
                        onValueChange={onClick(versionString)}
                        asAtom
                        disabled={disabled}
                      />
                    </Table.Cell>
                    <Table.Cell width={'1'}>{t(versionString ?? '')}</Table.Cell>
                  </Table.Row>
                );
              })}
            </ScrollableContainer>
          </Table.Body>
        </Table.Content>
      )}
    </Table>
  );
}

const ScrollableContainer = styled.div`
  overflow-y: auto;
  height: 100%;
`;
