// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
//import loadImageIntoElement from 'components/treactui-template/meta/loadImageIntoElement';
import { get } from '@tls/react-saf-ui';
import { blobToBase64 } from 'components/treactui-template/meta/loadImageIntoElement';

export function resetMask(): void {
  document.body.dispatchEvent(new CustomEvent('resetmask', { bubbles: true }));
}

export function exportMask(): Promise<File | undefined> {
  return new Promise<File | undefined>(resolve => {
    const getMask = e => {
      const mask = e.detail;
      const dataUrl = mask?.dataUrl as string;
      const blob = base64ToBlob(dataUrl.split(',')[1]);
      const filename = 'mask.png';
      resolve(new File([blob], filename, { type: 'image/png' }));

      document.removeEventListener('maskexported', getMask);
    };

    document.addEventListener('maskexported', getMask);

    document.body.dispatchEvent(new CustomEvent('export', { detail: { format: 'png' }, bubbles: true }));
  });
}

export function setMask(maskUrl: string): Promise<boolean> {
  return new Promise<boolean>(resolve => {
    async function getAndDrawMask() {
      const { data, status } = await get(maskUrl, false, false, {
        responseType: 'blob',
      });

      if (status < 200 || status >= 400) {
        resolve(false);
        return;
      }

      try {
        const base64 = await blobToBase64(data);
        document.body.dispatchEvent(
          new CustomEvent('maskimported', {
            detail: {
              imageData: base64,
              title: 'Image',
            },
            bubbles: true,
          })
        );
        resolve(true);
      } catch {
        resolve(false);
      }
    }

    getAndDrawMask();
  });
}

export function undoMask() {
  document.body.dispatchEvent(
    new CustomEvent('undomask', {
      bubbles: true,
    })
  );
}

export function redoMask() {
  document.body.dispatchEvent(
    new CustomEvent('redomask', {
      bubbles: true,
    })
  );
}

function base64ToBlob(base64: string) {
  // use atob() to decode base64 encoded string
  const bin = atob(base64);

  const buffer = new Uint8Array(bin.length);
  for (let i = 0; i < bin.length; i++) {
    buffer[i] = bin.charCodeAt(i);
  }
  const blob = new Blob([buffer.buffer], { type: 'image/png' });
  return blob;
}
