// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { IconProps } from '@tls/treact-ui/lib/components/Icons/types';
import React from 'react';

const IconImage = ({ id, width, height, viewBox, strokeWidth, stroke, fill, ...other }: IconProps) => {
  return (
    <svg
      id={id || 'svg-icon-filter'}
      data-name='svg-icon-filter'
      data-testid='svg-icon-filter'
      xmlns='http://www.w3.org/2000/svg'
      width={width || '2.5rem'}
      height={height || '2.55rem'}
      viewBox={viewBox || '0 0 36 36'}
      strokeWidth={strokeWidth || '0'}
      stroke={stroke || 'currentColor'}
      fill={fill || 'currentColor'}
      {...other}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M35 4.5V31.5H1V4.5H35ZM31.6 7.875H4.4V28.125L12.9 16.3125L21.4 28.125L26.5 21.375L31.6 28.125V7.875ZM23.95 11.25C25.378 11.25 26.5 12.3638 26.5 13.7812C26.5 15.1987 25.378 16.3125 23.95 16.3125C22.522 16.3125 21.4 15.1987 21.4 13.7812C21.4 12.3638 22.522 11.25 23.95 11.25Z'
        fill={fill}
      />
    </svg>
  );
};

export default IconImage;
