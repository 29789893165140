// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import styled from 'styled-components';
import React, { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Atoms, COLORS, Molecules } from '@tls/treact-ui';
import { setZoomLevel } from 'store/reducers/projectToolSlice';
import IconMagnifyingGlassMinus from 'components/treactui-template/icons/IconMagnifyingGlassMinus';
import IconMagnifyingGlassPlus from 'components/treactui-template/icons/IconMagnifyingGlassPlus';
import IconScannerViewMaximize from 'components/treactui-template/icons/IconScannerViewMaximize';

const STEP_SIZE = 0.25;
export default function CanvasZoom() {
  const dispatch = useAppDispatch();
  const zoomLevel = useAppSelector(s => s.tools.zoomLevel);

  const handleZoomChange = useCallback(
    e => {
      if (!e?.detail?.scale) return;
      dispatch(setZoomLevel(e.detail.scale));
    },
    [dispatch]
  );

  useEffect(() => {
    document.addEventListener('scalechanged', handleZoomChange);

    return () => {
      document.removeEventListener('scalechanged', handleZoomChange);
    };
  }, [handleZoomChange]);

  const resetZoom = useCallback(() => {
    document.dispatchEvent(new CustomEvent('reset', { bubbles: true }));
  }, []);

  const maximize = () => {
    document.dispatchEvent(new CustomEvent('zoomchanged', { bubbles: true, detail: { scale: zoomLevel + STEP_SIZE } }));
  };

  const setZoom = (option: { id: number }) => {
    document.dispatchEvent(new CustomEvent('zoomchanged', { bubbles: true, detail: { scale: option.id / 100 } }));
  };

  const minimize = () => {
    document.dispatchEvent(
      new CustomEvent('zoomchanged', { bubbles: true, detail: { scale: Math.max(0.01, zoomLevel - STEP_SIZE) } })
    );
  };

  return (
    <BottomRight>
      <Atoms.TransparentButton
        /* @ts-expect-error: Component untyped */
        icon={<IconMagnifyingGlassMinus />}
        noShadow
        noBorder
        asAtom
        onClick={minimize}
      />
      <Molecules.DropdownHeader
        className='dropdown'
        label=''
        noBorder
        selectedOption={{ id: zoomLevel * 100 }}
        keySelector={e => e.id}
        onChange={setZoom}
        labelSelector={e => `${e.id.toFixed(0)} %`}
        options={[{ id: 100 }, { id: 150 }, { id: 200 }, { id: 250 }, { id: 300 }]}
      />
      <Atoms.TransparentButton
        /* @ts-expect-error: Component untyped */
        icon={<IconMagnifyingGlassPlus />}
        noShadow
        noBorder
        asAtom
        onClick={maximize}
      />
      <Atoms.TransparentButton
        /* @ts-expect-error: Component untyped */
        icon={<IconScannerViewMaximize />}
        noShadow
        noBorder
        asAtom
        onClick={resetZoom}
      />
    </BottomRight>
  );
}

const BottomRight = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba(${COLORS.trwhite.rgb}, 0.8);
  padding: 0.125rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .dropdown {
    height: 2.5rem;
    background-color: transparent;
  }
`;
