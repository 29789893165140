// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { Atoms, Molecules } from '@tls/treact-ui';
import { Icons } from '@tls/treact-ui-templates';
import { OrderType } from 'model/sorter';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { OrderOption, updateDashboardOrder, updateDashboardOrderBy } from 'store/reducers/uiSettingsSlice';
import styled from 'styled-components';

const { IconOrderDown, IconOrderUp } = Icons;
const options: OrderOption[] = [
  {
    id: OrderType.Name,
    label: 'project.name',
  },
  {
    id: OrderType.ModifiedDate,
    label: 'project.modifiedDate',
  },
  {
    id: OrderType.Status,
    label: 'project.status.title',
  },
];

export default function SortBy() {
  const { t } = useTranslation();

  const orderBy = useAppSelector(s => s.uiSettings.oderBy);
  const order = useAppSelector(s => s.uiSettings.order);

  const orderIcon = order === 'desc' ? <IconOrderDown height='1rem' /> : <IconOrderUp height='1rem' />;
  const dispatch = useAppDispatch();

  const onChange = useCallback((o: OrderOption) => dispatch(updateDashboardOrderBy(o.id)), [dispatch]);
  const updateOder = useCallback(
    () => dispatch(updateDashboardOrder(order === 'asc' ? 'desc' : 'asc')),
    [dispatch, order]
  );

  const getLabel = useCallback(l => t(l.label), [t]);

  if (options.length < 1) {
    return <React.Fragment />;
  }

  const selected = options.find(o => o.id === orderBy) ?? options[0];

  return (
    <SortByContainer>
      <IconContainer>
        <Atoms.Button onClick={updateOder} icon={orderIcon} variant='secondary' />
      </IconContainer>
      <Molecules.DropdownContent
        label={t('SortBy')}
        keySelector={getId}
        labelSelector={getLabel}
        onChange={onChange}
        options={options ?? []}
        selectedOption={selected}
      />
    </SortByContainer>
  );
}

const SortByContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  vertical-align: middle;
`;

const IconContainer = styled.div`
  margin: auto 0.5rem 1.5rem 0;
  height: 2.5rem;
  border: none;
  background-color: transparent;
  padding: 0;

  @media (hover: hover) {
    &:hover {
      color: ${({ theme }) => theme.trcolor1.hex};
    }
  }

  &.selected {
    color: ${({ theme }) => theme.trcolor1.hex};
  }
`;

function getId(option: OrderOption) {
  return option.id;
}
