// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { UxcAppNotificationList, UxcAppSidebarNotifications } from '@trumpf/ux.application-framework-react';
import { initMyTrumpfNotificationBackend } from '@trumpf/ux.application-framework';
import { getAuthClient } from '@trumpf/ux.utils';
import { useSafContext } from '@tls/react-saf-ui';
import React, { useEffect } from 'react';

export default function NotificationSidebar() {
  const { authService } = useSafContext();

  useEffect(() => {
    if (!authService?.subscribeTokenChange) return;

    const subscriptionId = authService.subscribeTokenChange(data => {
      const client = getAuthClient();
      client.setTokenData({
        access_token: data.access_token,
        expires_in: data.expires_in ?? 3600,
        refresh_token: data.refresh_token,
        id_token: data.id_token,
        token_type: data.token_type,
      });
    });

    return () => {
      if (!authService.unsubscribeTokenChange) return;
      authService.unsubscribeTokenChange(subscriptionId);
    };
  }, [authService]);

  useEffect(() => {
    initMyTrumpfNotificationBackend();
  }, []);

  return (
    <UxcAppSidebarNotifications>
      <UxcAppNotificationList />
    </UxcAppSidebarNotifications>
  );
}
