// SPDX-FileCopyrightText: 2024 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import React, { useCallback, useEffect, useRef } from 'react';
import { Atoms, COLORS, Molecules } from '@tls/treact-ui';
import IconScannerViewMaximize from 'components/treactui-template/icons/IconScannerViewMaximize';
import IconMagnifyingGlassMinus from 'components/treactui-template/icons/IconMagnifyingGlassMinus';
import IconMagnifyingGlassPlus from 'components/treactui-template/icons/IconMagnifyingGlassPlus';
import styled from 'styled-components';

const panzoom = (self as any).panzoom;

export type Props = {
  imgCanvasRef: React.RefObject<HTMLCanvasElement>;
  maskCanvasRef: React.RefObject<HTMLCanvasElement>;
  parentRef: React.RefObject<HTMLDivElement>;
};
const STEP_SIZE = 0.5;
const zoomLevels = [1, 1.5, 2, 3];

function resetZoom(zoom) {
  if (!zoom) return;
  zoom.off();
  zoom.dispose();
}

function initializePanZoom(canvasRef: React.RefObject<HTMLCanvasElement>, onZoom?: (zoomLevel: number) => void) {
  if (!canvasRef.current) return;

  const zoom = panzoom(canvasRef.current, {
    smoothScroll: true,
    initialZoom: 1,
    minZoom: 1,
    zoomDoubleClickSpeed: 1,
  });

  if (!onZoom) return zoom;

  zoom.on('zoom', () => {
    onZoom(zoom.getTransform().scale);
  });

  return zoom;
}

export default function ZoomControls({ imgCanvasRef, maskCanvasRef, parentRef }: Readonly<Props>) {
  const [zoomLevel, setZoomLevel] = React.useState(1);

  const panZoomImage = useRef<any>();
  const panZoomMask = useRef<any>();

  useEffect(() => {
    resetZoom(panZoomImage.current);
    panZoomImage.current = initializePanZoom(imgCanvasRef, setZoomLevel);

    resetZoom(panZoomMask.current);
    panZoomMask.current = initializePanZoom(maskCanvasRef);
  }, [imgCanvasRef, maskCanvasRef]);

  const zoomAbs = useCallback(
    (level: number) => {
      if (!parentRef.current) return;
      panZoomImage?.current.zoomAbs(parentRef.current.offsetWidth / 2, parentRef.current.offsetHeight / 2, level);
      panZoomMask?.current.zoomAbs(parentRef.current.offsetWidth / 2, parentRef.current.offsetHeight / 2, level);
    },
    [parentRef]
  );

  useEffect(() => {
    zoomAbs(zoomLevel);
  }, [zoomLevel, zoomAbs]);

  const onZoomChange = useCallback(
    (diff: number) => () => {
      setZoomLevel(zl => zl + diff);
    },
    []
  );

  const reset = useCallback(() => {
    zoomAbs(1);
    panZoomImage?.current.moveTo(0, 0);
    panZoomMask?.current.moveTo(0, 0);
    setZoomLevel(1);
  }, [zoomAbs]);

  return (
    <BottomRight>
      <Atoms.TransparentButton
        /* @ts-expect-error: Component untyped */
        icon={<IconMagnifyingGlassMinus />}
        noShadow
        noBorder
        asAtom
        onClick={onZoomChange(-STEP_SIZE)}
      />
      <Molecules.DropdownHeader
        className='dropdown'
        label=''
        noBorder
        options={zoomLevels.map(level => ({ id: level, label: `${level * 100}%` }))}
        selectedOption={{ id: Math.round(zoomLevel * 100), label: `${Math.round(zoomLevel * 100)}%` }}
        onChange={({ id }) => setZoomLevel(id)}
        keySelector={option => option.id}
        labelSelector={option => option.label}
      />
      <Atoms.TransparentButton
        /* @ts-expect-error: Component untyped */
        icon={<IconMagnifyingGlassPlus />}
        noShadow
        noBorder
        asAtom
        onClick={onZoomChange(STEP_SIZE)}
      />
      <Atoms.TransparentButton
        /* @ts-expect-error: Component untyped */
        icon={<IconScannerViewMaximize />}
        noShadow
        noBorder
        asAtom
        onClick={reset}
      />
    </BottomRight>
  );
}

const BottomRight = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba(${COLORS.trwhite.rgb}, 0.8);
  padding: 0.125rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .dropdown {
    height: 2.5rem;
    background-color: transparent;
  }
`;
