// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TooltipItem,
  ActiveElement,
  Chart,
  ChartEvent,
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Scatter } from 'react-chartjs-2';
import styled from 'styled-components';
import { Atoms, COLORS } from '@tls/treact-ui';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setActiveMd5, setHoveredMd5s, setZoomLevel, ZoomLevel } from 'store/reducers/projectEvaluationSlice';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import DebounceSet from 'components/common/DebounceSet';
import useModelEvaluationApi, { IdWithValue } from 'hooks/useModelEvaluationApi';
import { Coordinate, useDefaultDataSet, useDefaultOptions } from './defaults';
import { SetBoxPlotOptions, setHighlightedData } from './helpers';
import { EvaluationContext } from '../EvaluationContext';
import { ProjectVersion } from 'model/ProjectMetaMessageExtensions';
import Backdrop from 'components/treactui-template/organisms/backdrop/Backdrop';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin,
  zoomPlugin
);

export type Props = {
  projectId: string;
  modelId: ProjectVersion;
  evaluationId?: string;
};

export default function ModeLBoxPlot({ projectId, modelId, evaluationId }: Props) {
  const { t, i18n } = useTranslation();

  const data = useDefaultDataSet();

  const options = useDefaultOptions();

  const hoveredMd5s = useAppSelector(s => s.evaluation.hoveredMd5s);
  const zoomLevel = useAppSelector(s => s.evaluation.zoomLevel);
  const dispatch = useAppDispatch();

  const ref = useRef<ChartJSOrUndefined<'scatter', Coordinate[], string>>(null);

  const { evaluation, boxPlot, progress } = useModelEvaluationApi(projectId, modelId, evaluationId);
  const evalRef = useRef<IdWithValue[]>([]);

  const activeMd5 = useAppSelector(s => s.evaluation.activeMd5);
  const [hoveredMd5sToShow, setHoveredMd5sToShow] = useState<string[]>([]);
  DebounceSet(hoveredMd5s, setHoveredMd5sToShow);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    //The images of the other charts might be in different order, find the correct idx.
    const evaluationIdx = evalRef.current.map((e, idx) => ({ e, idx }));
    const hoveredIdxs = evaluationIdx.filter(e => hoveredMd5sToShow.includes(e.e.imageMd5)).map(e => e.idx);

    const activeIdx = evaluationIdx.find(e => e.e.imageMd5 === activeMd5)?.idx ?? undefined;

    setHighlightedData(data.current, hoveredIdxs, activeIdx);
    ref.current?.update();
  }, [data, hoveredMd5sToShow, activeMd5, initialized]);

  const [zoomLevelToShow, setZoomLevelToShow] = useState<ZoomLevel>();
  DebounceSet(zoomLevel, setZoomLevelToShow);
  useEffect(() => {
    if (ref.current?.options.scales?.y === undefined) return;
    ref.current.options.scales.y.max = zoomLevelToShow?.max ?? 1;
    ref.current.options.scales.y.min = zoomLevelToShow?.min ?? 0;
    ref.current.update();
  }, [zoomLevelToShow]);

  const renderLabel = useCallback(
    (i: TooltipItem<'scatter'>) => {
      return `${t('project.label.training.chart.iou')}: ${(i.raw as Coordinate).y?.toLocaleString(i18n.language, {
        maximumFractionDigits: 2,
      })}`;
    },
    [i18n.language, t]
  );

  const onHover = useCallback(
    (_, elements: ActiveElement[]) =>
      dispatch(
        setHoveredMd5s(
          elements
            .map(e => {
              if (e.index < 0 || e.index >= evalRef.current.length) return '';
              return evalRef.current[e.index].imageMd5;
            })
            .filter(e => e)
        )
      ),
    [dispatch]
  );

  const onZoom = useCallback(
    (context: { chart: Chart }) => {
      dispatch(setZoomLevel({ min: context.chart.scales.y.min, max: context.chart.scales.y.max }));
    },
    [dispatch]
  );

  const { index, updateIndex, updateLength } = useContext(EvaluationContext);
  useEffect(() => {
    updateLength(evaluation.length);
  }, [evaluation.length, updateLength]);

  const onClick = useCallback(
    /* eslint-disable @typescript-eslint/no-unused-vars */
    (event: ChartEvent, elements: ActiveElement[], chart: Chart) => {
      if (elements.length < 1) return;
      const closest = elements[0];

      updateIndex(closest.index);
      if (!evalRef.current[closest.index]) return;

      dispatch(setActiveMd5(evalRef.current[closest.index].imageMd5));
    },
    [dispatch, updateIndex]
  );

  useEffect(() => {
    if (index < 0 || index > evalRef.current.length) return;

    const current = evalRef.current[index]?.imageMd5;
    if (current && current === activeMd5) return;

    dispatch(setActiveMd5(current));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, dispatch]);

  useEffect(() => {
    const currentIndex = evalRef.current.findIndex(e => e.imageMd5 === activeMd5);

    if (currentIndex < 0 || currentIndex === index) return;
    updateIndex(currentIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMd5, dispatch]);

  useEffect(() => {
    if (!options.current) return;

    if (ref.current?.options) {
      ref.current.options.onHover = onHover;
      ref.current.options.onClick = onClick;
    }
    if (options.current.plugins?.tooltip?.callbacks) options.current.plugins.tooltip.callbacks.label = renderLabel;
    if (options.current.plugins?.zoom?.zoom) options.current.plugins.zoom.zoom.onZoom = onZoom;
    if (options.current.plugins?.zoom?.pan) options.current.plugins.zoom.pan.onPan = onZoom;
    if (options.current.scales?.y?.title) options.current.scales.y.title.text = t('project.label.training.chart.iou');
    if (options.current.scales?.y?.ticks)
      options.current.scales.y.ticks.callback = tickValue => tickValue.toLocaleString(i18n.language);

    ref.current?.update();
  }, [i18n.language, onClick, onHover, onZoom, options, renderLabel, t]);

  useEffect(() => {
    if (evaluation.length < 1 || !boxPlot) return;

    //Only add newest data and reset highlight
    evaluation.forEach(e => {
      //Skip duplicate entries
      if (evalRef.current.some(r => r.imageMd5 === e.imageMd5)) return;

      evalRef.current.push(e);
      data.current.datasets[0].data.push({ x: 0, y: e.value });
    });

    setHighlightedData(data.current, []);

    SetBoxPlotOptions(boxPlot, options.current);

    ref.current?.update();
    setInitialized(true);
  }, [evaluation, boxPlot, data, options, onHover]);

  return (
    <Container className='chart-container'>
      <Scatter className='chart' options={options.current} data={data.current} ref={ref} />
      <Backdrop showBackdrop={progress < 1} width={75}>
        <Atoms.ProgressBar percentage={progress * 100} width={100} />
      </Backdrop>
    </Container>
  );
}
const Container = styled.div`
  position: relative;
  height: calc(100% - 2.5rem);
  width: 100%;
  .chart {
    background-color: #f3f5f7;
    border: 1px solid ${COLORS.trgrey5.hex};
  }
`;
