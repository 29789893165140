// SPDX-FileCopyrightText: 2024 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { Atoms, ICONS } from '@tls/treact-ui';
import Search from 'components/header/Search';
import PageHeader from 'components/treactui-template/organisms/page/PageHeader';
import PageWrapper from 'components/treactui-template/organisms/page/PageWrapper';
import useMediaQuery, { DefaultBreakpoints } from 'hooks/useMediaQuery';
import FilterGridContainer, {
  ActionButtonShell,
  PageTitleShell,
  SearchShell,
  SecondRowShell,
} from 'layout/header/FilterGridContainer';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { AppRoutes } from 'utilities/router/AppRoutes';

const { IconPlus, IconMoveLeft } = ICONS;

export default function Settings() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { width } = useMediaQuery();
  let filterActions = (
    <SearchShell>
      <Search />
    </SearchShell>
  );

  if (width < DefaultBreakpoints.md) {
    filterActions = <SecondRowShell key='second-row'>{filterActions}</SecondRowShell>;
  }

  const returnUrl = useAppSelector(a => a.uiSettings.returnUrl) ?? AppRoutes.Projects;

  let addButton: ReactElement | undefined = undefined;
  const { pathname } = useLocation();
  if (pathname === AppRoutes.SettingsUser) {
    addButton = (
      <ActionButtonShell>
        <Atoms.Button
          label={t('settings.users.add')}
          icon={<IconPlus />}
          onClick={() => navigate(AppRoutes.SettingsAddUser)}
        />
      </ActionButtonShell>
    );
  } else if (pathname === AppRoutes.SettingsRole) {
    addButton = (
      <ActionButtonShell>
        <Atoms.Button
          label={t('settings.roles.add')}
          icon={<IconPlus />}
          onClick={() => navigate(AppRoutes.SettingsAddRole)}
        />
      </ActionButtonShell>
    );
  }

  let title = t('settings.users.title');
  if (pathname === AppRoutes.SettingsAddUser) {
    title = t('settings.users.add');
  } else if (pathname === AppRoutes.SettingsContracts) {
    title = t('settings.contracts.title');
  } else if (pathname === AppRoutes.SettingsRole) {
    title = t('settings.roles.title');
  } else if (pathname === AppRoutes.SettingsAddRole) {
    title = t('settings.roles.add');
  }

  return (
    <PageWrapper>
      <PageHeader>
        <FilterGridContainer
          pxBreakpoint={DefaultBreakpoints.md}
          showActionButton={addButton !== undefined}
          showView={false}
          showFilter={false}
        >
          <PageTitleShell>
            <Title>
              <Atoms.TransparentButton
                /* @ts-expect-error: Missing-types  */
                noShadow
                noBorder
                asAtom
                icon={<IconMoveLeft />}
                onClick={() => navigate(returnUrl)}
                iconWidth='2.25'
                iconHeight='2.25'
              />
              <span className='font-size_l'>{title}</span>
            </Title>
          </PageTitleShell>
          {addButton}
          {filterActions}
        </FilterGridContainer>
      </PageHeader>
      <Outlet />
    </PageWrapper>
  );
}

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
