// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import React, { useRef, useEffect, useContext, RefObject } from 'react';
import { PageContext } from './PageContext';
import { NavbarContext } from './NavbarContext';

function ObserveResize(setHeight: (height: number) => void, ref?: RefObject<HTMLElement>) {
  useEffect(() => {
    if (!ref?.current) return;

    const resizeObserver = new ResizeObserver(e => {
      if (e.length !== 1 || !e[0].target) return;
      setHeight(e[0].target.scrollHeight + 1);
    });
    resizeObserver.observe(ref.current);
    return () => resizeObserver.disconnect(); // clean up
  }, [ref, ref?.current?.clientHeight, setHeight]);
}

export default function PageHeader({ children }: React.PropsWithChildren) {
  const { setHeaderHeight, setNavHeight } = useContext(PageContext);
  const { navbarRef: NavbarRef } = useContext(NavbarContext);
  const ref = useRef<HTMLDivElement>(null);

  ObserveResize(setHeaderHeight, ref);
  ObserveResize(setNavHeight, NavbarRef);

  return <section ref={ref}>{children}</section>;
}
