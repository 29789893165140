// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { put } from '@tls/react-saf-ui';
import { useState } from 'react';

export default function useUploadApi(projectId: string) {
  const [uploading, setUploading] = useState(false);

  async function uploadFile(original: File, previewImage: File) {
    setUploading(true);
    const formData = new FormData();
    formData.append('uploadedFiles', original);
    formData.append('uploadedFiles', previewImage);

    const result = await put(`projects/${projectId}/labels/image`, formData, false, false, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    setUploading(false);
    return result;
  }

  async function uploadMask(mask: File, imageMd5: string) {
    setUploading(true);
    const formData = new FormData();
    formData.append('uploadedFile', mask);

    const result = await put(`projects/${projectId}/labels/${imageMd5}/mask`, formData, false, false, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    setUploading(false);
    return result;
  }

  async function uploadPreviewImage(preview: File) {
    setUploading(true);
    const formData = new FormData();
    formData.append('uploadedFile', preview);

    const result = await put(`projects/${projectId}/previewImage`, formData, false, false, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    setUploading(false);
    return result;
  }

  return { loading: uploading, uploadFile, uploadMask, uploadPreviewImage };
}
