// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF

import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';

export function i18nextInit(language: string, appBaseUrl: string) {
  return i18next
    .use(HttpBackend)
    .use(initReactI18next)
    .init(
      {
        returnNull: false,
        lng: language,
        fallbackLng: 'en',
        interpolation: {
          escapeValue: false,
        },
        ns: ['translation'],
        keySeparator: '.',
        defaultNS: 'translation',
        load: 'currentOnly',
        react: {
          useSuspense: true,
          bindI18n: 'languageChanged added loaded',
          bindI18nStore: 'added removed',
          nsMode: 'default',
        },
        backend: {
          loadPath: `${appBaseUrl}/locales/{{lng}}/{{ns}}.json`,
          requestOptions: {
            mode: 'cors',
            credentials: 'same-origin',
            cache: 'reload',
          },
        },
      },
      error => {
        if (error) console.error(error);
      }
    );
}
