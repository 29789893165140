// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF

import TelemetrySettings from 'model/TelemetrySettings';
import { get } from '@tls/react-saf-ui';
import { useQuery } from '@tanstack/react-query';

export default function useTelemetryApi() {
  const { data, isLoading, status } = useQuery<TelemetrySettings>({
    queryKey: ['telemetry'],
    queryFn: async () => await get('telemetry', false),
  });

  return { data: data, isLoading, lastStatus: status };
}
