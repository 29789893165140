// SPDX-FileCopyrightText: 2024 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { Organisms } from '@tls/treact-ui';
import { ThreeSectorContent, TwoRowCell } from '@tls/treact-ui-templates';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import RoleDetails from './roles/RoleDetails';
import PageContent from 'components/treactui-template/organisms/page/PageContent';
import SelectRole from './roles/SelectRole';
import useMediaQuery from 'hooks/useMediaQuery';
import useRolesApi from 'hooks/useRolesApi';
import useFilteredRoles from 'hooks/useFilteredRoles';
import { Role } from 'model/Role';

export default function Roles() {
  const { roles, loadingRoles, deleteRole, editRole, permissions } = useRolesApi();
  const { t } = useTranslation();

  const filteredRoles = useFilteredRoles(roles);

  const renderTableRowCells = useCallback((role: Role) => {
    return [
      <Organisms.Table.Cell key='Role' width='15rem'>
        <TwoRowCell row1={role.name} row2={role.permissions?.join(' ,')} row1Bold />
      </Organisms.Table.Cell>,
    ];
  }, []);

  const { isMedium, isSmall, isExtraSmall } = useMediaQuery();

  return (
    <PageContent displayAsPaper disableScroll>
      <ThreeSectorContent
        isSmallWidth={isMedium || isSmall || isExtraSmall}
        selectionAdapters={[
          {
            listElementKeySelector: (role: Role) => role.id ?? '',
            menuLabel: 'role',
            tableHeaderCells: [
              <Organisms.Table.HeaderCell key='Role' width='15rem'>
                {t('role.roleName')}
              </Organisms.Table.HeaderCell>,
            ],
            listElements: filteredRoles ?? [],
            renderTableRowCells: renderTableRowCells,
            renderDetail: (listElement: Role, handleBack) => {
              if (!listElement) return <SelectRole />;
              return (
                <RoleDetails
                  role={listElement}
                  roles={roles}
                  isLoading={loadingRoles}
                  addOrEditRole={editRole}
                  deleteRole={deleteRole}
                  onClose={isMedium || isSmall || isExtraSmall ? handleBack : undefined}
                  permissions={permissions}
                  isBuiltInRole={listElement.isBuiltIn}
                />
              );
            },
          },
        ]}
      />
    </PageContent>
  );
}
