// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { useEffect, useRef } from 'react';

export default function DebounceSet<T>(selectedIds: T | undefined, setSelectedIdsToShow: (value: T) => void) {
  const latestValue = useRef<T | undefined>(undefined);
  const debounceLink = useRef<NodeJS.Timeout | undefined>(undefined);
  useEffect(() => {
    latestValue.current = selectedIds;

    if (debounceLink.current) return;
    debounceLink.current = setTimeout(() => {
      debounceLink.current = undefined;

      if (!latestValue.current) return;
      setSelectedIdsToShow(latestValue.current);
    }, 80);
  }, [selectedIds, setSelectedIdsToShow]);
}
