// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ToolMode, PencilType, ToolType } from 'model/Tools';
import { loadStoreStateFromLocalStorage } from 'store/rehydrate';

export interface ToolState {
  toolType: ToolType;
  pencilType: PencilType;
  pencilWidth: number;
  pencilWidthStep: number;
  mode: ToolMode;
  transparency: number;
  zoomLevel: number;
  artifactDetection: boolean;
}

const initialState: ToolState = loadStoreStateFromLocalStorage(l => l?.tools, {
  toolType: ToolType.Pencil,
  pencilType: PencilType.Circle,
  pencilWidth: 55,
  mode: ToolMode.Draw,
  transparency: 50,
  artifactDetection: true,
  pencilWidthStep: 1,
  zoomLevel: 1,
} as ToolState);

export const deleteProjectSlice = createSlice({
  name: 'tool',
  initialState,
  reducers: {
    changeToolType: (state, action: PayloadAction<ToolType>) => {
      state.toolType = action.payload;
      if (state.toolType !== ToolType.Pencil) state.mode = ToolMode.Draw;
    },
    changePencilToolType: (state, action: PayloadAction<PencilType>) => {
      if (state.toolType === ToolType.Pencil) state.pencilType = action.payload;
    },
    changePencilWidth: (state, action: PayloadAction<number>) => {
      if (state.toolType !== ToolType.Pencil) return;
      if (action.payload < 0.001) state.pencilWidth = 0.001;
      else if (action.payload > 100) state.pencilWidth = 100;
      else state.pencilWidth = action.payload;
    },
    changePencilWidthStep: (state, action: PayloadAction<number>) => {
      state.pencilWidthStep = action.payload;
    },
    changeToolMode: (state, action: PayloadAction<ToolMode>) => {
      state.mode = action.payload;
      if (state.mode === ToolMode.Erase) state.toolType = ToolType.Pencil;
    },
    setTransparency: (state, action: PayloadAction<number>) => {
      if (action.payload < 0) state.transparency = 0;
      else if (action.payload > 100) state.transparency = 100;
      else state.transparency = action.payload;
    },
    setZoomLevel: (state, action: PayloadAction<number>) => {
      if (action.payload <= 0) {
        state.zoomLevel = 0.001;
      } else {
        state.zoomLevel = action.payload;
      }
    },
    toggleArtifactDetection: state => {
      state.artifactDetection = !state.artifactDetection;
    },
  },
});

export const {
  changePencilToolType,
  changePencilWidth,
  changePencilWidthStep,
  changeToolMode,
  changeToolType,
  setTransparency,
  toggleArtifactDetection,
  setZoomLevel,
} = deleteProjectSlice.actions;
export default deleteProjectSlice.reducer;
