// SPDX-FileCopyrightText: 2024 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { useQuery } from '@tanstack/react-query';
import { get } from '@tls/react-saf-ui';
import { Contract, parseContract } from 'model/Contract';
import { useMemo } from 'react';

export default function useAllContractApi() {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['all-contracts'],
    queryFn: async () => await get('contracts/all', false, true),
  });

  const filteredData: Contract[] | undefined = useMemo(
    () =>
      data?.data?.reduce((pv: Contract[], cv) => {
        const entry = parseContract(cv);

        if (
          entry &&
          !pv.find(
            e =>
              e.contractId === entry.contractId &&
              e.customerNumber === entry.customerNumber &&
              e.endCustomerNumber === entry.endCustomerNumber
          )
        ) {
          pv.push(entry);
        }
        return pv;
      }, []),
    [data?.data]
  );

  return {
    data: filteredData,
    isLoading,
    isError,
  };
}
