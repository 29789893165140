// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import AddProjectButton from 'components/common/AddProjectButton';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export default function NoProjects() {
  const { t } = useTranslation();

  return (
    <Container>
      <span className='font-size_l'>{t('projects.noProjects')}</span>
      <AddProjectButton />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  row-gap: 1rem;
  height: 100%;
`;
