// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { ProjectStatus } from 'model/ProjectMetadata';

export type ProjectStatusAckMessage = {
  projectId?: string;
  status: ProjectStatus;
};

export function isProjectStatusAckMessage(arg): arg is ProjectStatusAckMessage {
  return arg?.projectId !== undefined && arg?.status !== undefined;
}
