// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { get } from '@tls/react-saf-ui';
import { ProjectMetaMessage } from '@tls/sw91-communication/types/com.base';
import { Dictionary } from 'model/Dictionary';
import { ProjectStatus } from 'model/ProjectMetadata';
import { ProjectStatusAckMessage } from 'model/reponse/ProjectStatusAckMessage';
import { loadStoreStateFromLocalStorage } from 'store/rehydrate';

export interface ProjectsState {
  projectsMetadata: ProjectMetaMessage[];
  loading?: boolean;
  lastStatus?: number;
  status: Dictionary<ProjectStatus>;
  lockedBy: Dictionary<string>;
}

type ProjectsFetchState = {
  jsonData: unknown[];
  lastStatus?: number;
  status: Dictionary<ProjectStatus>;
  lockedBy: Dictionary<string>;
};

const initialState: ProjectsState = loadStoreStateFromLocalStorage(l => l?.projects, {
  tags: [],
  projectsMetadata: [],
  status: {},
  jsonData: [],
  lockedBy: {},
} as ProjectsState);

const fetchProjects = createAsyncThunk<ProjectsFetchState, undefined, { rejectValue: ProjectsFetchState }>(
  'fetch-projects',
  async (_, thunkApi) => {
    const getAwaiter = get('projects');
    const statusAwaiter = get('projects/status');
    const lockAwaiter = get('projects/lockStatus');

    const { data, status } = await getAwaiter;
    const { data: statusData } = await statusAwaiter;
    const { data: lockStatusData } = await lockAwaiter;

    const resultData: ProjectsFetchState = {
      lastStatus: status,
      status: {},
      jsonData: [],
      lockedBy: {},
    };

    if (status < 200 || status >= 400) {
      return thunkApi.rejectWithValue(resultData);
    }

    if (statusData && typeof statusData === 'object') {
      resultData.status = statusData;
    }

    if (lockStatusData && typeof lockStatusData === 'object') {
      resultData.lockedBy = lockStatusData;
    }

    resultData.jsonData = data;
    return resultData;
  }
);

export const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    updateProjectStatus: (state, action: PayloadAction<ProjectStatusAckMessage>) => {
      const { projectId, status } = action.payload;
      if (!projectId) return;
      state.status[projectId] = status;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchProjects.pending, state => {
      state.loading = true;
    });
    builder.addCase(fetchProjects.rejected, (state, action) => {
      state.loading = false;
      state.lastStatus = action.payload?.lastStatus ?? 200;
    });
    builder.addCase(fetchProjects.fulfilled, (state, action) => {
      state.loading = false;
      state.projectsMetadata = action.payload.jsonData.map(ProjectMetaMessage.fromJSON);
      state.lastStatus = action.payload.lastStatus;
      state.status = action.payload.status ?? {};
      state.lockedBy = action.payload.lockedBy ?? {};
    });
  },
});

export const { updateProjectStatus } = projectsSlice.actions;
export { fetchProjects };
export default projectsSlice.reducer;
