// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { Atoms, ICONS, Organisms } from '@tls/treact-ui';
import { ToastByStatus } from 'components/treactui-template/organisms/toast/ToastMessage';
import useProjectSelectionApi from 'hooks/useProjectSelectionApi';
import useProjectsMetadata from 'hooks/useProjectsMetadata';
import React, { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { toggleDeleteDialog } from 'store/reducers/projectDeleteSlice';
import { fetchProjects } from 'store/reducers/projectsSlice';
import { AppRoutes } from 'utilities/router/AppRoutes';
const { Dialog } = Organisms;

export default function DeleteProjectDialog() {
  const { t, i18n } = useTranslation();

  const { id, show } = useAppSelector(s => s.deleteProject);
  const { selectedProject } = useProjectsMetadata(id);
  const { projectName, modifyTime } = selectedProject ?? {};
  const { remove, loading } = useProjectSelectionApi();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const deleteProject = useCallback(async () => {
    const { status } = await remove(id ?? '');
    ToastByStatus(
      status,
      t('project.settings.delete.title'),
      t('project.settings.delete.error', { name: projectName }),
      t('project.settings.delete.success', { name: projectName })
    );
    dispatch(fetchProjects());
    dispatch(toggleDeleteDialog());

    if (status === 200) navigate(AppRoutes.ProjectTilesFull);
  }, [dispatch, id, navigate, projectName, remove, t]);

  const onClose = useCallback(() => dispatch(toggleDeleteDialog()), [dispatch]);

  let saveIcon: JSX.Element | undefined = <ICONS.IconLoading />;
  if (!loading) {
    saveIcon = undefined;
  }
  return (
    <Dialog enableClosing open={show} size={'small'} onClose={onClose}>
      <Dialog.DialogHeader label={t('project.settings.delete.title', { name: projectName })} showCloseButton />
      <Dialog.DialogContent>
        <div>
          <Trans
            i18nKey={'project.settings.delete.details'}
            values={{
              name: projectName,
              date: modifyTime?.toLocaleString(i18n.language, {
                dateStyle: 'long',
                timeStyle: 'short',
              }),
            }}
          />
        </div>
      </Dialog.DialogContent>
      <Dialog.Footer
        right={[
          <Atoms.FooterButton key='cancel' label={t('cancel')} onClick={onClose} variant='secondary' />,
          <Atoms.FooterButton
            icon={saveIcon}
            key='save'
            label={t('confirm')}
            disabled={loading}
            onClick={deleteProject}
          />,
        ]}
      />
    </Dialog>
  );
}
