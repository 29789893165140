// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF

import { StoreTypes } from './storeTypes';

export function saveStoreToLocalStorage(state: StoreTypes) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('persistedState:sw91-ui', serializedState);
  } catch (e) {
    console.warn(e);
  }
}

export function loadStoreStateFromLocalStorage(selectSetting: (arg0: StoreTypes) => any, defaultSettings: any) {
  try {
    const serializedState = localStorage.getItem('persistedState:sw91-ui');

    if (serializedState === null) return defaultSettings;

    return { ...defaultSettings, ...(selectSetting(JSON.parse(serializedState)) ?? {}) };
  } catch (e) {
    console.warn(e);
    return {};
  }
}
