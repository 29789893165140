// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { Atoms, Organisms } from '@tls/treact-ui';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import useSettingsApi from './useSettingsApi';
import { DataUsageAgreement } from '@tls/sw91-communication/types/com.base';
import { ToastByStatus } from 'components/treactui-template/organisms/toast/ToastMessage';

export function InitialDataAgreementDialog() {
  const { t } = useTranslation();

  const { dataUsageAgreement, setDataAgreement } = useSettingsApi();

  const [open, setOpen] = useState<boolean>();

  useEffect(() => {
    if (dataUsageAgreement === DataUsageAgreement.UNSPECIFIED_DATA_USAGE_AGREEMENT) {
      setOpen(true);
      setDataAgreement(DataUsageAgreement.R_AND_D).then(result => {
        ToastByStatus(result.status, t('settings.data.initialized'), t('settings.data.initializedError'));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUsageAgreement]);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);
  return (
    <Organisms.Dialog
      open={dataUsageAgreement === DataUsageAgreement.UNSPECIFIED_DATA_USAGE_AGREEMENT || open}
      size={'small'}
      enableClosing
      onClose={onClose}
    >
      <Organisms.Dialog.DialogHeader label={t('settings.data.title')} />
      <Organisms.Dialog.DialogContent>
        <Trans i18nKey={t('settings.data.initialize')} />
      </Organisms.Dialog.DialogContent>
      <Organisms.Dialog.Footer
        right={<Atoms.FooterButton variant='secondary' key='cancel' label={t('close')} onClick={onClose} />}
      />
    </Organisms.Dialog>
  );
}
