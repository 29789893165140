// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { IconProps } from '@tls/treact-ui/lib/components/Icons/types';
import React from 'react';

export default function IconListSettings({
  id,
  width,
  height,
  viewBox,
  strokeWidth,
  stroke,
  fill,
  ...other
}: Readonly<IconProps>) {
  return (
    <svg
      id={id ?? 'svg-list-settings'}
      data-name='svg-list-settings'
      data-testid='svg-list-settings'
      xmlns='http://www.w3.org/2000/svg'
      width={width ?? '2.25rem'}
      height={height ?? '2.25rem'}
      viewBox={viewBox ?? '0 0 25 24'}
      strokeWidth={strokeWidth ?? '0'}
      stroke={stroke ?? 'currentColor'}
      fill={fill ?? 'none'}
      {...other}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.3909 17.0849C21.4394 17.3289 21.4624 17.5489 21.4624 17.7654C21.4624 17.9899 21.4404 18.2194 21.3969 18.4494L23.7499 19.8079L22.4999 21.9729L20.1474 20.6149C19.7884 20.9269 19.4104 21.1524 18.9999 21.2999V23.9999H16.4999V21.3004C16.0484 21.1424 15.6254 20.9044 15.2799 20.6134L12.9249 21.9729L11.6749 19.8079L14.0344 18.4459C13.9854 18.2009 13.9624 17.9814 13.9624 17.7654C13.9624 17.5414 13.9844 17.3119 14.0279 17.0814L11.6749 15.7234L12.9249 13.5579L15.2794 14.9174C15.6249 14.6264 16.0479 14.3884 16.4999 14.2309V11.4999H18.9999V14.2314C19.4094 14.3784 19.7869 14.6044 20.1469 14.9164L22.4999 13.5579L23.7499 15.7234L21.3909 17.0849ZM17.7124 19.7654C18.8154 19.7654 19.7124 18.8684 19.7124 17.7654C19.7124 16.6624 18.8154 15.7654 17.7124 15.7654C16.6094 15.7654 15.7124 16.6624 15.7124 17.7654C15.7124 18.8684 16.6094 19.7654 17.7124 19.7654Z'
        fill='black'
      />
      <path d='M3.7124 4.19409H1.7124V6.19409H3.7124V4.19409Z' fill='black' />
      <path d='M17.7124 4.19409H4.7124V6.19409H17.7124V4.19409Z' fill='black' />
      <path d='M3.7124 9.19409H1.7124V11.1941H3.7124V9.19409Z' fill='black' />
      <path d='M3.7124 14.1941H1.7124V16.1941H3.7124V14.1941Z' fill='black' />
      <path d='M15.4006 9.19409V11.1941H4.7124V9.19409H15.4006Z' fill='black' />
      <path d='M10.7567 16.1941V14.1941H4.7124V16.1941H10.7567Z' fill='black' />
    </svg>
  );
}
