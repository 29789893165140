// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { COLORS } from '@tls/treact-ui';
import React from 'react';
import styled from 'styled-components';

export default function Badge({ display, children, value }: React.PropsWithChildren<Props>) {
  if (!display) return <>{children}</>;

  return (
    <FlagContainer>
      {children}
      <FloatingBadge>{value}</FloatingBadge>
    </FlagContainer>
  );
}

const FlagContainer = styled.span`
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  flex-shrink: 0;
`;

const FloatingBadge = styled.span`
  display: flex;
  flex-flow: row wrap;
  place-content: center;
  align-items: center;
  position: absolute;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 0.75rem;
  width: 20px;
  line-height: 1;
  height: 15px;
  z-index: 4;
  background-color: ${COLORS.trblue4.hex};
  color: ${COLORS.trwhite.hex};
  top: 0px;
  right: 0px;
  transform: scale(1) translate(30%, -30%);
  transform-origin: 100% 0%;
`;

export type Props = {
  value: string | number;
  display: boolean;
};
