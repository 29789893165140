// SPDX-FileCopyrightText: 2023 TRUMPF Laser SE
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { COLORS } from '@tls/treact-ui';
import styled from 'styled-components';
import React from 'react';
import IconLocked from 'components/treactui-template/icons/IconLocked';
import { useSafContext } from '@tls/react-saf-ui';

interface LockedInfoProps {
  lockedBy: string;
}

function isEmail(username: string) {
  return username.includes('@');
}

//regular expression with all non-alphanumeric characters
const nonAlphanumeric = /[^a-zA-Z0-9]/g;

//regular expression with . or _
const dotOrUnderscore = /[._]/g;

export function extractInitialsFromUsername(username: string) {
  if (isEmail(username)) {
    const indexOfAt = username.indexOf('@');
    const initials = username
      .substring(0, indexOfAt)
      .split(dotOrUnderscore)
      .map(part => part[0])
      .join('');

    //take first and last element from initials
    return initials[0] + (initials.length > 1 ? initials[initials.length - 1] : '');
  }

  //Assume it's a trumpf username ex: TLS564FR or TLS564FR_ext
  const cleanedUsername = username.replaceAll(nonAlphanumeric, '');
  const lastIndexOfUnderscore = cleanedUsername.lastIndexOf('_');
  if (lastIndexOfUnderscore === -1) {
    return cleanedUsername.substring(cleanedUsername.length - 2);
  }

  return cleanedUsername.substring(lastIndexOfUnderscore - 2, lastIndexOfUnderscore);
}

export default function LockedInfo({ lockedBy }: Readonly<LockedInfoProps>) {
  const { currentUser } = useSafContext();

  if (!lockedBy) return;
  if (lockedBy === currentUser?.userName) return <IconLocked height={'1.75rem'} width={'2rem'} />;
  return <Container>{extractInitialsFromUsername(lockedBy)}</Container>;
}

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 2.25rem;
  height: 2.25rem;
  font-size: 1.125rem;
  line-height: 1;
  border-radius: 50%;
  overflow: hidden;
  color: #e6e6e6;
  background-color: ${COLORS.trblue4.hex};
  font-weight: 700;
  //Uppercase
  text-transform: uppercase;
`;
