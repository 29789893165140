// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { Atoms, Organisms } from '@tls/treact-ui';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { toggleSettings } from 'store/reducers/uiSettingsSlice';
import useSettingsApi from './useSettingsApi';
import { DataUsageAgreement } from '@tls/sw91-communication/types/com.base';
import { ToastByStatus } from 'components/treactui-template/organisms/toast/ToastMessage';

export function DataAgreementDialog() {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const onClose = useCallback(() => {
    dispatch(toggleSettings());
  }, [dispatch]);
  const open = useAppSelector(s => s.uiSettings.showSettings);

  const { dataUsageAgreement, isLoading, setDataAgreement } = useSettingsApi();
  const [loading, setLoading] = useState(false);
  const onChange = useCallback(async () => {
    setLoading(true);
    const result = await setDataAgreement(
      dataUsageAgreement === DataUsageAgreement.R_AND_D ? DataUsageAgreement.PRIVATE : DataUsageAgreement.R_AND_D
    );
    ToastByStatus(result.status, t('settings.data.title'), t('settings.data.updatedError'));
    setLoading(false);
  }, [dataUsageAgreement, setDataAgreement, t]);

  return (
    <Organisms.Dialog open={open} onClose={onClose} size={'small'} enableClosing>
      <Organisms.Dialog.DialogHeader label={t('settings.data.title')} />
      <Organisms.Dialog.DialogContent>
        <Atoms.Switch
          label={t('settings.data.details')}
          value={dataUsageAgreement === DataUsageAgreement.R_AND_D}
          disabled={isLoading || loading}
          onChange={onChange}
        />
      </Organisms.Dialog.DialogContent>
      <Organisms.Dialog.Footer
        right={<Atoms.FooterButton variant='secondary' key='cancel' label={t('close')} onClick={onClose} />}
      />
    </Organisms.Dialog>
  );
}
