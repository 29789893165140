// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { useAppSelector } from 'store/hooks';
import { FilterTypes } from 'store/reducers/uiSettingsSlice';
import { GetStatusTranslationKey } from 'model/ProjectMetaMessageExtensions';
import { useEffect, useState } from 'react';
import { OrderByType, OrderType, sortByModifiedDate, sortByName, sortByStatus } from 'model/sorter';
import useProjectsMetadata from './useProjectsMetadata';
import { ProjectMetadata } from 'model/ProjectMetadata';

export default function useFilteredProjects() {
  const { loading, lastStatus, projects } = useProjectsMetadata();
  const filterTypes = useAppSelector(s => s.uiSettings.filterType);
  const filterText = useAppSelector(s => s.uiSettings.filterText)?.toLocaleLowerCase();
  const order = useAppSelector(s => s.uiSettings.order);
  const orderBy = useAppSelector(s => s.uiSettings.oderBy);

  const [filteredProjects, setFilteredProjects] = useState<ProjectMetadata[]>([]);

  useEffect(() => {
    const filtered = projects.filter(
      p =>
        (!filterText || p.projectName?.toLocaleLowerCase()?.includes(filterText)) &&
        (!filterTypes[FilterTypes.Project] || filterTypes[FilterTypes.Project].includes(p.projectId ?? '')) &&
        (!filterTypes[FilterTypes.Status] ||
          filterTypes[FilterTypes.Status].includes(GetStatusTranslationKey(p.projectStatus))) &&
        (!filterTypes[FilterTypes.Tag] || filterTypes[FilterTypes.Tag].some(t => p.tags?.includes(t)))
    );

    filtered.sort(getOrderFunction(orderBy, order));

    setFilteredProjects(filtered);
  }, [filterText, filterTypes, order, orderBy, projects]);

  return { loading, lastStatus, filteredProjects };
}
function getOrderFunction(
  orderBy: string,
  order: OrderByType
): ((a: ProjectMetadata, b: ProjectMetadata) => number) | undefined {
  switch (orderBy) {
    case OrderType.Status:
      return sortByStatus(order);
    case OrderType.ModifiedDate:
      return sortByModifiedDate(order);
    case OrderType.Name:
    default:
      return sortByName(order);
  }
}
