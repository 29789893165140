// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { IconProps } from '@tls/treact-ui/lib/components/Icons/types';
import React from 'react';

const IconError = ({ id, width, height, viewBox, ...other }: IconProps) => {
  return (
    <svg
      id={id ?? 'svg-icon-error'}
      data-name='svg-icon-error'
      data-testid='svg-icon-error'
      width={width ?? '250'}
      height={height ?? '250'}
      viewBox={viewBox ?? '0 0 250 250'}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...other}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M250 74.2481L177.088 0L73.5911 0.0781754L0.296721 73.4897L0 177.631L72.9946 249.945L173.639 249.961L249.428 175.683L250 74.2481Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M171.848 12.5018L237.473 79.3292L236.96 170.403L168.537 237.462L78.1379 237.448L12.5127 172.435L12.7798 78.6754L78.7776 12.5721L171.848 12.5018Z'
        fill='#285172'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M164.455 112.484L84.4189 213.373L113.951 137.48H81.2507L126.018 49.9952H167.367L128.311 112.484H164.455Z'
        fill='white'
      />
    </svg>
  );
};

export default IconError;
