// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { HistoryInformationMessage } from '@tls/sw91-communication/types/com.base';
import { COLORS } from '@tls/treact-ui';
import {
  ChartData,
  CoreChartOptions,
  DatasetChartOptions,
  ElementChartOptions,
  LineControllerChartOptions,
  PluginChartOptions,
  ScaleChartOptions,
} from 'chart.js';
import { _DeepPartialObject } from 'chart.js/dist/types/utils';
import { useRef } from 'react';
export type DataChartType = ChartData<'line', number[], number>;

export function useDefaultData() {
  return useRef<DataChartType>({
    labels: [],
    datasets: [
      {
        label: 'Model update',
        type: 'line' as const,
        data: [],
        fill: false,
        pointStyle: [],
        borderColor: COLORS.trblue4.hex,
        backgroundColor: COLORS.trblue4.hex,
        pointBackgroundColor: COLORS.trwhite.hex,
        pointHoverRadius: [],
        hoverBorderWidth: 2,
        hoverBackgroundColor: COLORS.trwhite.hex,
        pointRadius: [],
        borderWidth: 0.7,
        pointBorderWidth: 1.2,
        pointHitRadius: 10,
      },
    ],
  });
}

export type OptionsType = _DeepPartialObject<
  CoreChartOptions<'line'> &
    ElementChartOptions<'line'> &
    PluginChartOptions<'line'> &
    DatasetChartOptions<'line'> &
    ScaleChartOptions<'line'> &
    LineControllerChartOptions
>;

export function useDefaultOptions() {
  return useRef<OptionsType>({
    responsive: true,
    clip: false,
    font: {
      family: 'Open Sans, sans-serif',
    },
    plugins: {
      legend: {
        position: 'bottom',
        align: 'start',
        fullSize: false,
        labels: {
          boxHeight: 12,
          boxWidth: 12,
          padding: 0,
          color: COLORS.trblack.hex,
        },
        onClick: e => e.native?.stopPropagation(),
      },
      tooltip: {
        backgroundColor: COLORS.trwhite.hex,
        titleColor: COLORS.trblack.hex,
        bodyColor: COLORS.trblack.hex,
        borderColor: COLORS.trblue4.hex,
        footerColor: COLORS.trblack.hex,
        borderWidth: 0.3,
        boxPadding: 5,
        cornerRadius: 0,
        callbacks: {
          label: () => '',
          footer: () => '',
        },
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'xy',
        },
        limits: {
          y: {
            max: 1,
            min: 0,
          },
          x: {
            min: 0,
          },
        },
        pan: {
          enabled: true,
          mode: 'xy',
        },
      },
    },
    scales: {
      x: {
        type: 'linear',
        grid: {
          display: false,
        },
        ticks: {
          color: COLORS.trblack.hex,
          font: {
            weight: 'bold',
          },
          precision: 0,
        },
        border: {
          color: COLORS.trgrey4.hex,
        },
        title: {
          align: 'end',
          text: 'Epochs',
          display: true,
          font: {
            weight: 'bold',
          },
          color: COLORS.trblack.hex,
        },
      },
      y: {
        grid: {
          display: true,
        },
        border: {
          display: false,
        },
        title: {
          text: 'IoU',
          display: true,
          font: {
            weight: 'bold',
          },
          color: COLORS.trblack.hex,
        },
        ticks: {
          color: COLORS.trblack.hex,
        },
        suggestedMax: 1,
        suggestedMin: 0,
      },
    },
    maintainAspectRatio: false,
  });
}

export function updateDataPoints(history: HistoryInformationMessage[], data: React.MutableRefObject<DataChartType>) {
  const pointStyle = data.current.datasets[0].pointStyle;
  const pointHoverRadius = data.current.datasets[0].pointHoverRadius;
  const pointRadius = data.current.datasets[0].pointRadius;

  if (!Array.isArray(pointHoverRadius) || !Array.isArray(pointRadius) || !Array.isArray(pointStyle)) return;

  //delete existing data
  const total = data.current.datasets[0].data.length;

  history.slice(total).forEach(h => {
    data.current.labels?.push(h.epochNumber ?? 0);
    data.current.datasets[0].data.push(JSON.parse(h.performanceData ?? '{}')?.iou_class_1 ?? 0);

    if (h.modelUpdate) {
      pointStyle?.push('rect');
      pointHoverRadius.push(8);
      pointRadius.push(6);
    } else {
      pointStyle.push('circle');
      pointHoverRadius.push(1);
      pointRadius.push(0);
    }
  });
}
