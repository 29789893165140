// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { PageContext } from './PageContext';

const GridContainer = styled.div`
  display: grid;
  height: auto;
`;

export default function PageWrapper({ children }: React.PropsWithChildren) {
  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const [navBarHeight, setNavHeight] = useState<number>(0);

  const value = useMemo(
    () => ({ headerHeightInPx: headerHeight, setHeaderHeight, navBarHeightInPx: navBarHeight, setNavHeight }),
    [headerHeight, setHeaderHeight, navBarHeight, setNavHeight]
  );

  return (
    <PageContext.Provider value={value}>
      <GridContainer>{children}</GridContainer>
    </PageContext.Provider>
  );
}
