// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { Atoms, ICONS } from '@tls/treact-ui';
import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeSelectedImage } from 'store/reducers/projectImageSlice';
import styled from 'styled-components';
import { AppRoutes } from 'utilities/router/AppRoutes';
import { EvaluationContext } from './EvaluationContext';
import ImagePreview from './ImagePreview';

export type Props = {
  version: string;
};

export default function ResultPreview({ version }: Props) {
  const { t } = useTranslation();

  const activeMd5 = useAppSelector(a => a.evaluation.activeMd5);
  const navigate = useNavigate();
  const { id } = useParams();

  const dispatch = useAppDispatch();
  const onEdit = useCallback(() => {
    dispatch(changeSelectedImage(activeMd5));
    navigate(AppRoutes.ProjectLabelFullPath(id ?? ''));
  }, [activeMd5, dispatch, id, navigate]);

  const { index, length, updateIndex } = useContext(EvaluationContext);

  const onChange = useCallback(
    (newIndex: number) => () => {
      updateIndex(newIndex);
    },
    [updateIndex]
  );

  return (
    <Container>
      <ButtonContainer>
        <Atoms.Button
          icon={<ICONS.IconChevronSingleUpEnd />}
          variant='secondary'
          disabled={index <= 0}
          onClick={onChange(0)}
        />
        <Atoms.Button
          icon={<ICONS.IconChevronSingleUp />}
          variant='secondary'
          disabled={index <= 0}
          onClick={onChange(index - 1)}
        />
        <Atoms.Button
          icon={<ICONS.IconChevronSingleDown />}
          variant='secondary'
          disabled={index === length - 1 || index === -1}
          onClick={onChange(index + 1)}
        />
        <Atoms.Button
          icon={<ICONS.IconChevronSingleDownEnd />}
          variant='secondary'
          disabled={index === length - 1 || index === -1}
          onClick={onChange(length - 1)}
        />
        <Atoms.Button
          icon={<ICONS.IconEdit />}
          label={t('project.label.edit')}
          variant='secondary'
          disabled={index === -1}
          onClick={onEdit}
        />
      </ButtonContainer>
      {id && activeMd5 && <ImagePreview projectId={id} imageId={activeMd5} version={version} />}
    </Container>
  );
}

const ButtonContainer = styled.div`
  display: flex;
  column-gap: 0.5rem;
  justify-content: end;
  padding: 0.5rem;
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
`;
