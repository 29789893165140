// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import NoProjects from './NoProjects';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import BackdropLoading from 'components/treactui-template/organisms/backdrop/loading/BackdropLoading';
import Tile from './tile';
import { useAppDispatch } from 'store/hooks';
import { fetchProjects } from 'store/reducers/projectsSlice';
import useFilteredProjects from 'hooks/useFilteredProjects';

export default function Tiles() {
  const dispatch = useAppDispatch();
  const { loading, lastStatus, filteredProjects } = useFilteredProjects();

  useEffect(() => {
    dispatch(fetchProjects());
  }, [dispatch]);

  useEffect(() => {
    //toast error message
  }, [lastStatus]);

  if (!loading && filteredProjects.length < 1) {
    return <NoProjects />;
  }

  return (
    <Container>
      <BackdropLoading loading={loading && filteredProjects.length < 1} />
      {filteredProjects.map(p => (
        <Tile key={p.projectId} entry={p} />
      ))}
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  min-height: calc(100% - 2rem - 2px);
  min-width: calc(100% - 2rem - 2px);
  display: inline-flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1rem;
`;
