// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { useQuery } from '@tanstack/react-query';
import { del, get, put } from '@tls/react-saf-ui';
import { ToastByStatus } from 'components/treactui-template/organisms/toast/ToastMessage';
import { useCallback } from 'react';

export default function useShareApi(projectId: string) {
  const { isLoading, data, refetch } = useQuery({
    queryKey: ['get-access', projectId],
    queryFn: async () => projectId && (await get(`projects/${projectId}/access`)),
  });

  const addAccess = useCallback(
    async (userId: string) => {
      const result = await put(`projects/${projectId}/access`, { userId });
      ToastByStatus(result.status, 'project.share.add');
      refetch();

      return result;
    },
    [refetch, projectId]
  );

  const removeAccess = useCallback(
    async (userId: string) => {
      const result = await del(`projects/${projectId}/access/${userId}`);
      ToastByStatus(result.status, 'project.share.delete');
      refetch();
      return result;
    },
    [refetch, projectId]
  );

  return {
    loadingUsers: isLoading,
    users: (data?.data as string[]) ?? [],
    addAccess,
    removeAccess,
  };
}
