// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { HistoryInformationMessage } from '@tls/sw91-communication/types/com.base';
import {
  ExportStrategyDataMessage,
  ExportStrategyProgressMessage,
} from '@tls/sw91-communication/types/com.api_tai_exporter';
import EventEmitter from 'eventemitter3';
import { EarlyStoppingMessage } from '@tls/sw91-communication/types/com.api_number_cruncher';
import { ModelEvaluationProgressResult } from 'model/ModelEvaluationProgressResult';
import { ProjectStatusAckMessage } from 'model/reponse/ProjectStatusAckMessage';
import { GetAllLabelPackageInfoAckMessage } from '@tls/sw91-communication/types/com.api_db_access';

interface UpdateEvents {
  onHistoryInformationUpdate: (message: HistoryInformationMessage) => void;
  onEarlyStopping: (message: EarlyStoppingMessage) => void;
  onExportProgress: (message: ExportStrategyProgressMessage) => void;
  onExportFailed: (message: ExportStrategyDataMessage) => void;
  imageDeleted: (projectId: string, imageMd5: string) => void;
  onEvaluationCompleted: (message: ModelEvaluationProgressResult) => void;
  onEvaluationFailed: (message: ModelEvaluationProgressResult) => void;
  onProjectStatusChanged: (message: ProjectStatusAckMessage) => void;
  onLabelPackageInfo: (message: GetAllLabelPackageInfoAckMessage[]) => void;
}

class UpdateEmitter extends EventEmitter<UpdateEvents> {}

export default UpdateEmitter;
