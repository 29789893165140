// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { Organisms } from '@tls/treact-ui';
import React, { useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import CreateProjectDialogContent from './settings/CreateProjectDialogContent';
import ImportProjectDialogContent from './settings/ImportProjectDialogContent';
import { toggleDialog } from 'store/reducers/projectSettingsSlice';
import { useTranslation } from 'react-i18next';

const { Dialog } = Organisms;

enum Page {
  Create,
  Import,
}

export default function CreateProjectDialog() {
  const { id, show, duplicateSourceId } = useAppSelector(s => s.projectSettings);

  const [page, setPage] = useState(Page.Create);

  const { t } = useTranslation();
  const onTabClick = useCallback((newPage: Page) => () => setPage(newPage), []);

  const dispatch = useAppDispatch();
  const onClose = useCallback(() => {
    dispatch(toggleDialog());
  }, [dispatch]);

  const showImport = !id && !duplicateSourceId;

  let headerLabel = 'project.settings.add.title';
  if (id) headerLabel = 'project.settings.edit.title';
  else if (duplicateSourceId) headerLabel = 'project.settings.duplicate.title';

  const subMenus = [
    <Organisms.SubMenuHorizontal.SubMenuTab
      label={t(headerLabel)}
      selected={!showImport || page === Page.Create}
      onClick={onTabClick(Page.Create)}
      key='create'
    />,
  ];
  if (showImport) {
    subMenus.push(
      <Organisms.SubMenuHorizontal.SubMenuTab
        label={t('projects.import.title')}
        selected={page === Page.Import}
        onClick={onTabClick(Page.Import)}
        key='import'
      />
    );
  }

  return (
    <Dialog enableClosing open={show} size='large' onClose={onClose}>
      <Organisms.SubMenuHorizontal>{subMenus}</Organisms.SubMenuHorizontal>
      {(!showImport || page === Page.Create) && <CreateProjectDialogContent saveOnEnter={show} />}
      {showImport && page === Page.Import && <ImportProjectDialogContent saveOnEnter={show} />}
    </Dialog>
  );
}
