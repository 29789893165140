// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { IconProps } from '@tls/treact-ui/lib/components/Icons/types';
import React from 'react';

const IconDraw = ({ id, width, height, viewBox, strokeWidth, stroke, fill, ...other }: IconProps) => {
  return (
    <svg
      id={id || 'svg-draw'}
      data-name='svg-draw'
      data-testid='svg-draw'
      xmlns='http://www.w3.org/2000/svg'
      width={width || '2.25rem'}
      height={height || '2.25rem'}
      viewBox={viewBox || '0 0 36 36'}
      strokeWidth={strokeWidth || '0'}
      stroke={stroke || 'currentColor'}
      fill={fill || 'currentColor'}
      {...other}
    >
      <path
        d='M20.5333 3.7646L19.6 5.23126L15.6 2.83126L16.4666 1.3646C16.5642 1.19694 16.7242 1.07474 16.9116 1.02476C17.099 0.974785 17.2986 1.00109 17.4666 1.09793L20.2666 2.7646C20.4343 2.86213 20.5565 3.02211 20.6065 3.20953C20.6565 3.39695 20.6301 3.59653 20.5333 3.7646Z'
        fill={fill}
      />
      <path d='M5.93054 19.0251L14.7549 4.33901L18.7664 6.74939L9.94209 21.4355L5.93054 19.0251Z' fill={fill} />
      <path d='M9 22.8979L5.53333 24.6979C5.26667 24.8313 5 24.6979 5 24.4313V20.4979L9 22.8979Z' fill={fill} />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.4743 25.923C16.9061 25.7791 17.2457 25.8378 17.4091 25.9169C17.4459 25.9347 17.4693 25.951 17.4833 25.9625C17.4785 25.9744 17.473 25.9878 17.4666 26.0027C17.407 26.1404 17.3058 26.3302 17.1546 26.5765C16.8716 27.0374 16.4971 27.5636 16.0823 28.1465L15.9824 28.2869C15.55 28.8949 15.0729 29.5709 14.7238 30.1811C14.5489 30.4867 14.3767 30.8259 14.2583 31.1695C14.1515 31.4798 14.0197 31.9839 14.141 32.5346C14.3475 33.4714 15.1099 33.9267 15.575 34.1303C16.0895 34.3554 16.6982 34.4799 17.2969 34.5544C18.5141 34.7059 20.0821 34.6951 21.7372 34.5857C25.0608 34.366 29.0361 33.7268 31.8947 32.9472C32.6939 32.7292 33.1651 31.9046 32.9471 31.1053C32.7292 30.3061 31.9046 29.8349 31.1053 30.0529C28.4639 30.7733 24.6892 31.384 21.5393 31.5922C19.9576 31.6968 18.6079 31.6944 17.6674 31.5774C17.572 31.5655 17.4845 31.5529 17.4045 31.5398C17.6634 31.1073 18.0202 30.5979 18.4272 30.0256L18.5395 29.8678C18.9373 29.3091 19.3716 28.6992 19.7111 28.1462C19.8985 27.841 20.0809 27.5154 20.2203 27.193C20.3485 26.8964 20.5 26.4702 20.5 26C20.5 24.6804 19.7166 23.7008 18.7159 23.2165C17.7543 22.7511 16.5939 22.7209 15.5257 23.077C14.8435 23.3044 13.6189 23.6721 12.5427 23.989C12.0093 24.146 11.5199 24.2884 11.1638 24.3916C10.9858 24.4431 10.8413 24.4848 10.7413 24.5136L10.5871 24.558C9.79077 24.7863 9.32976 25.6171 9.5581 26.4135C9.78645 27.2098 10.6171 27.6702 11.4134 27.4419L11.5716 27.3964C11.6729 27.3673 11.8189 27.3251 11.9984 27.2731C12.3575 27.1691 12.8513 27.0254 13.3901 26.8668C14.4581 26.5523 15.7335 26.17 16.4743 25.923ZM16.7292 31.3576C16.7292 31.3576 16.7363 31.3607 16.7473 31.3678C16.7347 31.3616 16.7292 31.3576 16.7292 31.3576ZM17.0848 32.1777C17.0828 32.1886 17.0808 32.1947 17.0808 32.1947C17.0808 32.1947 17.0815 32.1894 17.0848 32.1777Z'
        fill={fill}
      />
    </svg>
  );
};

export default IconDraw;
