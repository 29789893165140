// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { Atoms } from '@tls/treact-ui';
import React, { useCallback } from 'react';
import cs from 'classnames';
import Badge from 'components/treactui-template/atoms/Badge';
import { useAppSelector } from 'store/hooks';
import { FilterTypes } from 'store/reducers/uiSettingsSlice';
import IconFilterSort from 'components/treactui-template/icons/IconFilterSort';

export type Props = {
  onOpen: Function;
  onClose: Function;
  isOpen: boolean;
};

export default function FilterButton({ onOpen, onClose, isOpen }: Props) {
  const filterTypesToConsider = useAppSelector(s => s.uiSettings.filterTypesToDisplay) ?? [];
  const filterType = useAppSelector(a => a.uiSettings.filterType);
  const count = Object.keys(filterType).filter(
    f => !!filterType[f] && filterTypesToConsider.includes(f as FilterTypes)
  ).length;

  const toggleFilter = useCallback(() => (isOpen ? onClose() : onOpen()), [isOpen, onClose, onOpen]);
  return (
    <Badge value={count ?? 0} display={(count ?? 0) > 0}>
      <Atoms.Button
        icon={<IconFilterSort width='1.5rem' key='filter-btn-key' />}
        className={cs({
          selected: isOpen,
        })}
        variant='secondary'
        onClick={toggleFilter}
      />
    </Badge>
  );
}
