// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import React from 'react';
import Backdrop from '../Backdrop';
import CenteredLoader from './CenteredLoader';

type Props = {
  loading: boolean;
  label?: string;
  spinner?: boolean;
};

export default function BackdropLoading({ loading, label, spinner }: Readonly<Props>) {
  return (
    <Backdrop showBackdrop={loading}>
      <CenteredLoader label={label} spinner={spinner} />
    </Backdrop>
  );
}
