// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import React from 'react';
import styled from 'styled-components';

export default function FilterDivider() {
  return <VerticalDividerShell />;
}

const VerticalDividerShell = styled.div`
  margin: 1rem 1rem;
  border-right: 0.125rem solid black;
  grid-area: divider;
`;
