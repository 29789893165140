// SPDX-FileCopyrightText: 2024 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { get } from '@tls/react-saf-ui';
import { Contract, parseContract } from 'model/Contract';
import { useQuery } from '@tanstack/react-query';

export default function useUserContractsApi(userId: string) {
  const { data, isLoading, isError } = useQuery({
    queryKey: [`contracts/user/${userId}`],
    queryFn: async () => (userId ? await get(`contracts/user/${userId}`, false, true) : undefined),
  });

  const parsedData: Contract[] | undefined = Array.isArray(data?.data) ? data?.data?.map(parseContract) : [];

  return {
    data: parsedData,
    isLoading,
    isError,
  };
}
