// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface LabelState {
  predictEnabled: boolean;
  automaticLabelOrderUpdate: boolean;
  labelOrderUpdateInterval: number;
}

const initialState: LabelState = {
  predictEnabled: false,
  automaticLabelOrderUpdate: false,
  labelOrderUpdateInterval: 15,
};

export const projectLabelSlice = createSlice({
  name: 'label',
  initialState,
  reducers: {
    setPredictEnabled: (state, action: PayloadAction<boolean>) => {
      state.predictEnabled = action.payload;
    },
    toggleAutomaticLabelOrderUpdate: state => {
      state.automaticLabelOrderUpdate = !state.automaticLabelOrderUpdate;
    },
    setLabelOrderUpdateInterval: (state, action: PayloadAction<number | null>) => {
      if (action.payload === null) return;
      if (action.payload < 1) action.payload = 1;
      else if (action.payload > 90) action.payload = 90;

      state.labelOrderUpdateInterval = action.payload;
    },
  },
});

export const { setPredictEnabled, setLabelOrderUpdateInterval, toggleAutomaticLabelOrderUpdate } =
  projectLabelSlice.actions;
export default projectLabelSlice.reducer;
