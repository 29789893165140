// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { COLORS } from '@tls/treact-ui';
import { IconProps } from '@tls/treact-ui/lib/components/Icons/types';
import React from 'react';

export default function FlagErrorInteraction({ id, width, height, viewBox, stroke, fill, ...other }: IconProps) {
  return (
    <svg
      id={id ?? 'svg-icon-export'}
      width={width ?? '36'}
      height={height ?? '36'}
      viewBox={viewBox ?? '0 0 36 36'}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...other}
    >
      <path d='M1.5 4.5H34.5V31.5H1.5V4.5Z' fill={stroke ?? COLORS.trwhite.hex} />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M33 6H3V30H33V6ZM24.7279 14.1213L20.4853 18.364L24.7279 22.6066L22.6066 24.7279L18.364 20.4853L14.1213 24.7279L12 22.6066L16.2426 18.364L12 14.1213L14.1213 12L18.364 16.2426L22.6066 12L24.7279 14.1213Z'
        fill={fill ?? COLORS.trred2.hex}
      />
    </svg>
  );
}
