// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { ImagesContext } from 'components/project/images/ImagesContext';
import { useContext, useMemo } from 'react';
import { useAppSelector } from 'store/hooks';

export default function useProjectLabels() {
  const { selectedMd5 } = useAppSelector(s => s.image);
  const { images, loading } = useContext(ImagesContext);

  const name = useMemo(() => images?.find(l => l?.imageMd5 === selectedMd5)?.imageName ?? '', [images, selectedMd5]);

  return { selectedMd5: name ? selectedMd5 : '', name, loading, labels: images };
}
