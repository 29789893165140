// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { useSafContext } from '@tls/react-saf-ui';
import { StatusBarContainer, ToastStyle } from 'components/treactui-template/organisms/toast/ToastStyle';
import { HubContext, HubContextType } from 'components/common/emitter/HubContext';

import React, { useEffect, useMemo } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import I18nextProvider from 'utilities/translation/I18nextProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Connector from 'hub/Connector';
import UpdateEmitter from 'components/common/emitter/UpdateEmitter';
import { reactPlugin } from 'utilities/appInsights/AppInsights';
import { AppInsightsContext, AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import ErrorBound from 'components/common/ErrorBound';
import ContractFetcher from 'components/ContractFetcher';
import ApplicationRouter from 'layout/ApplicationRouter';
import { ThirdPartyLicensesDialog } from 'components/thirdPartyLicenses/ThirdPartyLicensesDialog';
import { useAppDispatch } from 'store/hooks';
import NotificationSidebar from 'components/NotificationSidebar';
import { DataAgreementDialog } from 'components/settings/DataAgreementDialog';
import { InitialDataAgreementDialog } from 'components/settings/InitialDataAgreementDialog';
import TelemetryFetcher from 'components/TelemetryFetcher';

export default function App() {
  const { appBaseUrl } = useSafContext();
  const dispatch = useAppDispatch();

  useEffect(() => {
    Connector.SetDispatch(dispatch);
  }, [dispatch]);

  const eventEmitter = Connector.getEventEmitter();
  const hubContextValue = useMemo<HubContextType>(() => {
    return {
      events: eventEmitter ?? new UpdateEmitter(),
    };
  }, [eventEmitter]);

  const queryClient = new QueryClient();

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <AppInsightsErrorBoundary onError={ErrorBound} appInsights={reactPlugin}>
        <I18nextProvider appBaseUrl={appBaseUrl}>
          <HubContext.Provider value={hubContextValue}>
            <QueryClientProvider client={queryClient}>
              <BrowserRouter basename={`${appBaseUrl}`}>
                <ToastStyle />
                <StatusBarContainer>
                  <ToastContainer
                    position='top-right'
                    autoClose={8000}
                    hideProgressBar={true}
                    closeButton={false}
                    closeOnClick={false}
                    style={{
                      zIndex: '1000000',
                      position: 'absolute',
                      right: 0,
                    }}
                    pauseOnHover={false}
                    newestOnTop
                    rtl={false}
                    pauseOnFocusLoss={false}
                    draggable={false}
                  />
                </StatusBarContainer>
                <ContractFetcher />
                <ThirdPartyLicensesDialog />
                <DataAgreementDialog />
                <InitialDataAgreementDialog />
                <ApplicationRouter />
                <NotificationSidebar />
                <TelemetryFetcher />
              </BrowserRouter>
            </QueryClientProvider>
          </HubContext.Provider>
        </I18nextProvider>
      </AppInsightsErrorBoundary>
    </AppInsightsContext.Provider>
  );
}
