// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import React, { useCallback } from 'react';
import { Atoms, ICONS } from '@tls/treact-ui';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { toggleDialog } from 'store/reducers/projectSettingsSlice';

const { IconPlus } = ICONS;
export default function AddProjectButton() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const isLicensed = useAppSelector(a => a.license.contract?.isActive ?? false);

  const onClick = useCallback(() => dispatch(toggleDialog()), [dispatch]);

  return (
    <Atoms.Button
      icon={<IconPlus />}
      onClick={onClick}
      className='fill'
      label={t('projects.addNewProject')}
      disabled={isLicensed === false}
    />
  );
}
