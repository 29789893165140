// SPDX-FileCopyrightText: 2024 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { Atoms, ICONS, Organisms } from '@tls/treact-ui';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Role } from 'model/Role';

const { IconLoading } = ICONS;
const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 1px + 1px);
`;

const Form = styled.form`
  flex-grow: 1;
  overflow-y: auto;
`;

const Grid = styled.div`
  display: grid;
  padding: 1rem;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 1rem;
`;
const GridItem = styled.div``;

enum EditAction {
  Save,
  Delete,
}
export default function RoleDetails({
  role,
  roles,
  addOrEditRole,
  deleteRole,
  onClose,
  isLoading = false,
  isBuiltInRole,
  permissions = [],
}: Readonly<Props>) {
  const { t } = useTranslation();

  const [editRole, setEditRole] = useState(role);
  const [action, setAction] = useState<EditAction>();

  const isNameAlreadyExist = useMemo(
    () => (roles?.filter(otherRole => otherRole.id !== role.id && otherRole.name === editRole.name)?.length ?? 0) > 0,
    [editRole, role, roles]
  );

  const onDelete = useCallback(async () => {
    setAction(EditAction.Delete);
    if (deleteRole) await deleteRole(role);
    setAction(undefined);
    if (onClose) onClose();
  }, [deleteRole, onClose, role]);

  const onAccept = useCallback(async () => {
    setAction(EditAction.Save);
    await addOrEditRole(editRole);
    setAction(undefined);
    if (onClose) onClose();
  }, [editRole, onClose, addOrEditRole]);

  useEffect(() => {
    setEditRole(role);
  }, [role]);

  const hasRoleChanges = useMemo(() => JSON.stringify(editRole) !== JSON.stringify(role), [editRole, role]);

  const editing = action === EditAction.Save;

  const rightActions: React.JSX.Element[] = [];
  if (onClose) {
    rightActions.push(
      <Atoms.FooterButton
        key='abort'
        variant={'secondary'}
        label={t('cancel')}
        onClick={onClose}
        disabled={isLoading}
      />
    );
  }
  rightActions.push(
    <Atoms.FooterButton
      key='save'
      variant={'primary'}
      label={t('save')}
      onClick={onAccept}
      icon={editing ? <IconLoading /> : undefined}
      disabled={isLoading || !hasRoleChanges || editing || isBuiltInRole}
    />
  );

  const togglePermission = useCallback(
    (newPermission: string) => {
      if (editRole?.permissions?.includes(newPermission)) {
        setEditRole({
          ...editRole,
          permissions: editRole.permissions?.filter(perm => perm !== newPermission) ?? [],
        });
      } else if (editRole) {
        setEditRole({
          ...editRole,
          permissions: [...(editRole.permissions ?? []), newPermission],
        });
      }
    },
    [editRole, setEditRole]
  );

  if (editRole.id !== role.id) return <></>;

  const deleting = action === EditAction.Delete;

  return (
    <Root>
      <Form autoComplete='off'>
        <Grid>
          <GridItem>
            <Atoms.InputFieldText
              label={t('role.roleName')}
              value={editRole.name}
              type={isNameAlreadyExist ? 'error' : 'none'}
              helperText={isNameAlreadyExist ? t('role.validation.roleNameError') : undefined}
              onValueChange={newValue =>
                setEditRole({
                  ...editRole,
                  name: newValue,
                })
              }
              disabled={isBuiltInRole}
            />
          </GridItem>
          <GridItem>
            <p className='font-size_m font-weight_bold title'>{t('role.permission.name')}</p>
            {permissions?.map(p => (
              <LeftAlignedCheckbox
                key={p}
                disabled={role.isBuiltIn}
                label={p}
                value={
                  role.isBuiltIn || editRole.permissions?.includes(p)
                    ? Atoms.Checkbox.Selection.selected
                    : Atoms.Checkbox.Selection.unselected
                }
                onValueChange={() => togglePermission(p)}
              />
            ))}
          </GridItem>
        </Grid>
      </Form>
      <Organisms.Footer
        left={
          deleteRole
            ? [
                <Atoms.FooterButton
                  key='delete'
                  variant={'secondary'}
                  label={t('delete')}
                  onClick={onDelete}
                  icon={deleting ? <IconLoading /> : undefined}
                  disabled={isLoading || deleting || isBuiltInRole}
                />,
              ]
            : []
        }
        right={rightActions}
      />
    </Root>
  );
}

interface Props {
  role: Role;
  roles?: Role[];
  onClose?: () => void;
  addOrEditRole: (role: Role) => Promise<void>;
  deleteRole?: (role: Role) => Promise<void>;
  isBuiltInRole?: boolean;
  permissions?: string[];
  isLoading?: boolean;
}

const LeftAlignedCheckbox = styled(Atoms.Checkbox)`
  align-items: start;
`;
