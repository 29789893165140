// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { createContext } from 'react';

export type LabelOrderResult = {
  md5: string;
  labelOrder: number;
};

export interface SSimContextType {
  isCalculating: boolean;
  result: LabelOrderResult[];
  progress: number;
  refreshResult: () => void;
  updateAvailable: boolean;
}

export const SSimContext = createContext<SSimContextType>({
  isCalculating: false,
  refreshResult: () => {
    console.error('SSimContext not initialized. Not applying');
  },
  progress: 0,
  result: [],
  updateAvailable: false,
});
