// SPDX-FileCopyrightText: 2023 TRUMPF Laser GmbH
//
// SPDX-License-Identifier: LicenseRef-TRUMPF
import { put } from '@tls/react-saf-ui';
import { useCallback, useState } from 'react';
import { LockProjectAckMessage, UnlockProjectAckMessage } from '@tls/sw91-communication/types/com.api_db_access';
import { AcknowledgeStatus } from '@tls/sw91-communication/types/com.base';
import { useAppDispatch } from 'store/hooks';
import { showDialog } from 'store/reducers/projectLockedSlice';

export function useProjectLockApi() {
  const [loading, setLoading] = useState(false);

  const unlock = useCallback(async (projectId: string) => {
    setLoading(true);
    const { data, status } = await put(`projects/${projectId}/unlock`);
    const lockResponse = UnlockProjectAckMessage.fromJSON(data ?? {});

    setLoading(false);
    if (status !== 200 || lockResponse.status?.status !== AcknowledgeStatus.SUCCESS) {
      return false;
    }

    return true;
  }, []);

  const dispatch = useAppDispatch();

  const lock = useCallback(
    async (projectId: string) => {
      setLoading(true);
      const { data, status } = await put(`projects/${projectId}/lock`);
      const lockResponse = LockProjectAckMessage.fromJSON(data?.ackMessage ?? {});
      setLoading(false);
      if (status !== 200 || lockResponse.status?.status !== AcknowledgeStatus.SUCCESS) {
        dispatch(
          showDialog({
            projectIdToLock: projectId,
            projectLockResponse: data,
          })
        );
        return false;
      }

      return true;
    },
    [dispatch]
  );

  return { lock, unlock, loading };
}
